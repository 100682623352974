import styled from 'styled-components';
import { useMemo, useState } from 'react';
import { intervalToDuration, isSameDay, isSameMonth } from 'date-fns';
import format from 'date-fns/format';
import { useTranslation } from 'react-i18next';
import theme from '../../design/theme';
import IconNighttime from '../../assets/session_nighttime.svg';
import IconDaytime from '../../assets/session_daytime.svg';
import { Caption, Heading, Label } from '../Typography';
import Icon from '../Icon/Icon';
import { SleepSession } from '../../../shared/types';
import MinutesToHoursAndMinutes from '../Time';

type SessionCardProps = {
  session: SleepSession
}

const Container = styled.div`
  display: flex;
  flex-direction: column;
`;

const SessionDate = styled.div`
  text-align: right;
  margin-bottom: 10px;
`;

const Card = styled.section`
  background-color: ${theme.color.background.darkMode.dark};
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: 20px;
  padding: 20px;
  border-radius: 10px;
`;

const TopContent = styled.section`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
`;

const LeftSide = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`;

const ImageBackground = styled.div`
  height: 24px;
  width: 24px;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const Information = styled.div`
  padding-left: 10px;
`;

const ExpandIconArea = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`;

const BottomContent = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  margin-top: 20px;
`;

const Row = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  margin: 8px 0;
`;

const SessionCard = ({ session }: SessionCardProps) => {
  const sessionStart = new Date(session.startTime * 1000);
  const sessionEnd = new Date(session.endTime * 1000);
  const [profileExpanded, setProfileExpanded] = useState(false);
  const { t } = useTranslation();
  const startTime = format(sessionStart, 'h:mm aaa');
  const endTime = format(sessionEnd, 'h:mm aaa');
  const isNight = session.sessionType === 'NIGHT';
  const timeDistance = intervalToDuration({ start: sessionStart, end: sessionEnd });

  const sessionTitle = () => {
    let text = '';
    switch (session.sessionType?.toLowerCase()) {
      case 'nap':
        text = t('Daytime Nap');
        break;
      case 'current':
        text = t('Current Session');
        break;
      default:
        break;
    }
    return isNight ? t('Nighttime Sleep') : text;
  };

  const formatDistance = () => {
    const hours = timeDistance.hours ? `${timeDistance.hours}hr` : '';
    const minutes = timeDistance.minutes ? `${timeDistance.minutes}min` : '';

    return `(${hours} ${minutes})`;
  };

  const totalSleepMinutes = () => {
    const dM = session.sleepStateDurationsMinutes[8] || 0;
    const nM = session.sleepStateDurationsMinutes[15] || 0;

    return dM + nM;
  };

  const sessionDate = useMemo(() => {
    const sTime = new Date(session.startTime * 1000);
    const eTime = new Date(session.endTime * 1000);
    const formattedStartTime = format(sTime, 'MMMM dd').split(' ');
    const formattedEndTime = format(eTime, 'MMMM dd').split(' ');

    if (isSameDay(sTime, eTime)) {
      return format(sTime, 'MMMM dd');
    }
    if (isSameMonth(sTime, eTime)) {
      return `${formattedStartTime[0]} ${formattedStartTime[1]}-${formattedEndTime[1]}`;
    }

    return `${format(sTime, 'MMMM dd')} - ${format(eTime, 'MMMM dd')}`;
  }, [session]);

  const sleepQuality = useMemo(() => {
    if (session.sleepQuality >= 0.8) {
      return t('Great');
    }
    if (session.sleepQuality >= 0.6) {
      return t('Okay');
    }
    return t('Poor');
  }, [session, t]);

  return (
    <Container>
      <SessionDate>
        <Caption>{sessionDate}</Caption>
      </SessionDate>
      <Card>
        <TopContent>
          <LeftSide>
            <ImageBackground>
              { !isNight && (
                <img
                  alt={t('Daytime Icon')}
                  src={IconDaytime}
                  width={20}
                />
              )}
              { isNight && (
                <img
                  alt={t('Nighttime Icon')}
                  src={IconNighttime}
                  width={20}
                />
              )}
            </ImageBackground>
            <Information>
              <Heading>{`${sessionTitle()}`}</Heading>
              <Caption>{`${startTime} - ${endTime}`}</Caption>
              <Caption>{formatDistance()}</Caption>
            </Information>
          </LeftSide>
          <ExpandIconArea>
            {profileExpanded
              ? (
                <Icon icon="ChevronUp" width={24} height={24} fill="white" onClick={() => setProfileExpanded(!profileExpanded)} />
              )
              : (
                <Icon icon="ChevronDown" width={24} height={24} fill="white" onClick={() => setProfileExpanded(!profileExpanded)} />
              )}
          </ExpandIconArea>
        </TopContent>
        { profileExpanded
          ? (
            <BottomContent>
              <Row>
                <Label>{t('Total Sleep')}</Label>
                <Label>
                  <MinutesToHoursAndMinutes totalMinutes={totalSleepMinutes()} />
                </Label>
              </Row>
              <Row>
                <Label>{t('Sleep Quality')}</Label>
                <Label>{sleepQuality}</Label>
              </Row>
              <Row>
                <Label>{t('Sleep Onset')}</Label>
                <Label><MinutesToHoursAndMinutes totalMinutes={session.sleepOnsetMinutes} /></Label>
              </Row>
              <Row>
                <Label>{t('Wakings')}</Label>
                <Label>{session.wakingsCount}</Label>
              </Row>
              <Row>
                <Label>{t('Longest Sleep Segment')}</Label>
                <Label>
                  <MinutesToHoursAndMinutes totalMinutes={session.longestSleepSegmentMinutes} />
                </Label>
              </Row>
            </BottomContent>
          )
          : <div /> }
      </Card>
    </Container>
  );
};

export default SessionCard;
