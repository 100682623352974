import styled from 'styled-components';
import { useTranslation } from 'react-i18next';
import { Body, PageHeading } from '../Typography';
import { Icon } from '../Icon';
import theme from '../../design/theme';
import { SockBattery } from '../../../shared/constants';

const ListItem = styled.div`
  display: flex;
  align-items: center;
  padding: 10px 0;
  margin-top: 10px;
`;

const StyledPageHeading = styled(PageHeading)`
  color: ${theme.color.primary.default};
`;

const StyledBody = styled(Body)`
  margin-left: 10px;
  font-weight: 600;
  width: 80%;
`;

const ModalBattery = () => {
  const { t } = useTranslation();

  return (
    <div>
      <StyledPageHeading>{t('Battery level')}</StyledPageHeading>
      <Body>
        {t('This is the estimated amount of time the sock sensor can be used before battery runs out. We recommend charging the battery daily, whenever not in use. Here is what each status means:')}
      </Body>
      <ListItem>
        <Icon icon={SockBattery.CHARGING} width={24} height={24} />
        <StyledBody>{t('Sock battery is charging or charged')}</StyledBody>
      </ListItem>
      <ListItem>
        <Icon icon={SockBattery.FULL} width={24} height={24} fill={theme.color.primary.default} />
        <StyledBody>{t('Sock battery: 18-16 hours left')}</StyledBody>
      </ListItem>
      <ListItem>
        <Icon icon={SockBattery.HIGH} width={24} height={24} />
        <StyledBody>{t('Sock battery: 15-13 hours left')}</StyledBody>
      </ListItem>
      <ListItem>
        <Icon icon={SockBattery.MEDIUMHIGH} width={24} height={24} />
        <StyledBody>{t('Sock battery: 12-10 hours left')}</StyledBody>
      </ListItem>
      <ListItem>
        <Icon icon={SockBattery.MEDIUM} width={24} height={24} />
        <StyledBody>{t('Sock battery: 9-7 hours left')}</StyledBody>
      </ListItem>
      <ListItem>
        <Icon icon={SockBattery.LOW} width={24} height={24} />
        <StyledBody>{t('Sock battery: 6-4 hours left')}</StyledBody>
      </ListItem>
      <ListItem>
        <Icon icon={SockBattery.CRITICAL} width={24} height={24} />
        <StyledBody>{t('Sock battery: 3-0 hours left')}</StyledBody>
      </ListItem>
      <ListItem>
        <Icon icon={SockBattery.DISABLED} width={24} height={24} />
        <StyledBody>
          {t('The Base Station is turned off, WiFi is disconnected, or the app is loading')}
        </StyledBody>
      </ListItem>
    </div>
  );
};

export default ModalBattery;
