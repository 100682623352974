import styled from 'styled-components';
import { useTranslation } from 'react-i18next';
import OwlIcon from '../../assets/owl_lookdown.svg';
import Button from '../../components/Button/Button';
import { PageHeading } from '../../components/Typography';
import { home } from '../../navigation/routes';

const Layout = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;

const HappyOwl = styled.img`
  width: 100px;
  height: 100px;
  margin-bottom: 30px;
`;

const StyledButton = styled(Button)`
  width: 100px;
`;

const PageDoesNotExist = () => {
  const { t } = useTranslation();
  return (
    <Layout>
      <HappyOwl
        alt={t('Owl Icon')}
        src={OwlIcon}
      />
      <PageHeading>{t('The page does not exist!')}</PageHeading>
      <StyledButton label={t('Back Home')} size="small" to={home()} />
    </Layout>
  );
};

export default PageDoesNotExist;
