import styled from 'styled-components';

import theme from '../../design/theme';

export const PageHeading = styled.h1`
  margin: 0 0 10px;
  font-family: Source Sans Pro;
  color: ${theme.color.text.darkMode.primary};
  font-weight: 600;
  font-size: 26px;
  line-height: 32px;
  letter-spacing: 0;
`;

export const PageTitle = styled.h2`
  margin: 0;
  font-family: Source Sans Pro;
  color: ${theme.color.text.darkMode.primary};
  font-weight: 700;
  font-size: 17px;
  line-height: 24px;
  letter-spacing: 0;
`;

export const Heading = styled.h3`
  margin: 0;
  font-family: Source Sans Pro;
  color: ${theme.color.text.darkMode.primary};
  font-weight: 700;
  font-size: 17px;
  line-height: 24px;
  letter-spacing: 0;
`;

export const Subheading = styled.h4`
  margin: 0;
  font-family: Source Sans Pro;
  color: ${theme.color.text.darkMode.primary};
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  letter-spacing: 0;
`;

export const Caption = styled.p`
  margin: 0;
  font-family: Source Sans Pro;
  color: ${theme.color.text.darkMode.primary};
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  letter-spacing: 0;
`;

export const Label = styled.p`
  margin: 0;
  font-family: Source Sans Pro;
  color: ${theme.color.text.darkMode.primary};
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  letter-spacing: 0;
`;

export const ButtonText = styled.p`
  margin: 0;
  font-family: Source Sans Pro;
  color: ${theme.color.text.darkMode.primary};
  font-weight: 500;
  font-size: 17px;
  line-height: 24px;
  letter-spacing: 0;
`;

export const Body = styled.p`
  margin: 0;
  font-family: Source Sans Pro;
  color: ${theme.color.text.darkMode.primary};
  font-weight: 400;
  font-size: 17px;
  line-height: 24px;
  letter-spacing: 0;
`;

export const SmallLabel = styled.p`
  margin: 0;
  font-family: Source Sans Pro;
  color: ${theme.color.text.darkMode.primary};
  font-weight: 500;
  font-size: 12px;
  line-height: 12px;
  letter-spacing: 0;
`;
