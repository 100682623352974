import styled from 'styled-components';
import { useNavigate } from 'react-router';
import { PageTitle } from '../Typography';
import theme from '../../design/theme';
import Icon from '../Icon/Icon';

type HeaderProps = {
  title?: string;
  backButton?: boolean;
  rightButton?: React.ReactNode;
  backgroundDark?: boolean;
  goBackFn?: () => void;
}
const Container = styled.div<HeaderProps>`
  display: flex;
  background-color: ${({ backgroundDark }) => (backgroundDark ? theme.color.background.darkMode.darker : theme.color.background.darkMode.dark)};
  height: 80px;
  justify-content: space-between;
  align-items: center;
  width: inherit;
  position: sticky;
  top: 0;
  z-index: 1;
`;

const BackButton = styled.div`
  padding: 16px;
`;

const ButtonContainer = styled.div`
  min-width: 60px;
`;

const NavHeader = ({
  title, backButton, rightButton, backgroundDark, goBackFn,
} : HeaderProps) => {
  const navigate = useNavigate();
  const goBack = goBackFn ?? (() => {
    navigate(-1);
  });

  return (
    <Container backgroundDark={backgroundDark}>
      <ButtonContainer>
        { backButton
          ? (
            <BackButton onClick={() => goBack()}>
              <Icon icon="ChevronLeft" width={24} height={24} fill={theme.color.text.darkMode.primary} />
            </BackButton>
          )
          : <div />}
      </ButtonContainer>
      <PageTitle>
        {title}
      </PageTitle>
      <ButtonContainer>
        {rightButton}
      </ButtonContainer>
    </Container>
  );
};

export default NavHeader;
