import styled from 'styled-components';
import LaunchIcon from '@mui/icons-material/Launch';
import { useTranslation } from 'react-i18next';
import { Body, PageHeading } from '../../components/Typography';
import BottomTextAndArrow from '../../components/FormComponents/BottomTextAndArrow';
import { wifiConfirmation } from './routes';
import BackButton from '../../components/Button/BackButton';
import theme from '../../design/theme';

const Layout = styled.div`
  display: flex;
  flex-direction: column;
  position: relative;
  `;

const Content = styled.div`
  display: flex;
  flex-direction: column;
  padding: 20px;
`;

const ButtonContainer = styled.div`
  position: fixed;
  width: 90%;
  bottom: 0;
  padding: 40px 20px;
  background-color: ${theme.color.background.darkMode.darker};
`;

const ExternalListItem = styled.a`
  padding: 16px 0;
  display: flex;
  width: 160px;
  align-items: center;
  justify-content: space-between;
  text-decoration: none;
  color: ${theme.color.primary.default};
`;

const WifiInstructions = () => {
  const { t } = useTranslation();
  return (
    <Layout>
      <BackButton />
      <Content>
        <PageHeading>{t('Wi-Fi Instructions')}</PageHeading>
        <Body>
          {t('Follow the instructions linked below to connect your base station to the internet. You will be asked to leave the application and setup wifi using another browser window.')}
        </Body>
        {' '}
        <ExternalListItem
          target="_blank"
          rel="noopener noreferrer"
          href="https://support.owletcare.com/hc/en-us/articles/4406067813645-Smart-Sock-3-Connecting-to-Wi-Fi-Using-a-Mobile-Browser"
        >
          {t('Setup instructions')}
          <LaunchIcon />
        </ExternalListItem>
        <Body>
          {t('Once completed, return to this application to continue.')}
        </Body>
      </Content>
      <ButtonContainer>
        <BottomTextAndArrow buttonText={t('Next')} route={wifiConfirmation()} />
      </ButtonContainer>
    </Layout>
  );
};

export default WifiInstructions;
