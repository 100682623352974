import { useEffect, EffectCallback } from 'react';
import { useAuthContext } from '../contexts/authContext';

type UseLogoutHook = (c: EffectCallback) => void

const useLogout: UseLogoutHook = (onLogout) => {
  const { firebaseToken } = useAuthContext();
  useEffect(() => {
    if (!firebaseToken) {
      return onLogout();
    }

    return () => undefined;
  }, [onLogout, firebaseToken]);
};

export default useLogout;
