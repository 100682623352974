import {
  createContext, useCallback, useContext, useEffect, useMemo, useState,
} from 'react';
import _ from 'lodash';
import useLogout from '../hooks/useLogout';
import setFirebaseObserver from '../utils/firebaseObserver';
import { emptyService, FirebaseResponseStatus, Service } from '../../shared/types';
import { useAccountContext } from './accountContext';

type ServiceProviderProps = { children: React.ReactNode }

type NormalizedServices = {[key:string]: Service}

type StateType = {
  findService: (s: string) => Service | undefined
  findServiceByDeviceKey: (s: string) => Service | undefined
  removeService: (s: string) => void,
  services: NormalizedServices,
  servicesList: Service[],
  status: FirebaseResponseStatus,
};

const initialState: StateType = {
  findService: (_s) => undefined,
  findServiceByDeviceKey: (_s) => undefined,
  removeService: (_s) => undefined,
  services: {},
  servicesList: [],
  status: FirebaseResponseStatus.idle,
};

const Context = createContext<StateType>(initialState);

const useServiceContext = () => {
  const context = useContext(Context);
  if (context === undefined) {
    throw new Error('ServiceContext context must be used within an ServiceProvider');
  }
  return context;
};

const v3ServiceType = 'smartSock3';

const ServiceProvider = ({ children }: ServiceProviderProps) => {
  const { account } = useAccountContext();
  const [services, setServices] = useState<NormalizedServices>(initialState.services);
  const [status, setStatus] = useState<FirebaseResponseStatus>(initialState.status);
  const servicesList = useMemo(() => Object.values(services), [services]);
  const findService = useCallback((serviceKey: string) => (
    services[serviceKey]
  ), [services]);
  const findServiceByDeviceKey = useCallback((deviceKey: string) => (
    servicesList.find((service) => service.deviceKey === deviceKey)
  ), [servicesList]);

  const removeService = useCallback((key: string) => {
    setServices((previousServices) => (_.omit(previousServices, [key])));
  }, []);

  useEffect(() => {
    const unsubscribeObservers: (() => void)[] = [];
    const serviceKeys = Object.keys(account.serviceKeys);

    setServices((previousServices) => Object.keys(previousServices).reduce(
      (acc, key) => {
        if (!serviceKeys.includes(key)) {
          return acc;
        }
        return { ...acc, ...{ [key]: previousServices[key] } };
      },
      initialState.services,
    ));

    serviceKeys.forEach((serviceKey) => {
      const refKey = `services/${serviceKey}`;
      const setServiceData = (newStatus: FirebaseResponseStatus, newService: Partial<Service>) => {
        if (newService) {
          if (newService.type === v3ServiceType) {
            const newServiceNormalized = {
              [serviceKey]: {
                ...emptyService,
                ...{ id: serviceKey, ...newService },
              },
            };
            setServices((previousServices) => ({ ...previousServices, ...newServiceNormalized }));
          }
        }
        setStatus(newStatus);
      };
      const observer = setFirebaseObserver<Service>(refKey, setServiceData);
      unsubscribeObservers.push(observer);
    });

    return () => {
      unsubscribeObservers.forEach((unsubscribe) => {
        unsubscribe();
      });
    };
  }, [account]);

  const clearServices = useCallback(() => {
    setServices(initialState.services);
    setStatus(initialState.status);
  }, []);

  useLogout(clearServices);

  return (
    <Context.Provider value={{
      findService, findServiceByDeviceKey, removeService, services, servicesList, status,
    }}
    >
      {children}
    </Context.Provider>
  );
};

export { ServiceProvider, useServiceContext };
