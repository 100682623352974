import styled from 'styled-components';
import { useState } from 'react';
import theme from '../../design/theme';
import { Heading, PageHeading } from '../../components/Typography';
import { Icon } from '../../components/Icon';
import { DeviceDetails, ServiceUser } from '../../../shared/types';
import Modal from '../../components/Modal/Modal';
import ModalBabyStatus from '../../components/Modal/ModalBabyStatus';

interface ActiveProps {
  active?: boolean;
}

const Container = styled.div`
  margin: 16px auto;
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
`;

const StatusContainer = styled.div`
  display: grid;
  grid-template-columns: 10% 80% 10%;
  align-items: center;
  width: 100%;
`;

const StatsContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-around;
  padding: 0 16px;
  width: 100%;
`;

const StyledHeading = styled(Heading)`
  display: flex;
  justify-content: center;
  grid-column: 2;
 `;

const Stat = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-around;
  padding: 16px;
`;

const StatLabel = styled(PageHeading)<ActiveProps>`
 color: ${({ active }) => (active ? theme.color.text.darkMode.primary : theme.color.text.darkMode.secondary)};
 font-size: 30px;
 `;

const StatusIcon = styled(Icon)<ActiveProps>`
  fill: ${({ active }) => (active ? theme.color.text.darkMode.secondary : theme.color.primary.default)};
  margin-bottom: 20px;
`;

const Divider = styled.div`
  width: 1px;
  height: 80px;
  background-color: ${theme.color.background.darkMode.darker};
`;

type TopBannerDataProps = {
  deviceDetails: DeviceDetails;
  serviceUser: ServiceUser;
}

const TopBannerData = ({
  deviceDetails, serviceUser,
}: TopBannerDataProps) => {
  const setIsMoving = (deviceDetails.sc === 2 && deviceDetails.srf === 2);
  const hrLabel = deviceDetails.hr ? `${deviceDetails.hr} BPM` : '---';
  const oxLabel = deviceDetails.ox ? `${deviceDetails.ox}% O2` : '---';
  const [modalActive, setModalActive] = useState<boolean>(false);
  return (
    <Container>
      <StatusContainer>
        <StyledHeading>{serviceUser.firstName}</StyledHeading>
        <Icon
          icon="Help"
          width={20}
          height={20}
          fill={theme.color.text.darkMode.primary}
          onClick={() => setModalActive(!modalActive)}
        />
      </StatusContainer>
      {setIsMoving ? (
        <Icon icon="Movement" fill={theme.color.primary.default} width={96} height={96} />
      ) : (
        <StatsContainer>
          <Stat>
            <StatusIcon icon="StatusHeartrate" width={40} height={40} />
            <StatLabel active={deviceDetails.hr !== undefined}>
              {hrLabel}
            </StatLabel>
          </Stat>
          <Divider />
          <Stat>
            <StatusIcon icon="StatusOxygen" width={40} height={40} />
            <StatLabel active={deviceDetails.ox !== undefined}>
              {oxLabel}
            </StatLabel>
          </Stat>
        </StatsContainer>
      )}
      <Modal
        isActive={modalActive}
        onClose={() => setModalActive(!modalActive)}
      >
        <ModalBabyStatus />
      </Modal>
    </Container>
  );
};

export default TopBannerData;
