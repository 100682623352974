import React from 'react';

import { Link } from 'react-router-dom';
import styled, { css } from 'styled-components';

import theme from '../../design/theme';
import { ButtonText } from '../Typography';

export interface ButtonProps {
  label: string;
  size?: 'large' | 'small';
  to?: string;
  type?: string;
  className?: string;
  disabled?: boolean;
  onClick?: () => void;
}

const ButtonStyles = css<Partial<ButtonProps>>`
  display: flex;
  flex-grow: 0;
  flex-shrink: 0;
  flex-flow: row nowrap;
  justify-content: center;
  align-items: center;
  min-width: 50px;
  height: 48px;
  padding: 0 24px;
  margin-top: 16px;
  border-radius: 8px;
  border: none;
  background-color: ${theme.color.primary.default};
  transition: background-color;
  text-decoration: none;

  &:hover {
    background-color: ${theme.color.primary.default};
  }

  ${({ size }) => size === 'large'
    && css`
      height: 48px;
      padding: 0 24px;
    `}

  ${({ size }) => size === 'small'
    && css`
      height: 40px;
      padding: 0 16px;
    `}

  ${({ disabled }) => disabled === true
    && css`
      cursor: not-allowed;
      pointer-events: none;
      opacity: 0.4;
    `}
`;

const Layout = styled(Link)`
  ${ButtonStyles}
`;

const StyledButtonText = styled(ButtonText)`
  color: ${theme.color.background.darkMode.dark};
  border: none;
  outline: none;
`;

const Button: React.FC<ButtonProps> = ({
  label,
  size = 'large',
  to = '#',
  type = 'button',
  className,
  onClick,
  disabled = false,
}) => (
  <Layout
    size={size}
    type={type}
    className={className}
    to={to}
    disabled={disabled}
    onClick={() => {
      onClick?.();
    }}
  >
    <StyledButtonText>{label}</StyledButtonText>
  </Layout>
);

export default Button;

// TODO: In order to allow keypress events api, this must be an input.

// onKeyPress?: { key: string, handler: () => void };

// onKeyPress={(e) => {
//   const { key, handler } = onKeyPress;
//   if (!disabled && e.key === key) {
//     handler?.();
//   }
// }}
