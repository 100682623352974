import { Navigate, Route, Routes } from 'react-router-dom';
import ChooseDeviceType from './ChooseDeviceType';
import DeviceNotFound from './DeviceNotFound';
import PairFailed from './PairFailed';
import RegisterDevice from './RegisterDevice';
import {
  chooseDevice,
  chooseDevicePath,
  deviceNotFoundPath,
  pairFailedPath,
  registerDevicePath,
  rootPath,
  safetyInfoPath,
  searchSockPath,
  baseSetupPath,
  basePlugInPath,
  wifiConfirmationPath,
  wifiInstructionsPath,
  addChildPath,
  aboutChildPath,
  notBornYetPath,
  childIsBornPath,
  assignChildPath,
  tutorialSockPath,
  tutorialNotificationsPath,
  tutorialReadingsPath,
  successPath,
  checkOTAUpdatesPath,
  oTAUpdatesSuccessPath,
  oTAUpdatesPausedPath,
} from './routes';
import SafetyInfo from './SafetyInfo';
import BaseSetup from './BaseSetup';
import BasePlugIn from './BasePlugIn';
import WifiInstructions from './WifiInstructions';
import WifiConfirmation from './WifiConfimation';
import TutorialSock from './TutorialSock';
import TutorialReadings from './TutorialReadings';
import TutorialNotifications from './TutorialNotifications';
import SearchSock from './SearchSock';
import AddChildStep from './AddChildStep';
import AboutChild from '../CreateProfile/views/AboutChild';
import NotBornYet from '../CreateProfile/views/NotBornYet';
import IsBorn from '../CreateProfile/views/IsBorn';
import AssignChildStep from './AssignChildStep';
import Success from './Success';
import OTAUpdatesSuccess from './OTAUpdatesSuccess';
import CheckOTAUpdates from './CheckOTAUpdates';
import OTAUpdatesPaused from './OTAUpdatesPaused';

const DeviceSetupRouter = () => (
  <Routes>
    <Route
      path={rootPath()}
      element={<Navigate to={chooseDevice()} />}
    />
    <Route
      path={chooseDevicePath()}
      element={<ChooseDeviceType />}
    />
    <Route
      path={wifiInstructionsPath()}
      element={<WifiInstructions />}
    />
    <Route
      path={baseSetupPath()}
      element={<BaseSetup />}
    />
    <Route
      path={basePlugInPath()}
      element={<BasePlugIn />}
    />
    <Route
      path={wifiConfirmationPath()}
      element={<WifiConfirmation />}
    />
    <Route
      path={pairFailedPath()}
      element={<PairFailed />}
    />
    <Route
      path={deviceNotFoundPath()}
      element={<DeviceNotFound />}
    />
    <Route
      path={searchSockPath()}
      element={<SearchSock />}
    />
    <Route
      path={checkOTAUpdatesPath()}
      element={<CheckOTAUpdates />}
    />
    <Route
      path={oTAUpdatesPausedPath()}
      element={<OTAUpdatesPaused />}
    />
    <Route
      path={oTAUpdatesSuccessPath()}
      element={<OTAUpdatesSuccess />}
    />
    <Route
      path={safetyInfoPath()}
      element={<SafetyInfo />}
    />
    <Route
      path={registerDevicePath()}
      element={<RegisterDevice />}
    />
    <Route
      path={addChildPath()}
      element={<AddChildStep />}
    />
    <Route
      path={assignChildPath()}
      element={<AssignChildStep />}
    />
    <Route
      path={aboutChildPath()}
      element={<AboutChild />}
    />
    <Route
      path={notBornYetPath()}
      element={<NotBornYet />}
    />
    <Route
      path={childIsBornPath()}
      element={<IsBorn />}
    />
    <Route
      path={tutorialSockPath()}
      element={<TutorialSock />}
    />
    <Route
      path={tutorialNotificationsPath()}
      element={<TutorialNotifications />}
    />
    <Route
      path={tutorialReadingsPath()}
      element={<TutorialReadings />}
    />
    <Route
      path={successPath()}
      element={<Success />}
    />
  </Routes>
);

export default DeviceSetupRouter;
