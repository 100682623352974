import {
  useCallback,
  useContext,
  useEffect,
  useState,
} from 'react';
import { SplitFactory, useClient, SplitContext } from '@splitsoftware/splitio-react';
import featureFlagConfig from '../../config/featureFlagConfig';
import Loader from '../../../ui/navigation/Loader';
import FeatureFlagContext from './context';
import { featureFlagDefaultKey } from '../../../shared/constants';

type FeatureFlagProviderProps = { children: JSX.Element }

const FeatureFlagProvider = ({ children }: FeatureFlagProviderProps) => {
  const [currentUserClient, setCurrentUserClient] = useState<SplitIO.IClient | undefined>();
  const [currentUserId, setCurrentUserId] = useState<string>(featureFlagDefaultKey);
  const { isReady, client: contextClient } = useContext(SplitContext);
  const client = useClient(currentUserId);

  const userInit = useCallback((userId: string) => {
    if (currentUserId !== userId) {
      setCurrentUserId(userId);
    }
  }, [currentUserId]);

  const userDeinit = useCallback(() => {
    setCurrentUserId(featureFlagDefaultKey);
  }, []);

  useEffect(() => {
    if (isReady && contextClient && currentUserId === featureFlagDefaultKey) {
      setCurrentUserClient(contextClient);
    }

    if (currentUserId !== featureFlagDefaultKey && client) {
      client?.on(client.Event.SDK_READY, () => {
        setCurrentUserClient(client);
      });
    }
  }, [client, isReady, contextClient, currentUserId]);

  return (
    <FeatureFlagContext.Provider value={
    {
      userInit,
      userDeinit,
      featureFlagClient: currentUserClient,
    }
  }
    >
      {!currentUserClient ? <Loader /> : children}
    </FeatureFlagContext.Provider>
  );
};

const FeatureFlagProviderWrapper = ({ children }: FeatureFlagProviderProps) => (
  <SplitFactory config={featureFlagConfig()}>
    <FeatureFlagProvider>
      {children}
    </FeatureFlagProvider>
  </SplitFactory>
);

export default FeatureFlagProviderWrapper;
