import { translationKeyType } from './react-i18next.d';

type OptionType = {
  text: React.Key | null | undefined | string;
  value: string | number;
}

type FormData = {
  firstName: string;
  birthDateValue: number | undefined;
  genderValue: string;
  relationshipValue: string;
  dueDateValue: number | undefined;
  nicuStayValue: string;
};

enum FeatureFlags {
  HISTORY = 'webapp_history',
  HISTORY_GRAPHS = 'webapp_history_graphs',
  STAGING_BANNER = 'webapp_staging_banner',
  NPS_SURVEY = 'webapp_nps_survey'
}

enum FeatureFlagTreatments {
  ON = 'on',
  OFF = 'off',
  CONTROL = 'control'
}

const featureFlagDefaultKey = 'anonymous';

const genderOptions: {text: translationKeyType, value: string}[] = [
  {
    text: 'Unspecified',
    value: 'Unspecified',
  },
  {
    // t('Male')
    text: 'Male',
    value: 'male',
  },
  {
    // t('Female')
    text: 'Female',
    value: 'female',
  },
];

const relationshipOptions: {text: translationKeyType, value: string}[] = [
  {
    text: 'Unspecified',
    value: 'Unspecified',
  },
  {
    // t('General Care')
    text: 'General Care',
    value: 'generalCare',
  },
  {
    // t('Other')
    text: 'Other',
    value: 'other',
  },
  {
    // t('Grand Child')
    text: 'Grand Child',
    value: 'grandChild',
  },
  {
    // t('Adopted')
    text: 'Adopted',
    value: 'adopted',
  },
  {
    // t('Biological Child')
    text: 'Biological Child',
    value: 'biologicalChild',
  },
];

const nicuStayOptions: {text: translationKeyType, value: string}[] = [
  {
    text: 'Unspecified',
    value: 'Unspecified',
  },
  {
    text: 'Yes',
    value: 'true',
  },
  {
    text: 'No',
    value: 'false',
  },
];

const allRaces: {text: translationKeyType, value: string}[] = [
  {
    // t('White/Caucasian')
    text: 'White/Caucasian',
    value: 'white',
  },
  {
    // t('Hispanic or Latino')
    text: 'Hispanic or Latino',
    value: 'hispanic',
  },
  {
    // t('Black or African American')
    text: 'Black or African American',
    value: 'black',
  },
  {
    // t('Asian')
    text: 'Asian',
    value: 'asian',
  },
  {
    // t('American Indian or Alaska Native')
    text: 'American Indian or Alaska Native',
    value: 'nativeAmerican',
  },
  {
    // t('Native Hawaiian or Other Pacific Islander')
    text: 'Native Hawaiian or Other Pacific Islander',
    value: 'islander',
  },
  {
    // t('Other Race')
    text: 'Other Race',
    value: 'other',
  },
];

enum SockBattery {
  FULL = 'StatusBatteryFull',
  HIGH = 'StatusBattery15',
  MEDIUMHIGH= 'StatusBattery12',
  MEDIUM = 'StatusBattery9',
  LOW = 'StatusBattery6',
  CRITICAL = 'StatusBattery3',
  DISABLED = 'StatusBatteryDisabled',
  CHARGING = 'StatusBatteryCharging'
}

enum PasswordStrength {
  // t('Strongest')
  Strongest = 'Strongest',
  // t('Stronger')
  Stronger = 'Stronger',
  // t('Strong')
  Strong = 'Strong',
  // t('Weak')
  Weak = 'Weak',
  // t('Weakest')
  Weakest = 'Weakest'
}

export {
  allRaces,
  featureFlagDefaultKey,
  FeatureFlags,
  FeatureFlagTreatments,
  genderOptions,
  nicuStayOptions,
  PasswordStrength,
  relationshipOptions,
  SockBattery,
};

export type { OptionType, FormData };

export const HISTORY_REQUEST_DEBOUNCE_MILLIS = 250;

export const NPS_SURVEY_TITLE = 'NPS Survey';

export const OWLET_USER_AGENT_HEADER = 'Owlet-User-Agent';

export const ECONNABORTED = 'ECONNABORTED';
