import { Link } from 'react-router-dom';
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import styled from 'styled-components';
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import { useTranslation } from 'react-i18next';
import theme from '../../design/theme';
import Avatar from '../../components/Avatar';
import { PageTitle } from '../../components/Typography';
import { deviceCollection } from '../../navigation/routes';
import { emptyServiceUser, DeviceDetails } from '../../../shared/types';
import TopBannerData from './TopBannerData';
import { useServiceUserContext } from '../../../backend/contexts/serviceUserContext';

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  background: linear-gradient(#9F9FDC, #34323F);
  padding: 16px;
`;

const Top = styled.div`
  display: flex;
  align-items: flex-start;
  width: 100%;
  padding: 16px 0;
`;

const AddDeviceButton = styled(Link)`
  display: flex;
  align-items: center;
  color: ${theme.color.primary.default};
  margin: 16px 0 0;
`;

const LinkText = styled(PageTitle)`
  margin-left: 6px;
  color: ${theme.color.primary.default};
  font-weight: 500;
`;

const goBack = () => {
  window.history.back();
};

type BannerProps = {
  hasDevice: boolean,
  showBackButton: boolean,
  deviceDetails: DeviceDetails,
  serviceUserKey: string,
}

const TopBanner = ({
  hasDevice, showBackButton, deviceDetails, serviceUserKey,
}: BannerProps) => {
  const { findServiceUser } = useServiceUserContext();
  const data = findServiceUser(serviceUserKey) || emptyServiceUser;
  const { t } = useTranslation();

  return (
    <Container>
      <Top>
        {showBackButton ? (
          <ArrowBackIosIcon
            style={{
              fontSize: '24px',
              color: '#D4D4F9',
              padding: '8px',
            }}
            onClick={() => goBack()}
          />
        ) : null }
      </Top>
      <Avatar size="large" />
      {hasDevice
        ? (
          <TopBannerData
            deviceDetails={deviceDetails}
            serviceUser={data}
          />
        )
        : (
          <AddDeviceButton to={deviceCollection()}>
            <AddCircleOutlineIcon fontSize="small" />
            <LinkText>{t('Add an Owlet device')}</LinkText>
          </AddDeviceButton>
        )}
    </Container>
  );
};

export default TopBanner;
