import { useState, useEffect } from 'react';
import styled from 'styled-components';
import { useNavigate } from 'react-router-dom';
import {
  ref,
  child,
  push,
  getDatabase,
} from 'firebase/database';
import FormControl from '@mui/material/FormControl';
import { LocalizationProvider } from '@mui/lab';
import AdapterDateFns from '@mui/lab/AdapterDateFns';
import { useTranslation } from 'react-i18next';
import UseFirebasePostDataHook from '../../../../backend/utils/useFirebasePostDataHook';
import { useAuthContext } from '../../../../backend/contexts/authContext';
import DateChooser from '../../../components/FormComponents/DateChooser';
import { PageHeading } from '../../../components/Typography';
import BackButton from '../../../components/Button/BackButton';
import theme from '../../../design/theme';
import { useServiceUserContext } from '../../../../backend/contexts/serviceUserContext';
import { useDeviceSetupContext } from '../../../../backend/contexts/deviceSetupContext';
import { locales } from '../../../../backend/config/i18n';
import { mapUserToDevice } from '../../../../backend/utils/owletApi';
import { useErrorContext } from '../../../../backend/contexts/errorContext';
import { success } from '../../DeviceSetup/routes';
import BottomTextAndArrow from '../../../components/FormComponents/BottomTextAndArrow';

const Layout = styled.div`
  display: flex;
  flex-direction: column;
  position: relative;
  `;

const Content = styled.div`
  display: flex;
  flex-direction: column;
  padding: 20px;
`;

const ButtonContainer = styled.div`
  width: 90%;
  padding: 40px 20px;
  background-color: ${theme.color.background.darkMode.darker};
`;

const NotBornYet = () => {
  const { currentUser } = useAuthContext();

  const { dsn, serviceCreated } = useDeviceSetupContext();
  const { withErrorHandler } = useErrorContext();
  const navigation = useNavigate();
  const { addServiceUser } = useServiceUserContext();
  const { t, i18n } = useTranslation();

  const db = getDatabase();
  const { firebaseToken } = useAuthContext();

  const [dueDateValue, setDueDateValue] = useState<number | undefined>(undefined);
  const [nextButtonDisabled, setNextButtonDisabled] = useState(true);

  const postNewProfile = async () => {
    const newProfileKey = push(child(ref(db), 'serviceUsers')).key;
    const postData = {
      accountKey: `${currentUser?.uid}`,
      type: 'child',
      firstName: 'Child',
      dueDate: dueDateValue,
      id: newProfileKey,
    };

    const mapUser = (serviceUserId: string, serviceKey: string) => {
      mapUserToDevice(`${firebaseToken}`, serviceUserId, dsn).then(() => {
        withErrorHandler(UseFirebasePostDataHook(
          'services',
          `${serviceKey}/serviceUserKeys`,
          { [serviceUserId]: true },
        ));
      });
    };

    // an exmaple on how ot use the useFirebasePostDataHook(node, nodeId, uid, data)
    await UseFirebasePostDataHook('/serviceUsers', newProfileKey, postData).then(() => {
      const id = `${newProfileKey}`;
      addServiceUser({
        [id]: {
          ...postData, avatar: '', id, races: [], relationship: null,
        },
      });
      if (dsn && serviceCreated) {
        mapUser(id, serviceCreated.id);
      }
      if (serviceCreated) {
        navigation(success());
      } else {
        navigation('/child-profile/success');
      }
    });
  };

  const handleSubmit = (e: React.MouseEvent<HTMLButtonElement>) => {
    setNextButtonDisabled(true);
    e.preventDefault();
    postNewProfile();
  };
  // TODO put in the callback on completion for the RTDB update function. Unpon success
  // route to 'OwlSet'.
  useEffect(() => {
    setDueDateValue(dueDateValue || undefined);
    const dDate = dueDateValue;
    if (dDate) {
      setNextButtonDisabled(false);
    } else {
      setNextButtonDisabled(true);
    }
  }, [dueDateValue]);

  return (
    <LocalizationProvider dateAdapter={AdapterDateFns} locale={locales[i18n.language]}>
      <Layout>
        <BackButton />
        <Content>
          <PageHeading>{t('Please select your due date')}</PageHeading>
          <FormControl variant="standard" error>
            <DateChooser dateValue={dueDateValue} label={t('Due Date*')} setter={setDueDateValue} />
          </FormControl>
          <ButtonContainer>
            <BottomTextAndArrow isDisabled={nextButtonDisabled} buttonText={t('Next')} onClick={handleSubmit} />
          </ButtonContainer>
        </Content>
      </Layout>
    </LocalizationProvider>
  );
};

export default NotBornYet;
