import AddIcon from '@mui/icons-material/Add';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { childIsBorn } from '../../../navigation/routes';

const AddChildRow = () => {
  const dialogItemGrid = {
    display: 'grid',
    gridTemplateColumns: '20% 70% 10%',
    gridTemplateRows: '10% 80% 10%',
  };

  const iconArea = {
    gridColumn: '1',
    gridRow: '2',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  };

  const iconStyle = {
    fontSize: '24px',
    color: '#D4D4F9',
  };

  const addChildTextArea = {
    gridColumn: '2',
    gridRow: '2',
    display: 'flex',
    justifyContent: 'flex-start',
    alignItems: 'center',
    paddingLeft: '20px',
  };

  const addChildTextStyle = {
    fontSize: '24px',
    color: '#D4D4F9',
  };

  const navigate = useNavigate();
  const { t } = useTranslation();

  return (
    <div
      style={dialogItemGrid}
      onKeyDown={() => navigate(childIsBorn())}
      onClick={() => navigate(childIsBorn())}
      role="button"
      tabIndex={0}
    >
      <div style={iconArea}>
        <AddIcon style={iconStyle} />
      </div>
      <div style={addChildTextArea}>
        <div style={addChildTextStyle}>
          {t('Add child')}
        </div>
      </div>
    </div>
  );
};
export default AddChildRow;
