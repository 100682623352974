import styled, { css } from 'styled-components';
import theme from '../../design/theme';
import DefaultAvatar from '../../assets/default_child_avatar.svg';

type AvatarSize = 'xsmall' | 'small' | 'medium' | 'large';

export interface AvatarProps {
  image?: string;
  size: AvatarSize;
}

interface LayoutProps {
  hasImage?: boolean;
  size?: AvatarSize;
}

const SIZE_XSMALL = 36;
const SIZE_SMALL = 64;
const SIZE_MEDIUM = 96;
const SIZE_LARGE = 140;

const Container = styled.div<LayoutProps>`
  width: 96px;
  height: 96px;
  background: ${theme.color.background.darkMode.dark};
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  overflow: hidden;

    ${({ size }) => size === 'xsmall'
    && css`
        width: ${SIZE_XSMALL}px;
        height: ${SIZE_XSMALL}px;
        `}
  
    ${({ size }) => size === 'small'
    && css`
        width: ${SIZE_SMALL}px;
        height: ${SIZE_SMALL}px;
        `}

    ${({ size }) => size === 'medium'
    && css`
        width: ${SIZE_MEDIUM}px;
        height: ${SIZE_MEDIUM}px;
        `}

    ${({ size }) => size === 'large'
    && css`
        width: ${SIZE_LARGE}px;
        height: ${SIZE_LARGE}px;
        `}
`;

const Image = styled.img`
  width: 100%;
  height: 100%;
  object-fit: cover;
`;

const DefaultImage = styled.img`
  width: 80%;
  height: 80%;
  object-fit: contain;
`;

const Avatar = ({ image, size = 'medium' }: AvatarProps) => (
  <Container size={size}>
    {image ? <Image src={image} alt="Avatar" /> : <DefaultImage src={DefaultAvatar} alt="Avatar" />}
  </Container>
);

export default Avatar;
