import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import { Avatar } from '@mui/material';

// serviceUsers /serviceUsers/{suid}
// {
//   "accountKey": "dpTVVbAgH1TS3sPV8TsEs2axSPH3",
//   "avatars": {
//     "-MpNBRLa6sfeUYWTlxAR": {
//       "filename": "1.637862194994E9.png",
//       "id": "",
//       "timestamp": 1637862196.9
//     }
//   },
//   "firstName": "Dev 2",
// }

type ChildProfileRowProps = {
  img: string;
  name: string;
};

const ChildProfileRow = ({ img, name }: ChildProfileRowProps) => (
  <div style={dialogChildProfileGrid}>
    <div style={childProfileIconArea}>
      <Avatar
        alt={name}
        src={img}
        style={childProfileIconStyle}
      />
    </div>
    <div style={childProfileTextArea}>
      <div style={childProfileTextStyle}>
        {name}
      </div>
    </div>
    <div style={childProfileArrowIconArea}>
      <ArrowForwardIosIcon style={childProfileArrowIconStyle} />
    </div>
  </div>
);

const dialogChildProfileGrid = {
  display: 'grid',
  gridTemplateColumns: '50px 60% 10%',
  gridTemplateRows: '10px 30px 10px',
  marginBottom: '30px',
};

const childProfileIconArea = {
  gridColumn: '1',
  gridRow: '2',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
};

const childProfileIconStyle = {
  height: '48px',
  width: '48px',
  left: '0px',
  top: '0px',
  borderRadius: '120px',
  color: '#D4D4F9',
};

const childProfileTextArea = {
  gridColumn: '2',
  gridRow: '2',
  display: 'flex',
  justifyContent: 'flex-start',
  alignItems: 'center',
  paddingLeft: '20px',
};

const childProfileTextStyle = {
  fontFamily: 'Source Sans Pro',
  fontSize: '17px',
  fontStyle: 'normal',
  fontWeight: '700',
  lineHeight: '24px',
  letterSpacing: '0px',
  color: '#D4D4F9',
};

const childProfileArrowIconArea = {
  gridColumn: '3',
  gridRow: '2',
  display: 'flex',
  justifyContent: 'flex-start',
  alignItems: 'center',
  paddingLeft: '20px',
};

const childProfileArrowIconStyle = {
  fontSize: '24px',
  color: '#D4D4F9',
};

export default ChildProfileRow;
