import {
  getDatabase, onValue, ref, Unsubscribe,
} from 'firebase/database';
import { FirebaseResponseStatus } from '../../shared/types';

const db = getDatabase();

type FirebaseObserverCallback<T> = (status: FirebaseResponseStatus, data: T) => void
type FirebaseObserver = <ResponseData>(
  ref: string,
  callback: FirebaseObserverCallback<ResponseData>
) => Unsubscribe

export const setFirebaseObserver: FirebaseObserver = (
  refKey,
  callback,
) => {
  const firebaseNodeRef = ref(db, refKey);
  const unsubscribe = onValue(firebaseNodeRef, (snapshot) => {
    const observerData = snapshot.val();
    const observerStatus = observerData
      ? FirebaseResponseStatus.success
      : FirebaseResponseStatus.error;
    callback(observerStatus, observerData);
  });

  return unsubscribe;
};

export default setFirebaseObserver;
