import {
  createContext, useContext, useEffect, useState,
} from 'react';
import { Device, Service } from '../../shared/types';
import { useDeviceContext } from './deviceContext';
import { useServiceContext } from './serviceContext';

type DeviceSetupProviderProps = { children: React.ReactNode }

type StateType = {
  setDsn: (s: string) => void,
  dsn: string,
  deviceCreated: Device | undefined,
  serviceCreated: Service | undefined,
};

const initialState: StateType = {
  setDsn: (_) => undefined,
  dsn: '',
  deviceCreated: undefined,
  serviceCreated: undefined,
};

const Context = createContext<StateType>(initialState);

const useDeviceSetupContext = () => {
  const context = useContext(Context);
  if (context === undefined) {
    throw new Error('DeviceSetupContext context must be used within an DeviceSetuprovider');
  }
  return context;
};

const DeviceSetupProvider = ({ children }: DeviceSetupProviderProps) => {
  const { findDevice, findDeviceKeyByDsn } = useDeviceContext();
  const { findServiceByDeviceKey } = useServiceContext();
  const [dsn, setDsn] = useState<string>(initialState.dsn);
  const [device, setDevice] = useState<Device | undefined>(undefined);
  const [service, setService] = useState<Service | undefined>(undefined);
  useEffect(() => {
    if (dsn) {
      const deviceKeyCreated = findDeviceKeyByDsn(dsn);
      if (deviceKeyCreated) {
        setDevice(findDevice(deviceKeyCreated));
        setService(findServiceByDeviceKey(deviceKeyCreated));
      }
    }
  },
  [dsn, findDevice, findDeviceKeyByDsn, findServiceByDeviceKey]);

  return (
    <Context.Provider value={{
      dsn, setDsn, deviceCreated: device, serviceCreated: service,
    }}
    >
      {children}
    </Context.Provider>
  );
};

export { DeviceSetupProvider, useDeviceSetupContext };
