import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import Button from '../../components/Button/Button';
import { Body, PageTitle } from '../../components/Typography';
import theme from '../../design/theme';
import HappyOwl from '../../assets/owl_happy.svg';
import { gettingStarted } from '../../navigation/routes';

const Container = styled.div`
  background: ${theme.color.background.darkMode.dark};
  padding: 16px 24px;
  margin: 104px 24px 24px;
  border-radius: 8px;
  display: flex;
  align-items: center;
  flex-direction: column;
  text-align: center;
`;

const CircleBackground = styled.div`
  width: 160px;
  height: 160px;
  background: ${theme.color.background.darkMode.dark};
  border-radius: 80px;
  margin-top: -90px;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const GetStartedCard = () => {
  const { t } = useTranslation();
  return (
    <Container>
      <CircleBackground>
        <img src={HappyOwl} alt="Happy Owl" />
      </CircleBackground>
      <PageTitle>{t('Welcome to the Owlet Care App!')}</PageTitle>
      <Body>{t('Add a device or create a child profile to get started.')}</Body>
      <Button label={t('Get Started')} to={gettingStarted()} />
    </Container>
  );
};

export default GetStartedCard;
