import { useState } from 'react';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';
import { Body } from '../../components/Typography';
import Icon from '../../components/Icon/Icon';
import theme from '../../design/theme';
import MinutesToHoursAndMinutes from '../../components/Time';
import { DeviceConnectionStatus, DeviceDetails } from '../../../shared/types';
import Modal from '../../components/Modal/Modal';
import ModalBaseWifi from '../../components/Modal/ModalBaseWifi';
import ModalBattery from '../../components/Modal/ModalBattery';
import { baseStationColor, determineBatteryIcon, wifiColor } from '../../../backend/utils/helpers';

const GridContainer = styled.div`
  display: flex;
  flex-direction: column;
  padding: 16px;
`;

const Row = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 8px;
`;

const LeftColumn = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
`;

const StyledBody = styled(Body)`
  margin: 0 8px;
`;

const DeviceCardData = ({
  connectionStatus, btt, bat, bso, chg,
}: DeviceDetails) => {
  const [modalWifiActive, setModalWifiActive] = useState<boolean>(false);
  const [modalBatteryActive, setModalBatteryActive] = useState<boolean>(false);
  const { t } = useTranslation();
  const batteryStatus = determineBatteryIcon(bat, chg);
  const baseStationIconColor = baseStationColor(bso);

  let chargeStatus;
  if (chg === 2) {
    chargeStatus = t('Charged');
  } else if (chg === 1) {
    chargeStatus = `${t('Charging')}(${bat}%)`;
  } else {
    chargeStatus = '---';
  }

  let baseStationStaus;
  if (bso === 1) {
    baseStationStaus = t('On');
  } else if (bso === 0) {
    baseStationStaus = t('Off');
  } else {
    baseStationStaus = '---';
  }

  return (
    <GridContainer>
      <Row>
        <LeftColumn>
          <Icon
            icon="StatusBaseStation3"
            fill={baseStationIconColor}
            width={24}
            height={24}
          />
          <StyledBody>{t('Base Station:')}</StyledBody>
        </LeftColumn>
        <Body>{baseStationStaus}</Body>
      </Row>
      <Row>
        <LeftColumn>
          <Icon
            icon={connectionStatus === DeviceConnectionStatus.Connected ? 'StatusWifi' : 'StatusWifiOff'}
            fill={wifiColor(connectionStatus)}
            width={24}
            height={24}
          />
          <StyledBody>{t('Base Station Wi-Fi')}</StyledBody>
          <Icon
            icon="Help"
            width={20}
            height={20}
            fill={theme.color.text.darkMode.primary}
            onClick={() => setModalWifiActive(!modalWifiActive)}
          />
        </LeftColumn>
        <Body>{connectionStatus ? t(connectionStatus) : '---'}</Body>
      </Row>
      <Row>
        <LeftColumn>
          <Icon
            icon={batteryStatus}
            fill={theme.color.primary.default}
            width={24}
            height={24}
          />
          <StyledBody>{t('Sock Battery')}</StyledBody>
          <Icon
            icon="Help"
            width={20}
            height={20}
            fill={theme.color.text.darkMode.primary}
            onClick={() => setModalBatteryActive(!modalBatteryActive)}
          />
        </LeftColumn>
        <Body>
          {
        btt ? <MinutesToHoursAndMinutes totalMinutes={btt} /> : chargeStatus
        }
        </Body>
      </Row>
      <Modal
        isActive={modalWifiActive}
        onClose={() => setModalWifiActive(!modalWifiActive)}
      >
        <ModalBaseWifi />
      </Modal>
      <Modal
        isActive={modalBatteryActive}
        onClose={() => setModalBatteryActive(!modalBatteryActive)}
      >
        <ModalBattery />
      </Modal>
    </GridContainer>
  );
};

export default DeviceCardData;
