import { ToggleButton, ToggleButtonGroup } from '@mui/material';
import { AnimatePresence, motion } from 'framer-motion';
import styled from 'styled-components';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import theme from '../../design/theme';
import { Icon } from '../Icon';
import { Body, Heading } from '../Typography';
import Button from '../Button/Button';
import { submitNpsSurvery } from '../../../backend/utils/owletApi';
import { useAuthContext } from '../../../backend/contexts/authContext';
import { appVersion, osName } from '../../../backend/utils/helpers';

interface ModalProps {
  isActive: boolean;
  onClose?: () => void;
  onSubmit?: () => void;
}

const Container = styled(motion.div).attrs({
  'aria-hidden': 'true',
})`
  display: flex;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 1000;
  flex-flow: column nowrap;
  justify-content: flex-end;
  align-items: center;
  height: auto;
  margin-top: 60px;
  background-color: transparent;
  overflow: scroll;

  ::-webkit-scrollbar {
    display: none;
  }
`;

const Layout = styled(motion.section)`
  display: flex;
  position: relative;
  z-index: 101;
  flex-flow: column nowrap;
  justify-content: flex-start;
  align-items: flex-start;
  min-height: 200px;
  max-height: 96%;
  overflow: scroll;
  border-radius: 20px 20px 0 0;
  padding: 20px 20px 60px;
  background-color: ${theme.color.background.darkMode.dark};
`;

const CloseContainer = styled.div`
  align-self: flex-end;
  padding: 10px 0 20px 20px;
  cursor: pointer;
`;

const Content = styled.div`
  padding-top: 10px;
`;

const StyledBody = styled(Body)`
  font-size: 20px;
  text-align: center;
  padding-bottom: 20px;
`;

const SelectContainer = styled.div`
  margin: 40px 0;
`;

const SelectHeadingContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-bottom: 10px;
`;

const StyledToggleButton = styled(ToggleButton)`

 &.Mui-selected, &.Mui-selected:hover {
    background-color: ${theme.color.background.darkMode.darker} !important;
  }
`;

const StyledTextArea = styled.textarea`
  width: 100%;
  height: 72px;
  background: ${theme.color.background.darkMode.light};
  border: none;
  color: ${theme.color.text.darkMode.primary};
  margin-top: 20px;
  border-radius: 8px;
  font-family: ${theme.font.family.SourceSansPro};
  font-size: 16px;

  &:focus {
    outline:${theme.color.background.darkMode.darker};
  }
`;

const ModalOverlay = styled(motion.div).attrs({
  'aria-hidden': 'true',
})`
  display: flex;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 100;
  flex-flow: column nowrap;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  background-color: rgba(17, 19, 24, 0.8);
`;

const StyledError = styled.p`
  color: red;
  font-size: .75rem;
`;

const SurveyExpanded = ({ isActive, onClose, onSubmit } : ModalProps) => {
  const [rating, setRating] = useState<number | null>(null);
  const [notes, setNotes] = useState<string | undefined>(undefined);
  const [errorMessage, setErrorMessage] = useState<string | null>(null);
  const [isDisabled, setIsDisabled] = useState<boolean>(false);
  const { firebaseToken, currentUser } = useAuthContext();
  const { t } = useTranslation();

  const deviceType = () => {
    const os = osName();

    const options: { [name: string]: string } = {
      iOS: 'CLIENT_DEVICE_TYPE_IOS',
      Android: 'CLIENT_DEVICE_TYPE_ANDROID',
      default: 'CLIENT_DEVICE_TYPE_UNSPECIFIED',
    };

    if (!os) return options.default;

    return options[os] || options.default;
  };

  const postSurvey = async () => {
    if (!rating) {
      setIsDisabled(true);
      setErrorMessage(t('Please select a rating.'));
      return null;
    }
    if (firebaseToken && currentUser) {
      const postData = {
        token: firebaseToken,
        accountId: currentUser?.uid,
        requestId: new Date().getTime().toString(),
        npsResponse: {
          appVersion: `${appVersion()}`,
          clientDeviceType: deviceType(),
          notes,
          rating,
          submissionTime: new Date().toISOString(),
        },
      };

      await submitNpsSurvery(postData).then((e) => {
        onSubmit?.();
      }).catch((error) => {
        console.error(error);
      });
    }
    return null;
  };

  const handleRating = (
    event: React.MouseEvent<HTMLElement>,
    newRating: number | null,
  ) => {
    if (newRating) {
      setIsDisabled(false);
      setErrorMessage(null);
    } else {
      setIsDisabled(true);
    }
    setRating(newRating);
  };

  const handleSubmit = () => {
    setIsDisabled(true);
    postSurvey();
  };

  return (
    <AnimatePresence>
      {isActive && (
      <Container
        exit={{ opacity: 0 }}
        transition={{ ease: 'easeInOut', duration: 0.5 }}
      >
        <AnimatePresence>
          <Layout
            initial={{ y: 800 }}
            animate={{ y: 0 }}
            exit={{ y: 800 }}
            transition={{ ease: 'easeInOut', duration: 0.5 }}
          >
            <CloseContainer>
              <Icon icon="Close" height={24} width={24} fill={theme.color.text.darkMode.primary} onClick={onClose} />
            </CloseContainer>
            <Content>
              <StyledBody>
                {t('How likely are you to recommend Owlet to a friend or colleague?')}
              </StyledBody>
              <SelectContainer>
                <SelectHeadingContainer>
                  <Heading>{t('Unlikely')}</Heading>
                  <Heading>{t('Likely')}</Heading>
                </SelectHeadingContainer>
                <ToggleButtonGroup
                  exclusive
                  fullWidth
                  aria-label="survey"
                  value={rating}
                  onChange={handleRating}
                >
                  <StyledToggleButton value={1} aria-label="1">
                    <Body>1</Body>
                  </StyledToggleButton>
                  <StyledToggleButton value={2} aria-label="2">
                    <Body>2</Body>
                  </StyledToggleButton>
                  <StyledToggleButton value={3} aria-label="3">
                    <Body>3</Body>
                  </StyledToggleButton>
                  <StyledToggleButton value={4} aria-label="4">
                    <Body>4</Body>
                  </StyledToggleButton>
                  <StyledToggleButton value={5} aria-label="5">
                    <Body>5</Body>
                  </StyledToggleButton>
                  <StyledToggleButton value={6} aria-label="6">
                    <Body>6</Body>
                  </StyledToggleButton>
                  <StyledToggleButton value={7} aria-label="7">
                    <Body>7</Body>
                  </StyledToggleButton>
                  <StyledToggleButton value={8} aria-label="8">
                    <Body>8</Body>
                  </StyledToggleButton>
                  <StyledToggleButton value={9} aria-label="9">
                    <Body>9</Body>
                  </StyledToggleButton>
                  <StyledToggleButton value={10} aria-label="10">
                    <Body>10</Body>
                  </StyledToggleButton>
                </ToggleButtonGroup>
                {errorMessage && <StyledError>{errorMessage}</StyledError>}
              </SelectContainer>
              <Heading>{t('How can we do better?')}</Heading>
              <StyledTextArea
                value={notes}
                onChange={(e) => setNotes(e.target.value)}
              />
              <Button disabled={isDisabled} label={t('Submit')} onClick={() => handleSubmit()} />
            </Content>
          </Layout>
        </AnimatePresence>
        <AnimatePresence>
          <ModalOverlay
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            exit={{ opacity: 0 }}
            transition={{ ease: 'easeInOut', duration: 0.5 }}
            onClick={onClose}
          />
        </AnimatePresence>
      </Container>
      )}
    </AnimatePresence>

  );
};

export default SurveyExpanded;
