import { getDatabase, update, ref } from 'firebase/database';

// NOTE: undefined is required for ref to fallback properly
// initialize your comp with undefined if it depends on another
// observer!!

type Data = Record<string, Date | boolean | string | string[] | number | null | undefined>;

const UseFirebasePostDataHook = (node: string | undefined,
  newNodeKey: string | null, data: Data | null) => {
  const db = getDatabase();
  const updates: { [key: string]: Data | null } = {};
  updates[`${node}/${newNodeKey}`] = data;
  return update(ref(db), updates);
};

export default UseFirebasePostDataHook;
