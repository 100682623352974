import {
  useCallback,
  useEffect, useMemo, useState,
} from 'react';
import _ from 'lodash';
import { useNavigate, useParams } from 'react-router-dom';
import styled from 'styled-components';
import {
  addDays,
  endOfDay,
  getUnixTime,
  isSameDay,
  startOfDay,
  subDays,
} from 'date-fns';
import { useServiceUserContext } from '../../../backend/contexts/serviceUserContext';
import {
  HistoryTab,
  ServiceUser,
} from '../../../shared/types';
import UserSelect from '../../components/History/UserSelect';
import { serviceUserHistory } from '../../navigation/routes';
import DatePickerHeader from '../../components/History/DatePickerHeader';
import { useAuthContext } from '../../../backend/contexts/authContext';
import Overview from '../../components/History/Overview';
import { useHistoryContext } from '../../../backend/contexts/history';
import SubNav from '../../components/History/SubNav';
import Graphs from '../../components/History/Graphs';
import { HISTORY_REQUEST_DEBOUNCE_MILLIS } from '../../../shared/constants';

const Layout = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  overflow: scroll;
`;

const History = () => {
  const { serviceUserKey = '' } = useParams();
  const navigate = useNavigate();
  const { findServiceUser, serviceUsersList } = useServiceUserContext();
  const { firebaseToken, currentUser } = useAuthContext();
  const serviceUser = useMemo(
    () => findServiceUser(serviceUserKey),
    [findServiceUser, serviceUserKey],
  );
  const [historyTab, setHistoryTab] = useState(HistoryTab.OVERVIEW);
  const [loading, setLoading] = useState(true);
  const { historyDate, updateHistory } = useHistoryContext();
  const [overviewKey, setOverviewKey] = useState('');
  const [vitalsKey, setVitalsKey] = useState('');

  const getTimeframe = (selectedDate: Date) => {
    const currentDate = endOfDay(new Date());
    const startTime = getUnixTime(subDays(startOfDay(selectedDate), 1));
    const endOfNextDay = endOfDay(addDays(selectedDate, 1));
    const endTime = isSameDay(currentDate, selectedDate)
      ? getUnixTime(currentDate)
      : getUnixTime(endOfNextDay);

    return {
      startTime,
      endTime,
    };
  };

  const overviewParams = useCallback((version: string) => {
    const sleepParams = {
      token: `${firebaseToken}`,
      accountId: `${currentUser?.uid}`,
      serviceUserId: serviceUserKey,
      timeZone: Intl.DateTimeFormat().resolvedOptions().timeZone,
      version,
      ...getTimeframe(historyDate as Date),
    };
    const vitalsParams = {
      token: `${firebaseToken}`,
      accountId: `${currentUser?.uid}`,
      profileId: `${serviceUser?.id}`,
      version,
      resolution: 600,
      startTime: getUnixTime(startOfDay(historyDate as Date)),
      endTime: getUnixTime(endOfDay(historyDate as Date)),
    };

    return { sleepParams, vitalsParams };
  }, [historyDate, serviceUser, firebaseToken, currentUser, serviceUserKey]);

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const getOverviewData = useCallback(
    _.debounce(async () => {
      const version = serviceUser?.service?.type;
      if (firebaseToken && historyDate && version) {
        const { vitalsParams, sleepParams } = overviewParams(version);
        const vitalsRequestKey = ['vitals', ...Object.values(_.omit(vitalsParams, ['token']))].join('-');
        const sleepKey = ['sleep', ...Object.values(_.omit(sleepParams, ['token']))].join('-');

        setOverviewKey(sleepKey);
        setVitalsKey(vitalsRequestKey);
        updateHistory({
          sleep: {
            key: sleepKey,
            params: sleepParams,
          },
          vitals: {
            key: vitalsRequestKey,
            params: vitalsParams,
          },
        }, setLoading);
      } else {
        setLoading(false);
        setOverviewKey('');
        setVitalsKey('');
      }
    }, HISTORY_REQUEST_DEBOUNCE_MILLIS, { leading: false }),
    [firebaseToken, currentUser, serviceUserKey, historyDate, serviceUser, updateHistory],
  );

  useEffect(() => {
    if (historyTab === HistoryTab.OVERVIEW) {
      getOverviewData();
    }

    return getOverviewData.cancel;
  }, [historyDate, serviceUserKey, getOverviewData, historyTab]);

  const handleChildSelect = (user: ServiceUser) => {
    navigate(serviceUserHistory(user.id));
  };

  const reloadOverview = useCallback((): Promise<void> => {
    const version = serviceUser?.service?.type;
    if (version) {
      const { vitalsParams, sleepParams } = overviewParams(version);
      updateHistory({
        sleep: {
          key: overviewKey,
          params: sleepParams,
          reload: true,
        },
        vitals: {
          key: vitalsKey,
          params: vitalsParams,
        },
      }, setLoading);
    }
    return new Promise((resolve) => resolve(undefined));
  }, [serviceUser, updateHistory, overviewKey, vitalsKey, overviewParams]);

  return (
    <Layout>
      <DatePickerHeader />
      <div>
        <UserSelect
          usersList={serviceUsersList}
          activeUser={serviceUser}
          handleChildSelect={handleChildSelect}
        />
      </div>
      <SubNav activeTab={historyTab} setHistoryTab={setHistoryTab} />
      { historyTab === HistoryTab.OVERVIEW && (
        <Overview
          historyDate={historyDate}
          loading={loading}
          overviewKey={overviewKey}
          vitalsKey={vitalsKey}
          reloadOverview={reloadOverview}
        />
      )}
      { historyTab === HistoryTab.GRAPH && (
        <Graphs
          serviceUser={serviceUser}
        />
      )}
    </Layout>
  );
};

export default History;
