/* eslint-disable no-console */
import {
  setAppActive,
  getDevices,
  getProperties,
  getRegisterableDevices,
  registerDevice,
  signIn,
  aylaSignOut,
} from '../utils/aylaApi';
import { getMiniToken } from '../utils/owletApi';
import { RealTimeVitals, RegisterDeviceBody } from '../../shared/types';

const V3_SOCK_PREFACE = 'SS3';

const getAylaToken = async (firebaseToken: string) => {
  try {
    const { data: { mini_token: miniToken } } = await getMiniToken(firebaseToken);
    const { data: { access_token: accessToken, expires_in: expiresIn } } = await signIn(miniToken);

    return { accessToken, expiresIn };
  } catch (e) {
    console.log(e);
    return undefined;
  }
};

const getV3Devices = (aylaToken: string) => (
  getDevices(aylaToken).then(
    ({ data: devices }) => devices.filter(
      ({ device }) => device.oem_model.startsWith(V3_SOCK_PREFACE),
    ),
  )
);

const getDSNs = (aylaToken: string) => (
  getV3Devices(aylaToken).then((devices) => devices.map(({ device }) => device.dsn))
);

const handleVitalsProperty = (
  token: string,
  DSN: string,
  callback: (dsn: string, vitals: RealTimeVitals, lastUpdatedAtUTC?: string) => void,
) => (
  getProperties(token, DSN)
    .then((res) => {
      const { property: deviceProp } = res.data.find(({ property }) => property.name === 'REAL_TIME_VITALS') || {};
      const vitals: RealTimeVitals = JSON.parse(deviceProp?.value || '{}');
      const lastUpdatedAtUTC = deviceProp?.data_updated_at;
      callback(DSN, vitals, lastUpdatedAtUTC);
    })
    .catch((error) => {
      console.error(error);
    })
);

const handleSetAppActive = (
  token: string,
  DSN: string,
  callback: (ts: string, dsn: string) => void,
) => (
  setAppActive(token, DSN)
    .then((res) => {
      callback(res.data.datapoint.updated_at, DSN);
    })
);

const connectDevice = (
  DSN: string,
  aylaToken: string,
  callbackPost: (ts: string, dsn: string) => void,
  callbackGet: (dsn: string, vitals: RealTimeVitals, lastUpdatedAtUTC?: string) => void,
) => {
  handleSetAppActive(aylaToken, DSN, callbackPost);
  const activateIntervalId = setInterval(() => {
    handleSetAppActive(aylaToken, DSN, callbackPost);
  }, 25000);

  handleVitalsProperty(aylaToken, DSN, callbackGet);
  const telemetryIntervalId = setInterval(() => {
    handleVitalsProperty(aylaToken, DSN, callbackGet);
  }, 3000);

  return [activateIntervalId, telemetryIntervalId];
};

const findRegisterableDevice = (token: string, ipAddress: string) => getRegisterableDevices(
  token,
  ipAddress,
).then((data) => data?.data?.device);

const register = (token: string, data: RegisterDeviceBody) => registerDevice(token, data);

const handleSignOut = (
  token: string,
  clearAylaData: () => void,
) => {
  aylaSignOut(token)
    .then(() => {
      clearAylaData();
    })
    .catch((error) => {
      console.error(error);
    });
};

export {
  connectDevice,
  getAylaToken,
  getDSNs,
  findRegisterableDevice,
  register,
  handleSignOut,
};
