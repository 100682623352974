import { useLocation } from 'react-router-dom';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';
import theme from '../../design/theme';
import Icon from '../Icon/Icon';
import { SmallLabel } from '../Typography';
import {
  accountCollection,
  accountInfo,
  home,
} from '../../navigation/routes';
import HistoryIcon from './HistoryIcon';
import NavItem from './NavItem';
import useFeatureFlag from '../../../backend/hooks/useFeatureFlag';
import { FeatureFlags } from '../../../shared/constants';

const NavbarContainer = styled.div`
  display: flex;
  justify-content: space-around;
  align-items: center;
  background-color: ${theme.color.background.darkMode.dark};
  height: 100%;
  z-index: 100;
`;

const Navbar = () => {
  const showHistory = useFeatureFlag(FeatureFlags.HISTORY);
  const { pathname: activeRoute } = useLocation();
  const { t } = useTranslation();

  const isAccountInfo = () => (
    activeRoute.startsWith(accountCollection()) && activeRoute.endsWith('/info')
  );

  const isHomeActive = () => isAccountInfo() || activeRoute.startsWith(home());

  const isAccountActive = () => {
    if (isAccountInfo()) return false;

    return activeRoute.startsWith(accountCollection());
  };

  return (
    <NavbarContainer>
      <NavItem to={home()}>
        <Icon
          icon="NavHome"
          width={24}
          height={24}
          style={isHomeActive() ? {
            fill: 'rgb(215, 255, 211)',
          } : {
            fill: 'rgb(225, 207, 236)',
          }}
        />
        <SmallLabel style={isHomeActive() ? {
          color: 'rgb(215, 255, 211)',
        } : {
          color: 'rgb(225, 207, 236)',
        }}
        >
          {t('Home')}
        </SmallLabel>
      </NavItem>
      {showHistory ? <HistoryIcon /> : null}
      <NavItem to={accountInfo()}>
        <Icon
          icon="NavAccount"
          width={24}
          height={24}
          style={isAccountActive() ? {
            fill: 'rgb(215, 255, 211)',
          } : {
            fill: 'rgb(225, 207, 236)',
          }}
        />
        <SmallLabel style={isAccountActive() ? {
          color: 'rgb(215, 255, 211)',
        } : {
          color: 'rgb(225, 207, 236)',
        }}
        >
          {t('Account')}
        </SmallLabel>
      </NavItem>
    </NavbarContainer>
  );
};

export default Navbar;
