import styled from 'styled-components';
import { useTranslation } from 'react-i18next';
import { Body, PageHeading } from '../Typography';
import { Icon } from '../Icon';
import theme from '../../design/theme';

const ListItem = styled.div`
  display: flex;
  align-items: center;
  padding: 10px 0;
  margin-top: 10px;
`;

const StyledPageHeading = styled(PageHeading)`
  color: ${theme.color.primary.default};
`;

const StyledBody = styled(Body)`
  margin-left: 10px;
  font-weight: 600;
`;

const ModalBaseWifi = () => {
  const { t } = useTranslation();

  return (
    <div>
      <StyledPageHeading>{t('Base Station WiFi Connectivity')}</StyledPageHeading>
      <Body>{t('While the Smart Sock connects to the Base Station via bluetooth low energy, Wi-Fi is required to view live readings and receive device updates.')}</Body>
      <br />
      <Body>{t('No Wi-Fi? No Problem! Owlet\'s Base Station works with or without Wi-Fi. While Wi-Fi is required to view live reading via the app, the Base Station designed to notify even if your Wi-Fi goes down or your phone dies.')}</Body>
      <br />
      <Body>{t('Below is a list of all base station Wi-Fi statuses.')}</Body>
      <ListItem>
        <Icon icon="StatusWifi" width={24} height={24} fill={theme.color.primary.default} />
        <StyledBody>{t('Base station Wi-Fi: Connected')}</StyledBody>
      </ListItem>
      <ListItem>
        <Icon icon="StatusWifiOff" width={24} height={24} fill={theme.color.text.darkMode.primary} />
        <StyledBody>{t('Base station Wi-Fi: Disconnected')}</StyledBody>
      </ListItem>
    </div>
  );
};

export default ModalBaseWifi;
