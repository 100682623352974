import styled from 'styled-components';
import ListItemText from '@mui/material/ListItemText';
import theme from '../../design/theme';
import { Caption, ButtonText } from '../../components/Typography';

export const DeviceContainer = styled.div`
  overflow-y: scroll;
  `;

export const PageContents = styled.div`
  padding: 20px;
`;

export const ImageContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100px;
`;

export const ItemStyle = styled(ListItemText)`
  color: ${theme.color.text.darkMode.primary};
  margin-left: 5vw;
`;

export const RemoveContainer = styled.div`
  color: rgb(242, 162, 122);
  padding: 16px 0;
  display: flex;
  width: 160px;
  align-items: center;
  justify-content: space-between;
  text-decoration: none;
`;

export const DeleteText = styled(ButtonText)`
  color: rgb(242, 162, 122);
`;

export const Underline100 = styled.div`
  border-bottom: .5px rgb(74, 76, 93) solid;
  width: 100%;
  margin-top: 3vh;
`;

export const ExternalListItem = styled.a`
  padding: 8px 0;
  display: flex;
  width: 140px;
  align-items: center;
  justify-content: space-between;
  text-decoration: none;
  color: ${theme.color.primary.default};
`;

export const StyledCaption = styled(Caption)`
  padding: 20px 0;
`;
