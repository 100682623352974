import { AnimatePresence, motion } from 'framer-motion';
import styled from 'styled-components';
import theme from '../../design/theme';
import { Icon } from '../Icon';

interface ModalProps {
  isActive: boolean;
  onClose?: () => void;
  children: React.ReactNode;
}

const Container = styled(motion.div).attrs({
  'aria-hidden': 'true',
})`
  display: flex;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 1000;
  flex-flow: column nowrap;
  justify-content: flex-end;
  align-items: center;
  height: auto;
  margin-top: 60px;
  background-color: transparent;
  overflow: scroll;

  ::-webkit-scrollbar {
    display: none;
  }
`;

const Layout = styled(motion.section)`
  display: flex;
  position: relative;
  z-index: 101;
  flex-flow: column nowrap;
  justify-content: flex-start;
  align-items: flex-start;
  min-height: 200px;
  max-height: 96%;
  overflow: scroll;
  border-radius: 20px 20px 0 0;
  padding: 20px 20px 60px;
  background-color: ${theme.color.background.darkMode.dark};
`;

const CloseContainer = styled.div`
  align-self: flex-end;
  padding: 10px 0 20px 20px;
  cursor: pointer;
`;

const ModalOverlay = styled(motion.div).attrs({
  'aria-hidden': 'true',
})`
  display: flex;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 100;
  flex-flow: column nowrap;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  background-color: rgba(17, 19, 24, 0.8);
`;

const Modal = ({ isActive, onClose, children } : ModalProps) => (
  <AnimatePresence>
    {isActive && (
    <Container
      exit={{ opacity: 0 }}
      transition={{ ease: 'easeInOut', duration: 0.5 }}
    >
      <AnimatePresence>
        <Layout
          initial={{ y: 800 }}
          animate={{ y: 0 }}
          exit={{ y: 800 }}
          transition={{ ease: 'easeInOut', duration: 0.5 }}
        >
          <CloseContainer>
            <Icon icon="Close" height={24} width={24} fill={theme.color.text.darkMode.primary} onClick={onClose} />
          </CloseContainer>
          {children}
        </Layout>
      </AnimatePresence>
      <AnimatePresence>
        <ModalOverlay
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          exit={{ opacity: 0 }}
          transition={{ ease: 'easeInOut', duration: 0.5 }}
          onClick={onClose}
        />
      </AnimatePresence>
    </Container>
    )}
  </AnimatePresence>
);

export default Modal;
