import { createContext } from 'react';
import { AccountProvider } from './accountContext';
import { DeviceProvider } from './deviceContext';
import { HistoryProvider } from './history';
import { ServiceProvider } from './serviceContext';
import { ServiceUserProvider } from './serviceUserContext';

type StoreProviderProps = { children: React.ReactNode }

const Context = createContext(undefined);

const StoreProvider = ({ children }: StoreProviderProps) => (
  <Context.Provider value={undefined}>
    <AccountProvider>
      <ServiceProvider>
        <ServiceUserProvider>
          <DeviceProvider>
            <HistoryProvider>
              {children}
            </HistoryProvider>
          </DeviceProvider>
        </ServiceUserProvider>
      </ServiceProvider>
    </AccountProvider>
  </Context.Provider>
);

export default StoreProvider;
