import { useMemo } from 'react';
import {
  Navigate,
  Route,
  Routes,
  useLocation,
} from 'react-router-dom';
import { HelmetProvider } from 'react-helmet-async';
import { useTranslation } from 'react-i18next';
import Navbar from '../components/Nav/Navbar';
import Account from '../screens/Account';
import CreateChildProfile from '../screens/CreateProfile';
import DeviceDetail from '../screens/Device/DeviceDetail';
import DeviceSetup from '../screens/DeviceSetup';
import GettingStarted from '../screens/Home/GettingStarted';
import Home from '../screens/Home/Home';
import ChildProfile from '../screens/Profile/ChildProfileWrapper';
import UpdateChildProfile from '../screens/UpdateChildProfile/views/UpdateChildProfile';
import Welcome from '../screens/Welcome/WelcomeWrapper';
import { useAuthContext, AuthStatus } from '../../backend/contexts/authContext';
import RouteWithTitle from './RouteWithTitle';
import PrivateRoute from './PrivateRoute';
import ConfirmationEmail from '../screens/SignIn/ConfirmationEmail';
import PageDoesNotExist from '../screens/404/PageDoesNotExist';
import History, { HistoryRedirect } from '../screens/History';
import {
  accountCollection,
  deviceCollection,
  deviceResource,
  gettingStarted,
  guide,
  history,
  home,
  root,
  childProfile,
  updateServiceUser,
  welcome,
  serviceUserHistory,
  serviceUserInfo,
  logIn,
  signUp,
  details,
  confirmationEmail,
} from './routes';
import SignInRoutes from '../screens/SignIn/routes';
import Survey from '../components/Survey/Survey';

const authenticatedStyle = {
  gridColumn: '1',
  gridRow: '1',
};

const AppRoutes = () => {
  const { authStatus } = useAuthContext();
  const location = useLocation();
  const { t } = useTranslation();

  const nonNavRoutes = useMemo(() => [
    deviceCollection(),
    gettingStarted(),
    childProfile(),
    confirmationEmail(),
    details(),
    logIn(),
    signUp(),
  ], []);

  const isNonNavRoute = (
    activeRoute: string,
  ) => nonNavRoutes.some((route) => activeRoute.startsWith(route));

  const displayNavbar = () => (
    authStatus === AuthStatus.Authenticated && !isNonNavRoute(location.pathname)
  );

  return (
    <HelmetProvider>
      <Routes>
        <Route
          path={root()}
          element={<Navigate replace to={home()} />}
        />
        <Route
          path={welcome()}
          element={<Welcome authStatus={authStatus} />}
        />
        <Route
          path={home()}
          element={<PrivateRoute><RouteWithTitle title={t('Home')}><Home /></RouteWithTitle></PrivateRoute>}
        />
        <Route
          path={gettingStarted()}
          element={<PrivateRoute><RouteWithTitle title={t('Getting Started')}><GettingStarted /></RouteWithTitle></PrivateRoute>}
        />
        <Route
          path={guide()}
          element={(
            <PrivateRoute>
              <RouteWithTitle title={t('Guide')}>
                <h2 style={authenticatedStyle}>{t('Guide')}</h2>
              </RouteWithTitle>
            </PrivateRoute>
          )}
        />
        <Route
          path={history()}
          element={(
            <PrivateRoute>
              <RouteWithTitle title={t('History')}>
                <HistoryRedirect />
              </RouteWithTitle>
            </PrivateRoute>
          )}
        />
        <Route
          path={serviceUserHistory()}
          element={(
            <PrivateRoute>
              <RouteWithTitle title={t('History')}>
                <History />
              </RouteWithTitle>
            </PrivateRoute>
          )}
        />
        <Route
          path={`${accountCollection()}/*`}
          element={(
            <PrivateRoute>
              <RouteWithTitle title={t('Account')}>
                <Account />
              </RouteWithTitle>
            </PrivateRoute>
          )}
        />
        <Route
          path={`${childProfile()}/*`}
          element={(
            <PrivateRoute>
              <RouteWithTitle title={t('Child Profile')}>
                <CreateChildProfile />
              </RouteWithTitle>
            </PrivateRoute>
          )}
        />
        <Route
          path={`${updateServiceUser()}/*`}
          element={(
            <PrivateRoute>
              <RouteWithTitle title={t('Profile')}>
                <UpdateChildProfile />
              </RouteWithTitle>
            </PrivateRoute>
          )}
        />
        <Route
          path={deviceResource()}
          element={(
            <PrivateRoute>
              <RouteWithTitle title={t('Device Info')}>
                <DeviceDetail />
              </RouteWithTitle>
            </PrivateRoute>
          )}
        />
        <Route
          path={serviceUserInfo()}
          element={
            (
              <PrivateRoute>
                <RouteWithTitle title={t('Child Details')}>
                  <ChildProfile />
                </RouteWithTitle>
              </PrivateRoute>
            )
          }
        />
        <Route
          path={`${deviceCollection()}/*`}
          element={(
            <PrivateRoute>
              <RouteWithTitle title={t('Device Setup')}>
                <DeviceSetup />
              </RouteWithTitle>
            </PrivateRoute>
          )}
        />
        <Route
          path={confirmationEmail()}
          element={
            (
              <PrivateRoute>
                <RouteWithTitle title={t('Confirmation Email')}>
                  <ConfirmationEmail />
                </RouteWithTitle>
              </PrivateRoute>
            )
          }
        />
        {[...SignInRoutes]}
        <Route path="*" element={(<PageDoesNotExist />)} />
      </Routes>
      {displayNavbar() && (
        <Navbar />
      )}
      <Survey />
    </HelmetProvider>
  );
};

export default AppRoutes;
