import {
  useCallback,
  useEffect,
  useMemo,
  useState,
  memo,
} from 'react';
import _ from 'lodash';
import styled, { css } from 'styled-components';
import {
  getUnixTime,
  addHours,
  startOfDay,
  subHours,
  format,
  addDays,
  subDays,
  isSameDay,
  endOfDay,
} from 'date-fns';
import {
  VictoryArea, VictoryChart, VictoryLine,
  VictoryAxis, VictoryLabel,
  VictoryZoomContainer,
} from 'victory';
import { useTranslation } from 'react-i18next';
import {
  ServiceUser,
  SleepDataResponse,
  VitalsData,
  VitalsDataResponse,
} from '../../../shared/types';
import { useHistoryContext } from '../../../backend/contexts/history';
import { useAuthContext } from '../../../backend/contexts/authContext';
import Icon from '../Icon/Icon';
import theme from '../../design/theme';
import { PageTitle, Body, Heading } from '../Typography';
import Loader from '../../navigation/Loader';
import Modal from '../Modal/Modal';
import ModalChartLine from '../Modal/ModalChartLine';
import ModalChartBar from '../Modal/ModalChartBar';
import { HISTORY_REQUEST_DEBOUNCE_MILLIS } from '../../../shared/constants';

const Container = styled.div`
  overflow: scroll;
  display: flex;
  flex-direction: column;
  background: ${theme.color.background.darkMode.dark};
  margin: 1px 0;
`;

const Divider = styled.div`
  width: 100%;
  min-height: 16px;
  background: ${theme.color.background.darkMode.darker};
`;

const LabelContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px 20px;
  border-bottom: 1px solid #494D5D;
`;

const DateControls = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 12px;
`;

const DateHeading = styled(PageTitle)`
  padding: 0 10px;
`;

const DisableSelect = css`
  user-select: none; /* supported by Chrome and Opera */
  -webkit-user-select: none; /* Safari */
  -khtml-user-select: none; /* Konqueror HTML */
  -moz-user-select: none; /* Firefox */
  -ms-user-select: none; /* Internet Explorer/Edge */
`;

const LabelHeading = styled(Heading)`
  ${DisableSelect};
`;

const LabelBody = styled(Body)`
  ${DisableSelect};
`;

type GraphsProps = {
  serviceUser: ServiceUser | undefined;
}

type pointPlot = {
  x: Date,
  y: number
}

const Graphs = ({ serviceUser }: GraphsProps) => {
  const currentDate = new Date();
  const [graphsData, setGraphsData] = useState<VitalsDataResponse | Record<string, string>>({});
  const [overviewData, setOverviewData] = useState<SleepDataResponse | Record<string, string>>({});
  const [loading, setLoading] = useState(true);
  const [modalLineChartActive, setModalLineChartActive] = useState<boolean>(false);
  const [modalBarChartActive, setModalBarChartActive] = useState<boolean>(false);
  const { firebaseToken, currentUser } = useAuthContext();
  const {
    historyDate, updateHistory, history, setHistoryDate,
  } = useHistoryContext();
  const { t } = useTranslation();
  const [startDay, setStartDay] = useState<Date>();
  const [endDay, setEndDay] = useState<Date>();
  const graphDate: Date = historyDate || currentDate;
  const [previousGraphDate, setPreviousGraphDate] = useState<Date | null>();
  const [zoomDomain, setZoomDomain] = useState<any>({
    x: [
      subHours(startOfDay(graphDate), 6),
      addHours(startOfDay(graphDate), 6),
    ],
  });

  const middleHour = useCallback(
    (date1: Date, date2: Date) => new Date((date1.getTime() + date2.getTime()) / 2), [],
  );

  const findDataSpaces = useCallback((vitals: boolean) => {
    const vitalsRecords = graphsData.data as VitalsData;
    const timeArray = vitalsRecords?.timeWindowStartTimes || [];

    const { data } = (overviewData as SleepDataResponse);
    const sleepTimeArray = data?.timeWindowStartTimes || [];

    if (vitals) {
      return findSpaces(timeArray, vitals);
    }
    return findSpaces(sleepTimeArray, vitals);
  }, [graphsData, overviewData]);

  const findSpaces = (timeArray: number[], vitals: boolean) => timeArray.map((timestamp, index) => {
    if (index === 0) {
      return false;
    }
    const gap = vitals ? 1220 : 122;
    if (timestamp - timeArray[index - 1] > gap) {
      return true;
    }
    return false;
  });

  const parseSleep = useMemo(
    () => {
      const { data } = (overviewData as SleepDataResponse);
      const sleepState = data?.sleepStates || [];
      const lightSleep : pointPlot[][] = [];
      const deepSleep : pointPlot[][] = [];
      const awake : pointPlot[][] = [];
      const intervals = findDataSpaces(false);

      let initialIndex = 0;
      let finalIndex = 0;
      sleepState.forEach((sleep, index) => {
        if (index === 0) {
          initialIndex = index;
        } else if (sleepState[index - 1] !== sleep
            || intervals[index] || index === sleepState.length - 1) {
          finalIndex = index - 1;
          const currentInterval : pointPlot[] = [
            {
              x: new Date(data.timeWindowStartTimes[initialIndex] * 1000),
              y: 15,
            },
            {
              x: new Date(data.timeWindowStartTimes[finalIndex] * 1000),
              y: 15,
            },
          ];
          if (sleepState[index - 1] === 8) {
            lightSleep.push(_.clone(currentInterval));
          }
          if (sleepState[index - 1] === 15) {
            deepSleep.push(_.clone(currentInterval));
          }
          if (sleepState[index - 1] === 1) {
            awake.push(_.clone(currentInterval));
          }
          initialIndex = index;
        }
      });

      return {
        lightSleep,
        deepSleep,
        awake,
      };
    },
    [overviewData, findDataSpaces],
  );

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const getGraphsData = useCallback(
    _.debounce(async () => {
      const version = serviceUser?.service?.type;
      if (firebaseToken && historyDate && version && startDay && endDay) {
        const sleepParams = {
          token: `${firebaseToken}`,
          accountId: `${currentUser?.uid}`,
          serviceUserId: serviceUser?.id,
          timeZone: Intl.DateTimeFormat().resolvedOptions().timeZone,
          version,
          startTime: getUnixTime(startDay),
          endTime: getUnixTime(endDay),
        };
        const vitalsParams = {
          token: `${firebaseToken}`,
          accountId: `${currentUser?.uid}`,
          profileId: serviceUser?.id,
          version,
          resolution: 600,
          startTime: getUnixTime(startDay),
          endTime: getUnixTime(endDay),
        };
        const sleepKey = ['sleep', ...Object.values(_.omit(sleepParams, ['token']))].join('-');
        const vitalsKey = ['vitals', ...Object.values(_.omit(vitalsParams, ['token']))].join('-');

        if (history[sleepKey]) {
          setOverviewData(history[sleepKey].data);
        }

        if (history[vitalsKey]) {
          setGraphsData(history[vitalsKey].data);
        }

        if (!isSameDay(previousGraphDate as Date, historyDate)) {
          updateHistory({
            sleep: {
              key: sleepKey,
              params: sleepParams,
            },
            vitals: {
              key: vitalsKey,
              params: vitalsParams,
            },
          });
          setPreviousGraphDate(historyDate);
        }
      } else {
        setGraphsData({});
        setOverviewData({});
      }
      setLoading(false);
    }, HISTORY_REQUEST_DEBOUNCE_MILLIS, { leading: false }),
    [
      firebaseToken,
      currentUser,
      startDay,
      endDay,
      historyDate,
      serviceUser,
      history,
      updateHistory,
      previousGraphDate,
    ],
  );

  const buildAreaData = useCallback((intervals, parsedData) => {
    const areaData : pointPlot[][] = [];

    let initialIndex = 0;
    let finalIndex = intervals.length;
    intervals.forEach((flag: boolean, index: number) => {
      if (flag) {
        finalIndex = index;
        areaData.push(parsedData.slice(initialIndex, finalIndex - 1));
        initialIndex = index;
      }
      if (index === intervals.length - 1) {
        areaData.push(parsedData.slice(initialIndex, intervals.length - 1));
      }
    });

    return areaData;
  }, []);

  const parseHR = useMemo(() => {
    const vitalsRecords = graphsData.data as VitalsData;
    const heartRateAvg = vitalsRecords?.heartRate?.avg || [];

    const parsedHR = heartRateAvg.map((hr, index) => (
      {
        x: new Date(vitalsRecords.timeWindowStartTimes[index] * 1000),
        y: hr,
      }));

    const split = findDataSpaces(true);

    return buildAreaData(split, parsedHR);
  },
  [graphsData, findDataSpaces, buildAreaData]);

  const parseOxygen = useMemo(() => {
    const vitalsRecords = graphsData.data as VitalsData;
    const oxygenAvg = vitalsRecords?.oxygen?.avg || [];

    const parsedOxygen = oxygenAvg.map((ox, index) => (
      {
        x: new Date(vitalsRecords.timeWindowStartTimes[index] * 1000),
        y: ox,
      }));

    const split = findDataSpaces(true);

    return buildAreaData(split, parsedOxygen);
  },
  [graphsData, findDataSpaces, buildAreaData]);

  const currentTime = useMemo(
    () => {
      const startTime = zoomDomain.x[0];
      const endTime = zoomDomain.x[1];
      const mid = new Date(
        (startTime.getTime() + endTime.getTime()) / 2,
      );
      return getUnixTime(mid);
    },
    [zoomDomain],
  );

  const currentOxygen = useCallback(() => {
    const vitalsRecords = graphsData.data as VitalsData;
    if (!historyDate || !vitalsRecords?.timeWindowStartTimes) { return ''; }

    const oxygenAvg = vitalsRecords?.oxygen?.avg || [];
    const index = _.findIndex(vitalsRecords.timeWindowStartTimes,
      (time) => (currentTime - 300 < time && time < currentTime + 300));

    if (index === -1) {
      return '- %';
    }
    return `${oxygenAvg[index]}%`;
  },
  [graphsData, historyDate, currentTime]);

  const currentHR = useCallback(() => {
    const vitalsRecords = graphsData.data as VitalsData;
    if (!historyDate || !vitalsRecords?.timeWindowStartTimes) { return ''; }

    const heartRateAvg = vitalsRecords?.heartRate?.avg || [];
    const index = _.findIndex(vitalsRecords.timeWindowStartTimes,
      (time) => (currentTime - 300 < time && time < currentTime + 300));

    if (index === -1) {
      return '- bpm';
    }
    return `${heartRateAvg[index]} bpm`;
  },
  [graphsData, historyDate, currentTime]);

  const sleepLabel = useCallback(() => {
    const { data } = (overviewData as SleepDataResponse);
    const sleepState = data?.sleepStates || [];
    if (!historyDate || !data?.timeWindowStartTimes) { return null; }

    const index = _.findIndex(data.timeWindowStartTimes,
      (time) => (currentTime - 40 < time && time < currentTime + 40));

    if (index === -1) {
      return '-';
    }

    let label = '';
    switch (sleepState[index]) {
      case 1:
        label = t('Awake');
        break;
      case 8:
        label = t('Light Sleep');
        break;
      case 15:
        label = t('Deep Sleep');
        break;
      default:
        break;
    }

    return label;
  },
  [overviewData, historyDate, currentTime, t]);

  useEffect(() => {
    getGraphsData();
    return getGraphsData.cancel;
  }, [serviceUser, getGraphsData]);

  useEffect(() => {
    const today = new Date();
    const startTime = subDays(startOfDay(graphDate), 1);
    const endTime = isSameDay(today, graphDate)
      ? addHours(endOfDay(today), 6)
      : addDays(startOfDay(graphDate), 2);

    setStartDay(startTime);
    setEndDay(endTime);

    setZoomDomain({
      x: [
        subHours(graphDate, 6),
        addHours(graphDate, 6),
      ],
    });
  }, [graphDate]);

  const renderAxis = () => (
    <VictoryAxis
      dependentAxis
      offsetX={300}
      tickFormat={() => ''}
      style={{ axis: { stroke: '#94dec9', strokeWidth: 3, strokeLinecap: 'initial' } }}
    />
  );

  const renderGridLines = () => (
    <VictoryAxis
      tickFormat={() => ''}
      tickCount={dateRange().length}
      tickValues={dateRange()}
      style={{
        grid: {
          stroke: ({ tick }) => (tick.getHours() === 0 ? 'white' : '#494D5D'),
          strokeDasharray: ({ tick }) => (tick.getHours() === 0 ? '4.4' : 'none'),
          strokeWidth: ({ tick }) => (tick.getHours() === 0 ? 1 : 0.5),
        },
        axis: { stroke: 'none' },
      }}
    />
  );

  const calculateDayChange = (domainProps: any) => {
    const { currentDomain } = domainProps;
    const current = middleHour(currentDomain.x[0], currentDomain.x[1]);
    setHistoryDate(current);
  };

  const onZoomDomainChange = (domainProps: any, graphDomain: any) => {
    setZoomDomain({ x: graphDomain.x });
    calculateDayChange(domainProps);
  };

  const moveBack = () => {
    const previousHour = middleHour(subHours(zoomDomain.x[0], 1), subHours(zoomDomain.x[1], 1));
    const finalDate = startOfDay(subDays(new Date(), 30));
    const nextDomain = [subHours(zoomDomain.x[0], 1), subHours(zoomDomain.x[1], 1)];
    if (previousHour < finalDate) {
      return;
    }
    const current = middleHour(nextDomain[0], nextDomain[1]);
    setZoomDomain({ x: nextDomain });
    if (!isSameDay(current, historyDate as Date)) setHistoryDate(current);
  };

  const moveForward = () => {
    const nextHour = middleHour(addHours(zoomDomain.x[0], 1), addHours(zoomDomain.x[1], 1));
    const finalDate = endOfDay(new Date());
    const nextDomain = [addHours(zoomDomain.x[0], 1), addHours(zoomDomain.x[1], 1)];
    if (nextHour > finalDate) {
      return;
    }
    const current = middleHour(nextDomain[0], nextDomain[1]);
    setZoomDomain({ x: nextDomain });
    if (!isSameDay(current, historyDate as Date)) setHistoryDate(current);
  };

  const dateRange = () => {
    const dates = [];
    let startDate = startDay as Date;
    const endDate = endDay as Date;

    while (startDate < endDate) {
      dates.push(startDate);

      startDate = addHours(startDate, 2);
    }
    dates.push(endDate);

    return dates;
  };

  const AreaLabel = (props: any) => {
    const { index, data } = props;
    const text = 'o';

    if (index === '0') {
      return <VictoryLabel {...props} renderInPortal dy={10} dx={-5} text={text} />;
    }
    if (index === `${data.length - 1}`) {
      return <VictoryLabel {...props} renderInPortal dy={10} dx={6} text={text} />;
    }

    return null;
  };

  if (loading) {
    return <Loader />;
  }

  return (
    <Container>
      <DateControls>
        <Icon icon="ChevronLeft" onClick={() => moveBack()} width={24} height={24} fill={theme.color.text.darkMode.primary} />
        <DateHeading>
          {format(middleHour(zoomDomain.x[0], zoomDomain.x[1]), 'MMM dd, p')}
        </DateHeading>
        <Icon icon="ChevronRight" onClick={() => moveForward()} width={24} height={24} fill={theme.color.text.darkMode.primary} />
      </DateControls>
      <VictoryAxis
        scale={{ x: 'time' }}
        width={600}
        height={40}
        padding={
          {
            top: 10,
            bottom: 40,
            left: 0,
            right: 0,
          }
        }
        domain={zoomDomain}
        tickCount={dateRange().length}
        tickValues={dateRange()}
        tickFormat={(x) => format(x, 'p')}
        style={{ tickLabels: { fill: theme.color.text.darkMode.primary, fontSize: 20 }, axis: { stroke: 'none' } }}
      />
      <Divider />
      <LabelContainer>
        <Icon icon="StatusSleep" width={24} height={24} fill={theme.color.tertiary.rhythm} />
        <LabelBody>{sleepLabel()}</LabelBody>
        <Icon
          icon="Help"
          width={20}
          height={20}
          fill={theme.color.text.darkMode.primary}
          onClick={() => setModalBarChartActive(!modalBarChartActive)}
        />
      </LabelContainer>
      <VictoryChart
        scale={{ x: 'time' }}
        width={600}
        height={130}
        padding={
          {
            top: 0,
            bottom: 0,
            left: 0,
            right: 0,
          }
        }
        domain={
          {
            x: [
              startDay as Date,
              endDay as Date,
            ],
          }
        }
        containerComponent={(
          <VictoryZoomContainer
            allowZoom={false}
            zoomDomain={zoomDomain}
            onZoomDomainChange={(domain, props) => onZoomDomainChange(props, domain)}
          />
        )}
      >
        {renderGridLines()}
        {parseSleep.awake.map((interval) => (
          <VictoryLine
            key={interval[0].x.getTime()}
            data={interval}
            style={{
              data: { stroke: '#f98c7f', strokeWidth: 25 },
            }}
          />
        ))}
        {parseSleep.lightSleep.map((interval) => (
          <VictoryLine
            key={interval[0].x.getTime()}
            data={interval}
            style={{ data: { stroke: '#bbbedb', strokeWidth: 25 } }}
          />
        ))}
        {parseSleep.deepSleep.map((interval) => (
          <VictoryLine
            key={interval[0].x.getTime()}
            data={interval}
            style={{ data: { stroke: '#7c77a2', strokeWidth: 25 } }}
          />
        ))}
        {renderAxis()}
      </VictoryChart>
      <Divider />
      <LabelContainer>
        <Icon icon="StatusOxygen" width={24} height={24} fill={theme.color.tertiary.ocean} />
        <LabelHeading style={{ color: '#319fbc' }}>{currentOxygen()}</LabelHeading>
        <Icon
          icon="Help"
          width={20}
          height={20}
          fill={theme.color.text.darkMode.primary}
          onClick={() => setModalLineChartActive(!modalLineChartActive)}
        />
      </LabelContainer>
      <VictoryChart
        scale={{ x: 'time' }}
        width={600}
        height={130}
        padding={
          {
            top: 0,
            bottom: 0,
            left: 0,
            right: 0,
          }
        }
        domain={
          {
            x: [
              startDay as Date,
              endDay as Date,
            ],
            y: [
              0,
              130,
            ],
          }
        }
        containerComponent={(
          <VictoryZoomContainer
            allowZoom={false}
            zoomDomain={zoomDomain}
            onZoomDomainChange={(domain, props) => onZoomDomainChange(props, domain)}
          />
        )}
      >
        {renderGridLines()}
        {parseOxygen.map((interval) => (
          <VictoryArea
            key={interval[0].x.getTime()}
            interpolation="natural"
            data={interval}
            style={{
              data: {
                stroke: '#319fbc', strokeWidth: 4, fillOpacity: 0.10, fill: '#319fbc',
              },
              labels: {
                fontSize: 30,
                fontWeight: '600',
                fill: '#319fbc',
              },
            }}
            labels={() => ''}
            labelComponent={<AreaLabel />}
          />
        ))}
        {renderAxis()}
      </VictoryChart>
      <Divider />
      <LabelContainer>
        <Icon icon="StatusHeartrate" width={24} height={24} fill={theme.color.tertiary.strawberry} />
        <LabelHeading style={{ color: '#f98c7f' }}>{currentHR()}</LabelHeading>
        <Icon
          icon="Help"
          width={20}
          height={20}
          fill={theme.color.text.darkMode.primary}
          onClick={() => setModalLineChartActive(!modalLineChartActive)}
        />
      </LabelContainer>
      <VictoryChart
        scale={{ x: 'time' }}
        width={600}
        height={180}
        padding={
          {
            top: 0,
            bottom: 40,
            left: 0,
            right: 0,
          }
        }
        domain={
          {
            x: [
              startDay as Date,
              endDay as Date,
            ],
            y: [
              0,
              240,
            ],
          }
        }
        containerComponent={(
          <VictoryZoomContainer
            allowZoom={false}
            zoomDomain={zoomDomain}
            onZoomDomainChange={(domain, props) => onZoomDomainChange(props, domain)}
          />
        )}
      >
        {renderGridLines()}
        {parseHR.map((interval) => (
          <VictoryArea
            key={interval[0].x.getTime()}
            interpolation="natural"
            data={interval}
            style={{
              data: {
                stroke: '#f98c7f', strokeWidth: 4, fillOpacity: 0.10, fill: '#f98c7f',
              },
              labels: {
                fontSize: 30,
                fontWeight: '600',
                fill: '#f98c7f',
              },
            }}
            labels={() => ''}
            labelComponent={<AreaLabel />}
          />
        ))}
        {renderAxis()}
        <VictoryAxis
          tickCount={dateRange().length}
          tickValues={dateRange()}
          tickFormat={(x) => format(x, 'p')}
          style={{ tickLabels: { fill: theme.color.text.darkMode.primary, fontSize: 20 }, axis: { stroke: 'none' } }}
        />
      </VictoryChart>
      <Divider />
      <Modal
        isActive={modalLineChartActive}
        onClose={() => setModalLineChartActive(!modalLineChartActive)}
      >
        <ModalChartLine />
      </Modal>
      <Modal
        isActive={modalBarChartActive}
        onClose={() => setModalBarChartActive(!modalBarChartActive)}
      >
        <ModalChartBar />
      </Modal>
    </Container>
  );
};

export default memo(Graphs);
