const colors = {
  background: {
    darkMode: {
      darker: '#19232F',
      dark: '#34323F',
      light: '#494D5D',
      account: '#272B37',
    },
  },
  border: {
    darkMode: {
      dark: '#34323F',
    },
  },
  status: {
    error: '#EE4D4D',
    warning: '#FFC45E',
    outOfRange: '#3BA1BD',
  },
  text: {
    darkMode: {
      primary: '#D4D4F9',
      secondary: '#b5b5b5',
      disabled: '#7A7A8F',
    },
    warning: '#FFD786',
    error: '#F4897C',
    good: '#9DE3CD',
  },
  input: {
    bg: {
      default: '#ffffff',
      disabled: '#dedede',
      focus: '#ffffff',
      hover: '#FAFBFC',
    },
    border: {
      default: '#ECECED',
      disabled: '#dedede',
      focus: '#99a3c4',
      hover: '#dfdfe0',
    },
  },
  placeholder: '#9FA0A0',
  primary: {
    default: '#9de3cd',
    dark: '#73C7AD',
    light: '#CFF1E5',
    darkGray: '#404151',
  },
  secondary: {
    default: '#D4D4F9',
  },
  tertiary: {
    default: '#FAB894',
    strawberry: '#EE4D4D',
    rhythm: '#6A76A0',
    ocean: '#007D9E',
    wave: '#76B1B7',
  },
  data: {
    awake: '#f98c7f',
    deepSleep: '#7c77a2',
    lightSleep: '#bbbedb',
  },
  white: '#ffffff',
};

export default colors;
