import IconList, {
  SVGProps,
  PathProps,
  PolygonProps,
  CircleProps,
} from './IconList';
import { IconTypes } from './IconTypes';

interface IconProps extends React.SVGProps<SVGSVGElement> {
  icon: IconTypes;
}

const Icon: React.FC<IconProps> = ({ icon, fill, ...rest }) => {
  const haveSVG = Object.prototype.hasOwnProperty.call(IconList, icon);
  const svg: SVGProps | null = haveSVG ? IconList[icon] : null;

  if (!svg) {
    return null;
  }

  return (
    <svg viewBox={svg.viewbox} {...rest}>
      <g fill={fill && fill}>
        {svg.circles
          ? svg.circles.map(({
            cx, cy, r,
          }: CircleProps) => (
            <circle
              key={`${cx}-${cy}-${r}`}
              cx={cx}
              cy={cy}
              r={r}
              fill={fill}
            />
          ))
          : null}

        {svg.paths
          ? svg.paths.map(({ path, fill: pathFill }: PathProps) => (
            <path key={path} d={path} fill={pathFill ?? fill} />
          ))
          : null}

        {svg.polygons
          ? svg.polygons.map(({ points }: PolygonProps, i) => (
            <polygon points={points} fill={fill} />
          ))
          : null}
      </g>
    </svg>
  );
};

Icon.defaultProps = { width: 16.5, height: 16.5 };

export default Icon;
