import styled from 'styled-components';
import { ServiceUser } from '../../../shared/types';
import theme from '../../design/theme';
import Avatar from '../Avatar';
import { Caption } from '../Typography';

type AvatarProps = {
  $isActive?: boolean;
}

const Layout = styled.section`
  background-color: ${theme.color.background.darkMode.dark};
  display: flex;
  flex-wrap: nowrap;
  overflow-x: auto;
  align-items: center;
  justify-content: stretch;
  height: 110px;
  margin-top: 1px;
  padding: 0 20px;

  ::-webkit-scrollbar {
    display: none;
  }
`;

const UserContainer = styled.div`
  display: flex;
  flex: 0 0 100px;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;

const AvatarContainer = styled.div<AvatarProps>`
  display: flex;
  justify-content: center;
  height: 68px;
  width: 68px;
  border-radius: 34px;
  border: 2px solid ${({ $isActive }) => ($isActive ? theme.color.primary.default : theme.color.background.darkMode.dark)};
`;

const StyledCaption = styled(Caption)<AvatarProps>`
  margin-top: 2px;
  color: ${({ $isActive }) => ($isActive ? theme.color.primary.default : theme.color.text.darkMode.primary)};
`;

type UserSelectProps = {
  usersList: ServiceUser[];
  activeUser: ServiceUser | undefined;
  handleChildSelect: (child: ServiceUser) => void;
};

type UserProps = {
  isActive?: boolean;
  onClick: () => void;
  name: string;
};

const User = ({ isActive, onClick, name }: UserProps) => (
  <UserContainer onClick={onClick} data-testid="user-button">
    <AvatarContainer $isActive={isActive}>
      <Avatar size="small" />
    </AvatarContainer>
    <StyledCaption $isActive={isActive}>{name}</StyledCaption>
  </UserContainer>
);

const UserSelect = ({ usersList, activeUser, handleChildSelect }: UserSelectProps) => {
  const isUserActive = (user: ServiceUser) => user === activeUser;

  if (usersList.length > 1) {
    return (
      <Layout>
        { usersList.map((user) => (
          <User
            key={user.id}
            name={user.firstName}
            isActive={isUserActive(user)}
            onClick={() => handleChildSelect(user)}
          />
        ))}
      </Layout>
    );
  }

  return null;
};

export default UserSelect;
