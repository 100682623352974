import { DeviceConnectionStatus } from '../../shared/types';

type DeviceConnectionStatusFunction = (
  ts1?: string,
  ts2?: string,
  ts3?: string
) => DeviceConnectionStatus

const APP_ACTIVE_OFFLINE = 20000;

const deviceConnectionStatus: DeviceConnectionStatusFunction = (
  appActiveAt,
  vitalsPostAt,
  firstAppActiveAt,
) => {
  if (!appActiveAt || !firstAppActiveAt || !vitalsPostAt) {
    return DeviceConnectionStatus.Connecting;
  }

  const appActiveDate = new Date(appActiveAt);
  const vitalsPostDate = new Date(vitalsPostAt);
  const firstAppActiveDate = new Date(firstAppActiveAt);

  const online = (appActiveDate.getTime() - APP_ACTIVE_OFFLINE) < vitalsPostDate.getTime();
  const connectingWindow = (Date.now() - APP_ACTIVE_OFFLINE) < firstAppActiveDate.getTime();
  const notOnline = connectingWindow
    ? DeviceConnectionStatus.Connecting : DeviceConnectionStatus.Disconnected;
  return online ? DeviceConnectionStatus.Connected : notOnline;
};

export default deviceConnectionStatus;
