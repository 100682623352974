import { useTranslation } from 'react-i18next';
import { Body, PageHeading } from '../Typography';

const ModalDeviceStatus = () => {
  const { t } = useTranslation();

  return (
    <div>
      <PageHeading>{t('Device Status')}</PageHeading>
      <Body>{t('This is your Owlet Sock device status. If there is ever an issue with device connectivity or range, it will show up here.')}</Body>
    </div>
  );
};

export default ModalDeviceStatus;
