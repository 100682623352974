import styled from 'styled-components';
import { Collapse } from '@mui/material';
import { useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import theme from '../../design/theme';
import SmartSockImg from '../../assets/device_sock3.png';
import DeviceCardData from './DeviceCardData';
import { Caption, Heading } from '../../components/Typography';
import Icon from '../../components/Icon/Icon';
import { emptyServiceUser, DeviceDetails, DeviceConnectionStatus } from '../../../shared/types';
import { useServiceUserContext } from '../../../backend/contexts/serviceUserContext';
import { baseStationColor, determineBatteryIcon, wifiColor } from '../../../backend/utils/helpers';
import { deviceResource } from '../../navigation/routes';
import { useAccountContext } from '../../../backend/contexts/accountContext';
import Modal from '../../components/Modal/Modal';
import ModalDeviceStatus from '../../components/Modal/ModalDeviceStatus';
import { IconTypes } from '../../components/Icon';

type ProfileCardProps = {
  serviceUserKey: string;
  deviceDetails?: DeviceDetails;
  deviceKey: string;
}

const SmartSockCardGrid = styled.section`
  background-color: ${theme.color.background.darkMode.dark};
  margin: 60px 0 40px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
`;

const ImageBackground = styled.div`
  height: 100px;
  width: 100px;
  border-radius: 50px;
  background-color: ${theme.color.background.darkMode.dark};
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: -40px;
`;

const SettingsContainer = styled.div`
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: space-between;
`;

const UtilsContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-around;
  width: 80px;
  padding-right: 10px;
`;

const Spacer = styled.div`
  width: 90px;
`;

const SmartSockImage = styled.img`
  height: 80px;
  width: 80px;
  object-fit: contain;
`;

const ContentContainer = styled.div`
  display: grid;
  grid-template-columns: 20% 60% 20%;
  width: 100%;
`;

const ProfileNameTitleArea = styled.div`
  text-align: center;
  grid-column: 2 / 3;
`;

const ExpandIconArea = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`;

const StyledCollapse = styled(Collapse)`
  width: 100%;
`;

const StatusIconsGrid = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-around;
  width: 60%;
  margin: 20px;
`;

const Divider = styled.div`
  height: 20px;
  width: 2px;
  color: ${theme.color.background.darkMode.darker};
`;

const DeviceCard = ({ serviceUserKey, deviceDetails, deviceKey }: ProfileCardProps) => {
  const { accountKey } = useAccountContext();
  const [profileExpanded, setProfileExpanded] = useState(false);
  const { findServiceUser } = useServiceUserContext();
  const data = findServiceUser(serviceUserKey) || emptyServiceUser;
  const { t } = useTranslation();
  const batteryStatus = useMemo<IconTypes>(() => (
    determineBatteryIcon(deviceDetails?.bat, deviceDetails?.chg)
  ), [deviceDetails?.bat, deviceDetails?.chg]);
  const baseStationIconColor = baseStationColor(deviceDetails?.bso);
  const [modalActive, setModalActive] = useState<boolean>(false);

  return (
    <SmartSockCardGrid>
      <SettingsContainer>
        <Spacer />
        <ImageBackground>
          <SmartSockImage
            alt={t('Smart Sock')}
            src={SmartSockImg}
          />
        </ImageBackground>
        <UtilsContainer>
          <Icon
            icon="Help"
            width={24}
            height={24}
            fill={theme.color.text.darkMode.primary}
            onClick={() => setModalActive(!modalActive)}
          />
          <Link to={deviceResource(accountKey, serviceUserKey, deviceKey)}>
            <Icon
              icon="Settings"
              width={24}
              height={24}
              fill={theme.color.text.darkMode.primary}
            />
          </Link>
        </UtilsContainer>
      </SettingsContainer>
      <ContentContainer>
        <ProfileNameTitleArea>
          <Caption>
            {t('{{childName}}\'s Smart Sock', { childName: data.firstName })}
            {' '}
          </Caption>
          <Heading>{deviceDetails?.status ? t(deviceDetails?.status) : '---'}</Heading>
        </ProfileNameTitleArea>
        <ExpandIconArea
          data-dd-action-name={t('{{childName}}\'s Smart Sock', { childName: `(child ID: ${data.id})` })}
        >
          {profileExpanded
            ? (
              <Icon icon="ChevronUp" width={24} height={24} fill="white" onClick={() => setProfileExpanded(!profileExpanded)} />
            )
            : (
              <Icon icon="ChevronDown" width={24} height={24} fill="white" onClick={() => setProfileExpanded(!profileExpanded)} />
            )}
        </ExpandIconArea>
      </ContentContainer>
      <StyledCollapse
        in={profileExpanded}
        timeout="auto"
        unmountOnExit
      >
        <DeviceCardData
          {...deviceDetails}
        />
      </StyledCollapse>
      {!profileExpanded
        ? (
          <StatusIconsGrid>
            <Icon icon="StatusBaseStation3" fill={baseStationIconColor} width={24} height={24} />
            <Divider />
            <Icon
              icon={deviceDetails?.connectionStatus === DeviceConnectionStatus.Connected ? 'StatusWifi' : 'StatusWifiOff'}
              fill={wifiColor(deviceDetails?.connectionStatus)}
              width={24}
              height={24}
            />
            <Divider />
            <Icon
              icon={batteryStatus}
              fill={theme.color.primary.default}
              width={24}
              height={24}
            />
          </StatusIconsGrid>
        )
        : <div />}
      <Modal
        isActive={modalActive}
        onClose={() => setModalActive(!modalActive)}
      >
        <ModalDeviceStatus />
      </Modal>
    </SmartSockCardGrid>
  );
};

export default DeviceCard;
