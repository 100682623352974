import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { getAuth, signInWithEmailAndPassword } from 'firebase/auth';
import {
  FormControl, InputAdornment, IconButton,
} from '@mui/material';
import { useTranslation } from 'react-i18next';
import {
  Screen,
  StyledInput,
  StyledInputLabel,
  StyledLink,
  StyledPasswordOff,
  StyledPasswordOn,
} from './Styles';
import Button from '../../components/Button/Button';
import { home, forgotPassword, welcome } from '../../navigation/routes';
import { PageHeading } from '../../components/Typography';
import BackButton from '../../components/Button/BackButton';
import ErrorBanner from '../../components/ErrorBanner';

interface LogInState {
  email: string,
  password: string,
  showPassword: boolean,
}

const Login = () => {
  const auth = getAuth();
  const navigate = useNavigate();
  const { t } = useTranslation();
  const [error, setError] = useState<Record<string, string> | undefined>();
  const [errorMessageView, setErrorView] = useState<boolean | string>(false);
  const [formData, setFormData] = useState<LogInState>({
    email: '',
    password: '',
    showPassword: false,
  });
  const { email, password, showPassword } = formData;

  const LoginFireBaseUser = () => {
    signInWithEmailAndPassword(auth, email, password)
      .then(() => {
        navigate(home());
      })
      .catch((e) => {
        if (e.code === 'auth/invalid-email') {
          e.message = t('Invalid Email. Please try again.');
        }
        if (e.code === 'auth/user-not-found') {
          e.message = t('No user found. Please try a different email or sign up.');
        }
        if (e.code === 'auth/wrong-password') {
          e.message = t('Incorrect Password.  Please try again.');
        }
        if (e.code === 'auth/internal-error') {
          e.message = t('There was an unexpected error. Please try again.');
        }
        setError(e);
        setErrorView(true);
      });
  };

  return (
    <form>
      {!errorMessageView
          || (
            <ErrorBanner
              onClose={() => {
                setErrorView(false);
              }}
              message={error?.message}
            />
          )}
      <BackButton path={welcome()} />
      <Screen>
        <PageHeading>{t('Login')}</PageHeading>
        <FormControl variant="standard">
          <StyledInputLabel
            id="email"
          >
            {t('Email')}
          </StyledInputLabel>
          <StyledInput
            name="email"
            type="email"
            value={email}
            onChange={(e) => setFormData((prev) => ({ ...prev, email: e.target.value }))}
          />
        </FormControl>
        <FormControl variant="standard">
          <StyledInputLabel
            id="password"
          >
            {t('Password')}
          </StyledInputLabel>
          <StyledInput
            name="password"
            value={password}
            type={showPassword ? 'text' : 'password'}
            onChange={(e) => setFormData((prev) => ({ ...prev, password: e.target.value }))}
            endAdornment={(
              <InputAdornment position="end">
                <IconButton
                  aria-label="toggle password visibility"
                  onClick={(e) => setFormData((prev) => (
                    { ...prev, showPassword: !showPassword }
                  ))}
                  onMouseDown={(e) => setFormData((prev) => (
                    { ...prev, showPassword: !showPassword }
                  ))}
                  edge="end"
                >
                  {showPassword ? <StyledPasswordOff /> : <StyledPasswordOn />}
                </IconButton>
              </InputAdornment>
            )}
          />
        </FormControl>
        <StyledLink to={forgotPassword()}>
          {t('Forgot Password?')}
        </StyledLink>
        <Button
          label={t('Login')}
          size="large"
          onClick={() => LoginFireBaseUser()}
        />
      </Screen>
    </form>
  );
};

export default Login;
