export enum ConfigVarName {
  AYLA_APP_ID = 'AYLA_APP_ID',
  AYLA_APP_SECRET = 'AYLA_APP_SECRET',
  AYLA_ENV = 'AYLA_ENV',
  FIREBASE_APP_ID = 'FIREBASE_APP_ID',
  FIREBASE_API_KEY = 'FIREBASE_API_KEY',
  FIREBASE_AUTH_DOMAIN = 'FIREBASE_AUTH_DOMAIN',
  FIREBASE_DATABASE_URL = 'FIREBASE_DATABASE_URL',
  FIREBASE_PROJECT_ID = 'FIREBASE_PROJECT_ID',
  OWLET_API_DOMAIN = 'OWLET_API_DOMAIN',
  ENABLE_DD_RUM = 'ENABLE_DD_RUM',
  ENABLE_DD_RUM_SESSION_REPLAY = 'ENABLE_DD_RUM_SESSION_REPLAY',
  FEATURE_FLAG_API_KEY = 'FEATURE_FLAG_API_KEY',
  NPS_API_KEY = 'NPS_API_KEY',
  NPS_APP_ID = 'NPS_APP_ID',
  NPS_USE_PROD = 'NPS_USE_PROD'
}

export type ConfigVarKind = {
  [ConfigVarName.AYLA_APP_ID]: string;
  [ConfigVarName.AYLA_APP_SECRET]: string;
  [ConfigVarName.AYLA_ENV]: string;
  [ConfigVarName.FIREBASE_APP_ID]: string;
  [ConfigVarName.FIREBASE_API_KEY]: string;
  [ConfigVarName.FIREBASE_AUTH_DOMAIN]: string;
  [ConfigVarName.FIREBASE_DATABASE_URL]: string;
  [ConfigVarName.FIREBASE_PROJECT_ID]: string;
  [ConfigVarName.OWLET_API_DOMAIN]: string;
  [ConfigVarName.ENABLE_DD_RUM]: boolean;
  [ConfigVarName.ENABLE_DD_RUM_SESSION_REPLAY]: boolean;
  [ConfigVarName.FEATURE_FLAG_API_KEY]: string;
  [ConfigVarName.NPS_API_KEY]: string;
  [ConfigVarName.NPS_APP_ID]: string;
  [ConfigVarName.NPS_USE_PROD]: boolean;
}

export enum deviceOptions {
  // t('Smart Sock')
  smartSock = 'Smart Sock',
  // t('Smart Sock 3')
  smartSock3 = 'Smart Sock 3',
  // t('Dream Sock')
  smartSock3Sleep = 'Dream Sock',
}

type Keys = Record<string, boolean>

export enum FirebaseResponseStatus {
  idle = 'idle',
  success = 'success',
  error = 'error',
}

export type FirebaseStatus = keyof typeof FirebaseResponseStatus;

export type Account = {
  accountAttribute: string;
  serviceKeys: Keys;
  serviceUserKeys: Keys;
}

export type AccountResponse = {
  status: FirebaseStatus;
  data: Account | undefined;
}

export type Service = {
  accountKey: string;
  createdAt: string;
  deviceKey: string;
  id: string;
  serviceUserKeys: Keys;
  status: string;
  type: keyof typeof deviceOptions;
}

export type ServiceResponse = {
  status: FirebaseStatus;
  data: Service | undefined;
}

export type Device = {
  accountKey: string;
  aylaEnvironment: string;
  createdAt: string;
  dsn: string;
  type: keyof typeof deviceOptions;
}

export type DeviceResponse = {
  status: FirebaseStatus;
  data: Device | undefined;
}

export type ServiceUser = {
  accountKey: string;
  dueDate?: number;
  firstName: string;
  id: string;
  type: string;
  avatar: string;
  birthDate?: number;
  gender?: string | null;
  nicuStay?: null | boolean;
  relationship: string | null;
  races: string[];
  service?: Service;
}

export type ServiceUserResponse = {
  status: FirebaseStatus;
  data: ServiceUser | undefined;
}

type Avatar = {
  filename: string;
}

export type AccountUser = {
  firstName: string;
  lastName: string;
  email: string;
  id: string;
  avatars?: Record<string, Avatar>;
  birthdate?: number;
  phone?: string;
}

export type AccountUserResponse = {
  status: FirebaseStatus;
  data: AccountUser | undefined;
}

export const emptyAccountUser: AccountUser = {
  firstName: '',
  lastName: '',
  id: '',
  email: '',
};

export const emptyService: Service = {
  accountKey: '',
  createdAt: '',
  deviceKey: '',
  id: '',
  serviceUserKeys: {},
  status: '',
  type: 'smartSock' as keyof typeof deviceOptions,
};

export const emptyDevice: Device = {
  accountKey: '',
  aylaEnvironment: '',
  createdAt: '',
  dsn: '',
  type: 'smartSock' as keyof typeof deviceOptions,
};

export const emptyServiceUser: ServiceUser = {
  accountKey: '',
  firstName: '',
  id: '',
  type: '',
  avatar: '',
  gender: '',
  relationship: '',
  races: [],
  nicuStay: null,
};

export const emptyAccount: Account = {
  accountAttribute: '',
  serviceKeys: {},
  serviceUserKeys: {},
};

export type AylaDevice = {
  device: {
    dsn: string,
    oem_model: string, // eslint-disable-line camelcase
    id: string,
  }
}

export type RegisterDeviceBody = {
  device: {
    dsn: string,
    ip: string,
  }
}

export type AylaDeviceProperty = {
  property: {
    name: string;
    value: string;
    data_updated_at?: string; // eslint-disable-line camelcase
  }
}

export type AylaSetActiveDataPoint = {
  datapoint: {
    updated_at: string; // eslint-disable-line camelcase
  }
}

export type RealTimeVitals = {
  ox: number // Oxygen Level
  hr: number // Heart Rate
  mv: number // Movement
  sc: number // Sock Connection
  st: number // Skin Temp
  bso: number // Basestatin On
  bat: number // Battery level
  btt: number // Battery Time Remaining
  chg: number // Charge Status
  aps: number // Alert Pause Status
  alrt: number // Alert Status
  ota: SmartSockOTAState // OTA Status
  srf: number // Sock Readings Flag
  rsi: number // Bluetooth RSI
  sb: number // Soft Bricked Status
  ss: number // Sleep Status
  hw: string // Hardware type
}

export enum DeviceConnectionStatus {
  // t('Connected')
  Connected = 'Connected',
  // t('Connecting')
  Connecting = 'Connecting',
  // Disconnected = 'Disconnected' - This is what it should be after OWA-281 is resolved.
  // t('Connecting...')
  Disconnected = 'Connecting...'
}

export enum DeviceStatus {
  // offline = 'Base Station Offline', - This is what it should be after OWA-281 is resolved.
  // t('Connecting to Base Station...')
  offline = 'Connecting to Base Station...',
  // t('Connecting...')
  connecting = 'Connecting...',
  // t('App Searching for Base Station')
  troubleGettingData = 'App Searching for Base Station',
  // t('Update in Progress')
  otaInProgress = 'Update in Progress',
  // t('Sock Charging Complete')
  sockCharged = 'Sock Charging Complete',
  // t('Sock Charging')
  sockCharging = 'Sock Charging',
  // t('Base Station Turned Off')
  basestationOff = 'Base Station Turned Off',
  // t('Notification Temporarily Paused')
  alertPaused = 'Notification Temporarily Paused',
  // t('Sock Disconnected')
  sockDisconnected = 'Sock Disconnected',
  // t('Check Sock Placement')
  sockPlacementIssue = 'Check Sock Placement',
  // t('One Moment... Getting Data')
  sockRecentlyPlaced = 'One Moment... Getting Data',
  // t('Wiggling')
  kicking = 'Wiggling',
  // t('One Moment... Getting Data')
  checkingOn = 'One Moment... Getting Data',
  // t('Receiving Data')
  gettingData = 'Receiving Data',
}

export type DeviceDetails = {
  ox?: number
  hr?: number
  sc?: number
  btt?: number
  bso?: number
  chg?: number
  alrt?: number
  srf?: number
  bat?: number
  ota?: SmartSockOTAState
  connectionStatus?: DeviceConnectionStatus
  status?: DeviceStatus
  lastUpdatedAtUTC?: string
  setDeviceActiveAtUTC?: string
  firstSetDeviceActiveAtUTC?: string
}

export enum SmartSockOTAState {
    none = 0,
    firmwareBeingSent = 1,
    waitingForSockToBePluggedIn = 2,
    installing = 3,
    installingCritical = 4,
    unknown = 5,
}

export type AylaContextState = {
  [key: string]: DeviceDetails
}

export enum AylaContextActions {
  Reset = 'Reset',
  UpdateVitals = 'UpdateVitals',
  UpdateDeviceActive = 'UpdateDeviceActive',
}

export type AylaReset = {
  type: AylaContextActions.Reset
}

export type AylaUpdateDeviceActivePayload = {
  deviceActiveAt: string,
  dsn: string,
}

export type AylaUpdateDeviceActive = {
  type: AylaContextActions.UpdateDeviceActive,
  payload: AylaUpdateDeviceActivePayload,
}

export type AylaUpdateVitalsPayload = {
  dsn: string,
  lastUpdatedAtUTC: string,
  vitals: RealTimeVitals,
}

export type AylaUpdateVitals = {
  type: AylaContextActions.UpdateVitals,
  payload: AylaUpdateVitalsPayload
}

export type AylaContextAction = AylaReset | AylaUpdateDeviceActive | AylaUpdateVitals

export type GetIpAddressResponse = {
  ip: string
}

export type GetMiniTokenResponse = {
  mini_token: string // eslint-disable-line camelcase
}

export type SleepSession = {
  startTime: number,
  endTime: number,
  longestSleepSegmentMinutes: number,
  wakingsCount: number,
  sessionType: string,
  sleepOnsetMinutes: number,
  sleepQuality: number,
  sleepStateDurationsMinutes: Record<string, number>,
}

type SleepData = {
  timeWindowStartTimes: number[];
  sleepStates: number[];
}

export type SleepDataResponse = {
  data: SleepData;
  sessions: SleepSession[]
}

type DataSerie = {
  avg: number[],
  min: number[],
  max: number[],
}

export type VitalsData = {
  counts: {
    validSamples: number[],
  }
  firstReadingTimes: number[],
  heartRate: DataSerie,
  lastReadingTimes: number[],
  movement: Pick<DataSerie, 'avg'>,
  oxygen: DataSerie,
  timeWindowStartTimes: number[],
}

export type VitalsDataResponse = {
  data: VitalsData
}

export enum HistoryTab {
  OVERVIEW,
  GRAPH,
}
