import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import styled from 'styled-components';
import OwlIcon from '../../assets/owl_lookdown.svg';
import { Body, PageHeading } from '../../components/Typography';
import theme from '../../design/theme';
import { useAylaContext } from '../../../backend/contexts/aylaContext';
import { oTAUpdatesPaused, oTAUpdatesSuccess } from './routes';
import { useDeviceSetupContext } from '../../../backend/contexts/deviceSetupContext';
import { SmartSockOTAState } from '../../../shared/types';

const Layout = styled.div`
  display: flex;
  flex-direction: column;
  `;

const Content = styled.div`
  display: flex;
  flex-direction: column;
  padding: 20px;
`;

const ImageContainer = styled.img`
  width: 100px;
  height: 100px;
  margin: 30px 0;
  align-self: center;
`;

const LoaderContainer = styled.div`
  margin-top: 80px;
  align-self: center;
`;

const Loader = styled.div`
  border: 6px solid ${theme.color.background.darkMode.darker};
  border-top: 6px solid ${theme.color.primary.default};
  border-radius: 50%;
  width: 60px;
  height: 60px;
  animation: spin 1s linear infinite;
  margin-bottom: 20px;

  @keyframes spin {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }
`;

const CheckOTAUpdates = () => {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const { dsn } = useDeviceSetupContext();
  const { deviceDetails, refreshDevices } = useAylaContext();

  useEffect(() => {
    refreshDevices();
  }, [refreshDevices]);

  useEffect(() => {
    let timeoutId: ReturnType<typeof setTimeout>;

    if (dsn) {
      const otaState = deviceDetails[dsn]?.ota ?? SmartSockOTAState.unknown;

      if (otaState === SmartSockOTAState.none) {
        timeoutId = setTimeout(() => {
          navigate(oTAUpdatesSuccess());
        }, 2000);
      } else if (otaState === SmartSockOTAState.waitingForSockToBePluggedIn) {
        navigate(oTAUpdatesPaused());
      }
    }
    return () => {
      clearTimeout(timeoutId);
    };
  }, [deviceDetails, dsn, navigate]);

  return (
    <Layout>
      <Content>
        <ImageContainer
          alt={t('Owl Icon')}
          src={OwlIcon}
        />
        <PageHeading>{t('Updating your Owlet Sock...')}</PageHeading>
        <Body>{t('This can take some time.')}</Body>
        <br />
        <Body>{t('Do not unplug the Sock or Base Station while updating.')}</Body>
        <LoaderContainer>
          <Loader />
        </LoaderContainer>
      </Content>
    </Layout>
  );
};

export default CheckOTAUpdates;
