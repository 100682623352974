import { useParams } from 'react-router-dom';
import ChildProfile from './ChildProfile';

const ChildProfileWrapper = () => {
  const { serviceUserKey } = useParams();

  return (
    <ChildProfile serviceUserKey={`${serviceUserKey}`} showBackButton />
  );
};

export default ChildProfileWrapper;
