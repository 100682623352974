import {
  useEffect, useLayoutEffect,
  useRef, useState,
} from 'react';
import FormLabel from '@mui/material/FormLabel';
import { makeStyles } from '@mui/styles';
import { Input } from '@mui/material';
import Select from '@mui/material/Select';
import { MenuItem } from '@material-ui/core';
import InputLabel from '@mui/material/InputLabel';
import FormControl from '@mui/material/FormControl';
import { useParams } from 'react-router-dom';
import { LocalizationProvider } from '@mui/lab';
import AdapterDateFns from '@mui/lab/AdapterDateFns';
import { useNavigate } from 'react-router';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import BottomTextAndArrow from '../../../components/FormComponents/BottomTextAndArrow';
import CheckboxForm from '../../../components/FormComponents/CheckboxForm';
import UseFirebasePostDataHook from '../../../../backend/utils/useFirebasePostDataHook';
import { emptyServiceUser } from '../../../../shared/types';
import { accountCollection } from '../../../navigation/routes';
import DateChooser from '../../../components/FormComponents/DateChooser';
import { useServiceUserContext } from '../../../../backend/contexts/serviceUserContext';
import {
  genderOptions,
  relationshipOptions,
  nicuStayOptions,
  allRaces,
} from '../../../../shared/constants';
import NavHeader from '../../../components/NavHeader/NavHeader';
import { locales } from '../../../../backend/config/i18n';
import { convertToBool, convertToString } from '../../../../backend/utils/helpers';
import theme from '../../../design/theme';
import ErrorBanner from '../../../components/ErrorBanner';

const DialogGrid = styled.div`
  overflow-y: scroll;
`;

const PageContents = styled.div`
  padding: 20px;
`;

const AboutChildArea = styled.div`
  margin-top: 20px;
`;

const RaceSelectionArea = styled.div`
  width: 100%;
  margin-top: 60px;
`;

const NextButtonArea = styled.div`
  width: 95%;
  padding: 20px 0;
`;

const DeleteButton = styled.div`
  margin-top: 30px;
  color: ${theme.color.tertiary.default};
  width: 100%;
  height: 100%;
`;

const UpdateChildProfile = () => {
  const navigate = useNavigate();
  const { accountKey, serviceUserKey } = useParams();

  const { findServiceUser, removeServiceUser } = useServiceUserContext();
  const serviceUserData = findServiceUser(`${serviceUserKey}`) || emptyServiceUser;

  const {
    birthDate,
    dueDate,
    firstName,
    gender,
    nicuStay,
    races,
    relationship,
  } = serviceUserData;

  const [babyFirstName, setBabyFirstName] = useState(firstName);
  const [race, setRace] = useState<string[]>([]);
  const [childBirthDate, setChildBirthDate] = useState<number | undefined | null>(birthDate);
  const [genderValue, setGenderValue] = useState(gender || 'Unspecified');
  const [relationshipValue, setRelationshipValue] = useState(relationship || 'Unspecified');
  const [childDueDate, setChildDueDate] = useState<number | undefined>(dueDate);
  const [nicuStayValue, setNicuStayValue] = useState<string | null>(convertToString(nicuStay));
  const [errorMessage, setErrorMessage] = useState<string | null>(null);
  const containerDiv = useRef<HTMLInputElement | null>(null);
  const { t, i18n } = useTranslation();

  useEffect(() => {
    setBabyFirstName(firstName);
    setRace(races || []);
    setChildBirthDate(birthDate || null);
    setGenderValue(gender || 'Unspecified');
    setRelationshipValue(relationship || 'Unspecified');
    setChildDueDate(dueDate || undefined);
    setNicuStayValue(convertToString(nicuStay));
  }, [birthDate, gender, nicuStay, relationship, dueDate, races, firstName]);

  const deleteProfile = async () => {
    await UseFirebasePostDataHook('serviceUsers', serviceUserKey || '', null).then(() => {
      removeServiceUser(`${serviceUserKey}`);
      navigate(`${accountCollection()}/account-info`);
    }).catch((error) => {
      handleNetworkError(error);
    });
  };

  const saveForm = async () => {
    const updateFormData = {
      accountKey,
      firstName: babyFirstName,
      birthDate: childBirthDate,
      dueDate: childDueDate,
      gender: genderValue === t('Unspecified') ? null : genderValue,
      nicuStay: convertToBool(nicuStayValue),
      races: race,
      id: serviceUserKey,
      relationship: relationshipValue === t('Unspecified') ? null : relationshipValue,
      type: 'child',
    };

    await UseFirebasePostDataHook(
      'serviceUsers', serviceUserKey || '', updateFormData,
    ).then(() => {
      navigate(`${accountCollection()}/account-info`);
    }).catch((error) => {
      handleNetworkError(error);
    });
  };

  const handleNetworkError = (error: any) => {
    setErrorMessage(t('There was an unexpected error. Please try again.'));
    console.log(error);
  };

  useLayoutEffect(() => {
    if (errorMessage) {
      containerDiv.current?.scrollIntoView({
        behavior: 'smooth',
      });
    }
  }, [errorMessage]);

  const useStyles = makeStyles({
    inputLabel: {
      textTransform: 'uppercase',
      color: '#D4D4F9 !important',
      '&.Mui-focused': {
        color: '#D4D4F9 !important',
      },
    },
    localizationProvider: {
      color: '#D4D4F9 !important',
    },
    textField: {
      color: '#D4D4F9 !important',
    },
    datePickerStyles: {
      '& label': {
        color: '#D4D4F9',
      },
      '& label.Mui-focused': {
        color: '#D4D4F9',
      },
      '& .MuiInput-root': {
        color: '#D4D4F9',
      },
      '& .MuiInput-underline': {
        borderBottomColor: '#D4D4F9',
      },
      '& .MuiInput-underline:before': {
        borderBottomColor: '#D4D4F9',
      },
      '& .MuiInput-underline:after': {
        borderBottomColor: '#D4D4F9',
      },
    },
    underline: {
      '&:after': {
        color: '#D4D4F9 !important',
        borderBottom: '1px solid #D4D4F9 !important',
      },
      '&:before': {
        borderBottom: '1px solid #D4D4F9 !important',
      },
    },
    select: {
      color: '#D4D4F9 !important',
      '& .MuiSvgIcon-root': {
        color: '#D4D4F9 !important',
      },
      '&:before': {
        borderColor: '#D4D4F9 !important',
      },
      '&:after': {
        borderColor: '#D4D4F9 !important',
        color: '#D4D4F9 !important',
      },
    },
    date: {
      '& .MuiInputBase-root-MuiOutlinedInput-root': {
        borderColor: '#D4D4F9 !important',
        color: '#D4D4F9 !important',
      },
      '& .MuiOutlinedInput-notchedOutline': {
        borderColor: '#D4D4F9 !important',
        color: '#D4D4F9 !important',
      },
      '&:before': {
        borderColor: '#D4D4F9 !important',
      },
      '&:after': {
        borderColor: '#D4D4F9 !important',
        color: '#D4D4F9 !important',
      },
    },
  });

  const classes = useStyles();

  return (
    <LocalizationProvider dateAdapter={AdapterDateFns} locale={locales[i18n.language]}>
      <DialogGrid>
        {/* eslint-disable-next-line object-shorthand */}
        <NavHeader title={t('{{firstName}}\'s Profile', { firstName: firstName })} backButton />
        <PageContents ref={containerDiv}>
          <AboutChildArea>
            {errorMessage
              && (
                <ErrorBanner
                  onClose={() => {
                    setErrorMessage(null);
                  }}
                  message={errorMessage}
                />
              )}
            <FormControl variant="standard" style={inputStyle}>
              <InputLabel
                id="first-name"
                className={classes.inputLabel}
              >
                {t('First Name')}
              </InputLabel>
              <Input
                name="first-name"
                value={babyFirstName}
                className={[classes.underline, classes.textField].join(' ')}
                onChange={(e) => setBabyFirstName(e.target.value)}
              />
            </FormControl>
            <DateChooser dateValue={childBirthDate} label={t('Birth Date')} setter={setChildBirthDate} />
            <FormControl variant="standard" style={inputStyle}>
              <InputLabel id="gender-value" className={classes.inputLabel}>{t('Enter gender')}</InputLabel>
              <Select
                id="gender-value"
                label="Enter gender"
                onChange={(e) => setGenderValue(e.target.value)}
                className={classes.select}
                value={genderValue}
              >
                {genderOptions.map((option) => (
                  <MenuItem
                    key={option.value}
                    value={option.value}
                  >
                    {t(option.text)}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
            <FormControl variant="standard" style={inputStyle}>
              <InputLabel id="relationship-value" className={classes.inputLabel}>{t('Enter relationship')}</InputLabel>
              <Select
                id="relationship-value"
                label={t('Enter relationship')}
                onChange={(e) => setRelationshipValue(e.target.value)}
                className={classes.select}
                value={relationshipValue}
              >
                {relationshipOptions.map((option) => (
                  <MenuItem
                    key={option.value}
                    value={option.value}
                  >
                    {t(option.text)}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
            <DateChooser dateValue={childDueDate} label={t('Due Date')} setter={setChildDueDate} />
            <FormControl variant="standard" style={inputStyle}>
              <InputLabel id="nicu-stay-value" className={classes.inputLabel}>{t('NICU Stay?')}</InputLabel>
              <Select
                id="nicu-stay-value"
                label="NICU Stay?"
                onChange={(e) => setNicuStayValue(e.target.value)}
                className={classes.select}
                value={nicuStayValue}
              >
                {nicuStayOptions.map((option) => (
                  <MenuItem
                    key={option.value}
                    value={option.value}
                  >
                    {t(option.text)}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </AboutChildArea>
          <RaceSelectionArea>
            <FormLabel style={{ color: theme.color.text.darkMode.primary }} component="legend">{t('Race(optional)')}</FormLabel>
            <CheckboxForm
              allData={allRaces}
              selectedData={race}
              setSelectedData={setRace}
            />
            <DeleteButton
              onKeyDown={() => deleteProfile()}
              onClick={() => deleteProfile()}
              role="button"
              tabIndex={0}
            >
              {t('Delete Child Profile')}
            </DeleteButton>
          </RaceSelectionArea>
          <NextButtonArea
            onKeyDown={() => saveForm()}
            role="button"
            tabIndex={0}
          >
            <BottomTextAndArrow
              buttonText={t('Save')}
              onClick={() => saveForm()}
            />
          </NextButtonArea>
        </PageContents>
      </DialogGrid>
    </LocalizationProvider>
  );
};

const inputStyle = {
  width: '85%',
  marginTop: '40px',
};

export default UpdateChildProfile;
