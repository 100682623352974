import styled from 'styled-components';
import { useState } from 'react';
import { Link } from 'react-router-dom';
import { useNavigate, useParams } from 'react-router';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import { AddCircleOutlineOutlined } from '@mui/icons-material';
import DeleteIcon from '@mui/icons-material/Delete';
import Stack from '@mui/material/Stack';
import { useTranslation } from 'react-i18next';
import LaunchIcon from '@mui/icons-material/Launch';
import { Caption, PageTitle, Heading } from '../../components/Typography';
import theme from '../../design/theme';
import { useDeviceContext } from '../../../backend/contexts/deviceContext';
import NavHeader from '../../components/NavHeader/NavHeader';
import DeviceImage from '../../assets/device_sock3.png';
import { useServiceUserContext } from '../../../backend/contexts/serviceUserContext';
import { useDeviceSetupContext } from '../../../backend/contexts/deviceSetupContext';
import {
  DeviceContainer,
  ExternalListItem,
  PageContents,
  ImageContainer,
  Underline100,
  RemoveContainer,
  DeleteText,
  StyledCaption,
} from './DeviceDetail.styles';
import ChildList from './ChildList';
import {
  emptyDevice, emptyService, emptyServiceUser, deviceOptions,
} from '../../../shared/types';
import { useAylaContext } from '../../../backend/contexts/aylaContext';
import { removeDevice } from '../../../backend/utils/owletApi';
import { useAuthContext } from '../../../backend/contexts/authContext';
import { accountInfo, childIsBorn } from '../../navigation/routes';
import { getDevice, unregisterDevice } from '../../../backend/utils/aylaApi';
import { useServiceContext } from '../../../backend/contexts/serviceContext';
import PageDoesNotExist from '../404/PageDoesNotExist';

const AddButton = styled(Link)`
  display: flex;
  align-items: center;
  color: ${theme.color.primary.default};
  margin-left: 0vw;
  padding: 8px 0;
`;

const LinkText = styled(PageTitle)`
  margin-left: 6px;
  color: ${theme.color.primary.default};
  font-weight: 500;
`;

const DeviceDetail = () => {
  const { deviceKey = '', serviceKey } = useParams();
  const [deleted, setDeleted] = useState(false);
  const navigate = useNavigate();
  const { aylaToken, refreshDevices } = useAylaContext();
  const { firebaseToken } = useAuthContext();
  const { findDevice, status } = useDeviceContext();
  const { findServiceByDeviceKey, removeService } = useServiceContext();
  const deviceData = findDevice(`${deviceKey}`) || emptyDevice;
  const { type, dsn } = deviceData;
  const { serviceUserKeys } = findServiceByDeviceKey(deviceKey) || emptyService;
  const { findServiceUser } = useServiceUserContext();
  const serviceUserData = findServiceUser(Object.keys(serviceUserKeys)[0]) || emptyServiceUser;
  const { firstName } = serviceUserData;
  const { serviceCreated } = useDeviceSetupContext();
  const dataSuccess = status === 'success';
  const { t } = useTranslation();

  const deleteDevice = async () => {
    if (firebaseToken && aylaToken) {
      const { data } = await getDevice(`${aylaToken}`, dsn);
      const { device: { id } } = data;

      await removeDevice(firebaseToken, dsn);
      await unregisterDevice(`${aylaToken}`, id).then(() => {
        refreshDevices();
      });

      const service = findServiceByDeviceKey(id);
      if (service) {
        setDeleted(true);
        removeService(service.id);
      }
      navigate(accountInfo());
    }
  };

  if (!deleted && (!dataSuccess || (dataSuccess && deviceData === emptyDevice))) {
    return <PageDoesNotExist />;
  }

  const backNavigation = () => {
    if (serviceCreated) {
      navigate(accountInfo());
    } else {
      navigate(-1);
    }
  };

  return (
    <DeviceContainer>
      {dataSuccess && (
        <>
          <NavHeader title={`${deviceOptions[type]} (${firstName || t('Unassigned')})`} backButton goBackFn={() => backNavigation()} />
          <PageContents>
            <List>
              <ImageContainer>
                <img src={DeviceImage} width={140} height={120} alt={t('Device')} />
              </ImageContainer>
              <Underline100 />
              <StyledCaption>{t('Device Assignment')}</StyledCaption>
              <ChildList
                dsn={dsn}
                serviceKey={`${serviceKey}`}
              />
              <AddButton
                to={childIsBorn()}
              >
                <AddCircleOutlineOutlined fontSize="small" />
                <LinkText>{t('Add Child')}</LinkText>
              </AddButton>
              <Underline100 />
              <StyledCaption>{t('Device Settings')}</StyledCaption>
              <ExternalListItem
                target="_blank"
                rel="noopener noreferrer"
                href="https://support.owletcare.com/hc/en-us/articles/4407225426445-Smart-Sock-3-How-to-reset-WiFi"
              >
                {t('Change Wi-Fi')}
                <LaunchIcon />
              </ExternalListItem>
              <Caption>{t('For if you want to use your base station in a new location.')}</Caption>
              <Underline100 />
              <StyledCaption>{t('Device Info')}</StyledCaption>
              <Heading>{t('Base Station Identifier')}</Heading>
              <Caption>{dsn}</Caption>
              <Underline100 />
              <ListItem sx={{ justifyContent: 'center' }}>
                <RemoveContainer onClick={() => deleteDevice()}>
                  <Stack direction="row" alignItems="center" gap={1}>
                    <DeleteIcon
                      fontSize="inherit"
                      style={{ fontSize: '24px' }}
                    />
                    <DeleteText>{t('Remove Device')}</DeleteText>
                  </Stack>
                </RemoveContainer>
              </ListItem>
            </List>
          </PageContents>
        </>
      )}
    </DeviceContainer>
  );
};

export default DeviceDetail;
