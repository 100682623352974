import { SockBattery, PasswordStrength } from '../../shared/constants';
import { DeviceConnectionStatus } from '../../shared/types';
import theme from '../../ui/design/theme';
import userAgent from './userAgent';

const dateResolver = (date?: number | null | undefined) => {
  if (date) {
    const ndate = new Date(date * 1000);
    return (ndate.getTime() + (ndate.getTimezoneOffset() * 60000));
  }
  return null;
};

const isDate = (date?: Date | number | null) => {
  if (date) {
    const ndate = new Date(date);
    return (ndate.getTime() - (ndate.getTimezoneOffset() * 60000)) / 1000;
  }
  return date ?? undefined;
};

const convertToBool = (value?: string | null) => {
  if (value === 'true') {
    return true;
  }
  if (value === 'false') {
    return false;
  }
  return null;
};

const convertToString = (value?: boolean | null) => {
  if (value === true) {
    return 'true';
  }
  if (value === false) {
    return 'false';
  }
  return 'Unspecified';
};

const determineBatteryIcon = (
  batteryPercentage: number | undefined, chargingStatus: number | undefined,
) => {
  if (chargingStatus === 1 || (chargingStatus === 2 && batteryPercentage === 100)) {
    return SockBattery.CHARGING;
  }
  if (chargingStatus === undefined || batteryPercentage === undefined || typeof batteryPercentage !== 'number') {
    return SockBattery.DISABLED;
  }
  if (batteryPercentage >= 75) {
    return SockBattery.FULL;
  }
  if (batteryPercentage >= 60) {
    return SockBattery.HIGH;
  }
  if (batteryPercentage >= 45) {
    return SockBattery.MEDIUMHIGH;
  }
  if (batteryPercentage >= 30) {
    return SockBattery.MEDIUM;
  }
  if (batteryPercentage >= 15) {
    return SockBattery.LOW;
  }
  return SockBattery.CRITICAL;
};

const determinePasswordStrength = (passwordStrength: number) => {
  switch (passwordStrength) {
    case 4:
      return PasswordStrength.Strongest;
    case 3:
      return PasswordStrength.Stronger;
    case 2:
      return PasswordStrength.Strong;
    case 1:
      return PasswordStrength.Weak;
    default:
      return PasswordStrength.Weakest;
  }
};

const baseStationColor = (bso: number | undefined) => {
  if (bso === 0) {
    return theme.color.text.darkMode.primary;
  }
  if (bso === 1) {
    return theme.color.primary.default;
  }

  return theme.color.primary.darkGray;
};

const wifiColor = (connectionStatus: string | undefined) => {
  if (connectionStatus === DeviceConnectionStatus.Connected) {
    return theme.color.primary.default;
  }

  return theme.color.primary.darkGray;
};

const appVersion = () => (
  process.env.REACT_APP_VERSION
);

const osName = () => (userAgent().getOS().name);
const osVersion = () => (userAgent().getOS().version);
const appDeviceModel = () => (userAgent().getDevice().model);

export {
  appDeviceModel,
  appVersion,
  convertToBool,
  convertToString,
  dateResolver,
  determineBatteryIcon,
  determinePasswordStrength,
  isDate,
  baseStationColor,
  osName,
  osVersion,
  wifiColor,
};
