import { useEffect } from 'react';
import { getAuth, sendEmailVerification } from 'firebase/auth';
import { useNavigate } from 'react-router-dom';
import styled from 'styled-components';
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import { useTranslation } from 'react-i18next';
import { PageHeading, PageTitle, Body } from '../../components/Typography';
import MaterialButton from '../../components/Button/MaterialButton';
import { details, root } from '../../navigation/routes';
import theme from '../../design/theme';
import { useAuthContext } from '../../../backend/contexts/authContext';

const Container = styled.div`
  display: flex;
  background-color: ${theme.color.background.darkMode.darker};
  overflow: scroll;
  flex-direction: column;
  padding: 20px;
  height: 100vh;
`;

const BackArrowStyle = {
  fontSize: '15px',
  color: '#D4D4F9',
  zIndex: '1',
};

const ConfirmationEmail = () => {
  const navigate = useNavigate();
  const { currentUser, setUser } = useAuthContext();
  const { t } = useTranslation();

  useEffect(() => {
    if (currentUser?.emailVerified) {
      navigate(details());
    }
  }, [currentUser, navigate]);

  useEffect(() => {
    const reloadInterval = setInterval(() => {
      currentUser?.reload().then((_) => {
        const user = getAuth().currentUser;
        if (user?.emailVerified) {
          setUser({ ...user });
        }
      });
    }, 5000);

    return () => clearInterval(reloadInterval);
  }, [currentUser, setUser]);

  const resendConfirmationEmail = () => {
    if (currentUser) {
      sendEmailVerification(currentUser)
        .then(() => {
          alert(t('Confirmation email has been resent'));
        })
        .catch((error) => {
          console.log(error);
        });
    }
  };

  const signOut = () => {
    getAuth().signOut().then(() => {
      navigate(root());
    }).catch((error) => {
      console.error(error);
    });
  };

  return (
    <Container>
      <div>
        <ArrowBackIosIcon
          style={BackArrowStyle}
          onClick={() => signOut()}
        />
      </div>
      <br />
      <PageHeading>{t('An email with a confirmation code has just been sent to...')}</PageHeading>
      <br />
      <PageTitle>
        {currentUser?.email}
      </PageTitle>
      <br />
      <Body>
        {t('Follow the directions in the email to confirm your account.')}
      </Body>
      <br />
      <MaterialButton
        buttonText={t('Resend Confirmation Email')}
        color={theme.color.primary.dark}
        onClick={() => resendConfirmationEmail()}
      />
    </Container>
  );
};

export default ConfirmationEmail;
