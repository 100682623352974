import styled from 'styled-components';
import { useTranslation } from 'react-i18next';
import { Body, PageHeading } from '../../components/Typography';
import BackButton from '../../components/Button/BackButton';
import Button from '../../components/Button/Button';
import theme from '../../design/theme';
import HappyOwl from '../../assets/owl_happy.svg';
import { registerDevice } from './routes';

const Layout = styled.div`
  display: flex;
  flex-direction: column;
  position: relative;
  `;

const OwlImage = styled.img`
  margin: 32px;
  width: 120px;
  height: 120px;
  display: flex;
  align-self: center;
  `;

const Content = styled.div`
  display: flex;
  flex-direction: column;
  padding: 20px;
`;

const ButtonContainer = styled.div`
  position: fixed;
  width: 90%;
  bottom: 0;
  padding: 40px 20px;
  background-color: ${theme.color.background.darkMode.darker};
`;

const DeviceNotFound = () => {
  const { t } = useTranslation();
  return (
    <Layout>
      <BackButton />
      <Content>
        <OwlImage src={HappyOwl} alt="Owl image" />
        <PageHeading>{t('Can’t find device…')}</PageHeading>
        <br />
        <Body>{t('Registering the base station can require multiple attempts..')}</Body>
        <br />
        <Body>
          {t('Make sure your phone is on the same Wi-Fi network as the base station, and try again.')}
        </Body>
      </Content>
      <ButtonContainer>
        <Button label={t('Try again')} to={registerDevice()} />
      </ButtonContainer>
    </Layout>
  );
};

export default DeviceNotFound;
