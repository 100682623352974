import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import { useTranslation } from 'react-i18next';
import AddChildRow from '../components/AddChildRow';
import ChildProfileRow from '../components/ChildProfileRow';
import baby from '../../../assets/baby-icon.jpg';

const childProfiles = [
  { img: baby, name: 'Oliver' },
  { img: baby, name: 'Moose' },
];

const goBack = () => {
  window.history.back();
};

const dialogGrid = {
  display: 'grid',
  gridTemplateColumns: '50px 80vw 25px',
  gridTemplateRows: '20px 15vh min-content 10vh 10vh',
};

const backArrowArea = {
  gridColumn: '1',
  gridRow: '1',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'flex-start',
  marginTop: '15px',
};

const backArrowStyle = {
  fontSize: '24px',
  color: '#D4D4F9',
};

const addChildArea = {
  gridColumn: '1 / 5',
  gridRow: '5',
};

const titleArea = {
  gridColumn: '2 / 5',
  gridRow: '2',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
};

const titleStyle = {
  fontFamily: 'Source Sans Pro',
  color: '#D4D4F9',
  fontSize: '26px',
  fontStyle: 'normal',
  fontWeight: '600',
  lineHeight: '32px',
  letterSpacing: '0px',
};

const childProfileRowsArea = {
  gridColumn: '2 / 4',
  gridRow: '3',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  width: '100%',
  overflowY: 'scroll' as const,
};

const childProfileRowsStyle = {
  width: '100%',
};
const ChildProfile = () => {
  const { t } = useTranslation();
  return (
    <div style={dialogGrid}>
      <div style={backArrowArea}>
        <ArrowBackIosIcon
          style={backArrowStyle}
          onClick={() => goBack()}
        />
      </div>
      <div style={titleArea}>
        <div style={titleStyle}>
          {t('Please select who will be using this Smart Sock.')}
        </div>
      </div>
      <div style={childProfileRowsArea}>
        <div style={childProfileRowsStyle}>
          {childProfiles.map(({ img, name }) => (
            <ChildProfileRow
              img={img}
              name={name}
              key={name}
            />
          ))}
        </div>
      </div>
      <div style={addChildArea}>
        <AddChildRow />
      </div>
    </div>
  );
};

export default ChildProfile;
