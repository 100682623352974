import { Checkbox, FormControlLabel, FormGroup } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { translationKeyType } from '../../../shared/react-i18next.d';
import theme from '../../design/theme';

type CheckboxFormProps = {
  allData: {text: translationKeyType, value: string}[];
  selectedData: string[];
  setSelectedData: (value: string[]) => void;
}

const CheckboxForm = ({
  allData, selectedData = [], setSelectedData,
}: CheckboxFormProps) => {
  const handleCheckboxChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { target } = e;

    let newSelectedData: string[] = [];
    if (selectedData?.includes(target.value)) {
      newSelectedData = selectedData.filter(
        (option: string) => option !== target.value,
      );
    }

    if (!selectedData.includes(target.value)) {
      newSelectedData = [...selectedData, target.value];
    }
    setSelectedData(newSelectedData);
  };
  const { t } = useTranslation();

  return (
    <FormGroup
      style={{
        color: theme.color.text.darkMode.primary,
        paddingRight: '15px',
      }}
    >
      {allData.map((option) => (
        <FormControlLabel
          key={option.text}
          value={option.value}
          control={(
            <Checkbox
              id={option.text}
              checked={selectedData.includes(option.value)}
              style={{
                color: theme.color.text.darkMode.primary,
                paddingRight: '15px',
              }}
              name={option.text}
              onChange={handleCheckboxChange}
            />
          )}
          label={t(option.text)}
          sx={{
            height: '42px',
          }}
        />
      ))}
    </FormGroup>
  );
};

export default CheckboxForm;
