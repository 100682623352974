import styled from 'styled-components';
import { useTranslation } from 'react-i18next';
import { Body, PageHeading } from '../../components/Typography';
import BackButton from '../../components/Button/BackButton';
import Button from '../../components/Button/Button';
import theme from '../../design/theme';
import HappyOwl from '../../assets/owl_happy.svg';
import { searchSock } from './routes';

const Layout = styled.div`
  display: flex;
  flex-direction: column;
  position: relative;
  `;

const OwlImage = styled.img`
  margin: 32px;
  width: 120px;
  height: 120px;
  display: flex;
  align-self: center;
  `;

const Content = styled.div`
  display: flex;
  flex-direction: column;
  padding: 20px;
`;

const BottomRow = styled.div`
  position: fixed;
  width: 90%;
  bottom: 0;
  padding: 40px 20px;
  background-color: ${theme.color.background.darkMode.darker};
`;

const PairFailed = () => {
  const { t } = useTranslation();

  return (
    <Layout>
      <BackButton />
      <Content>
        <OwlImage src={HappyOwl} alt="Owl image" />
        <PageHeading>{t('Sock Pairing failed')}</PageHeading>
        <br />
        <Body>
          {t(`Make sure the base station charging port is clean
          and that the sock is inserted evenly.`)}
        </Body>
        <br />
        <Body>{t('If it still doesnt work, chat with customer support.')}</Body>
      </Content>
      <BottomRow>
        <Button label={t('Try again')} to={searchSock()} />
      </BottomRow>
    </Layout>
  );
};

export default PairFailed;
