export const FIREBASE_APP_ID = '1:704296504428:web:65b1ee09d68b1279105c49';
export const FIREBASE_API_KEY = 'AIzaSyBjaCDe5Bo3qDv_8BjAdwiA17wHzpxdu8I';
export const FIREBASE_PROJECT_ID = 'owletcare-dev';
export const FIREBASE_AUTH_DOMAIN = `${FIREBASE_PROJECT_ID}.firebaseapp.com`;
export const FIREBASE_DATABASE_URL = `https://${FIREBASE_PROJECT_ID}.firebaseio.com`;

export const OWLET_API_DOMAIN = 'dev.owletdata.com';
export const AYLA_ENV = 'owldev';
export const AYLA_APP_ID = 'owa-lg-id';
export const AYLA_APP_SECRET = 'owa-mFxtCB1g2TAuVxQJoYGdJNdM7wU';

export const ENABLE_DD_RUM = false;
export const ENABLE_DD_RUM_SESSION_REPLAY = false;

export const FEATURE_FLAG_API_KEY = 'mui0rdr6paacvfa4o4np3j7mscjq1nlgsc9d';

export const NPS_APP_ID = 'app_JVC8qLLQDPdbOIQgWHvofvLcLsCsDEG7xOJ8T0t7AUc';
export const NPS_API_KEY = 'prod_OIfqJvgDZpz82YhaueD1YpFt2fxy7STYGqigqwzHlBU';
export const NPS_USE_PROD = true;
