import React from 'react';
import PhoneInput from 'react-phone-input-2';
import {
  isPossiblePhoneNumber,
  isValidPhoneNumber,
  validatePhoneNumberLength,
} from 'libphonenumber-js';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';
import theme from '../../design/theme';
import 'react-phone-input-2/lib/style.css';

type PhoneProps = {
  isDetails?: boolean
  phoneNumber: string | null | undefined
  setPhoneNumber: (val: string) => void
}

const Phone = ({ isDetails, phoneNumber, setPhoneNumber }: PhoneProps) => {
  const { t } = useTranslation();
  return (
    <PhoneInput
      specialLabel={t('Phone Number')}
      value={phoneNumber}
      isValid={(value?, country?) => {
        // need both comments because typing is incorrect in libphonenumber-js
        if (
          // eslint-disable-next-line @typescript-eslint/ban-ts-comment
          // @ts-ignore: Property 'iso2' does not exist on type 'object'.
          isPossiblePhoneNumber(value, country.iso2.toUpperCase())
          // eslint-disable-next-line @typescript-eslint/ban-ts-comment
          // @ts-ignore: Property 'iso2' does not exist on type 'object'.
          && isValidPhoneNumber(value, country.iso2.toUpperCase())
          // eslint-disable-next-line @typescript-eslint/ban-ts-comment
          // @ts-ignore: Property 'iso2' does not exist on type 'object'.
          && validatePhoneNumberLength(value, country.iso2.toUpperCase())) {
          return true;
        }
        return false;
      }}
      country="us"
      onChange={(value: string) => setPhoneNumber(value)}
      inputStyle={isDetails ? inputStyle : userInfoInputStyle}
      buttonStyle={isDetails ? buttonStyle : userInfoButtonStyle}
    />
  );
};

const inputStyle = {
  backgroundColor: theme.color.background.darkMode.darker,
  color: theme.color.text.darkMode.primary,
  border: 0,
  borderBottom: 'solid 1.5px #D4D4F9',
  width: '100%',
  fontSize: '1rem',
};

const buttonStyle = {
  backgroundColor: theme.color.background.darkMode.darker,
  border: 0,
  color: 'black',
};

const userInfoInputStyle = {
  backgroundColor: theme.color.background.darkMode.account,
  color: theme.color.text.darkMode.primary,
  border: 0,
  borderBottom: 'solid 1.5px #D4D4F9',
  width: '100%',
  fontSize: '1rem',
};

const userInfoButtonStyle = {
  backgroundColor: theme.color.background.darkMode.account,
  border: 0,
  color: 'black',
};

export default Phone;
