import { FeatureFlags, FeatureFlagTreatments } from '../../shared/constants';
import { useFeatureFlagContext } from '../contexts/featureFlags';

const useFeatureFlag = (flag: FeatureFlags): boolean => {
  const { featureFlagClient } = useFeatureFlagContext();
  if (!featureFlagClient) {
    return false;
  }

  const treatment = featureFlagClient?.getTreatment(flag);
  return treatment === FeatureFlagTreatments.ON;
};

export default useFeatureFlag;
