import { useCallback, useEffect, useState } from 'react';
import Leanplum, { Inbox, InboxMessage } from 'leanplum-sdk';
import NPSContext from './context';
import { useAccountContext } from '../accountContext';
import { getConfigSetting } from '../../config/URLConfig';
import { ConfigVarName } from '../../../shared/types';
import useFeatureFlag from '../../hooks/useFeatureFlag';
import { FeatureFlags, NPS_SURVEY_TITLE } from '../../../shared/constants';
import useLogout from '../../hooks/useLogout';

type NPSProviderProps = { children: JSX.Element }

const NPSProvider = ({ children }: NPSProviderProps) => {
  const { accountUser: { email: userEmail } } = useAccountContext();
  const [inbox, setInbox] = useState<Inbox>();
  const [messages, setMessages] = useState<InboxMessage[]>([]);
  const [showSurvey, setShowSurvey] = useState(false);
  const [showMinimized, setShowMinimized] = useState(false);
  const readAll = useCallback(() => {
    messages.forEach((msg) => {
      inbox?.read(msg.id());
    });
    setShowSurvey(false);
    setShowMinimized(false);
  }, [inbox, messages]);

  const markAllAsRead = useCallback(() => {
    messages.forEach((msg) => {
      inbox?.markAsRead(msg.id());
    });
    setShowSurvey(false);
    setShowMinimized(false);
  }, [inbox, messages, setShowSurvey]);

  const minimizeSurvey = useCallback(() => {
    setShowMinimized(true);
    setShowSurvey(false);
  }, [setShowMinimized]);

  const maximizeSurvey = useCallback(() => {
    setShowMinimized(false);
    setShowSurvey(true);
  }, [setShowMinimized]);

  useEffect(() => {
    const APP_ID = getConfigSetting(ConfigVarName.NPS_APP_ID);
    const API_KEY = getConfigSetting(ConfigVarName.NPS_API_KEY);
    const useProd = getConfigSetting(ConfigVarName.NPS_USE_PROD);
    if (useProd) {
      Leanplum.setAppIdForProductionMode(APP_ID, API_KEY);
    } else {
      Leanplum.setAppIdForDevelopmentMode(APP_ID, API_KEY);
    }
  }, []);

  useEffect(() => {
    const fetchMessages = () => {
      const lpInbox = Leanplum.inbox();
      setInbox(lpInbox);
      const msgs = lpInbox.unreadMessages();
      const unreadMessages = msgs.filter(
        (msg) => msg.title() === NPS_SURVEY_TITLE,
      );

      setMessages(unreadMessages);

      if (unreadMessages.length > 0) {
        setShowSurvey(true);
      }
    };

    if (userEmail) {
      Leanplum.addStartResponseHandler(fetchMessages);
      Leanplum.inbox().onChanged(fetchMessages);
      Leanplum.start(userEmail, () => {
        Leanplum.inbox().downloadMessages();
      });
    }
  }, [userEmail]);

  const onLogout = useCallback(() => {
    setInbox(undefined);
    setMessages([]);
    setShowSurvey(false);
    setShowMinimized(false);
  }, []);

  useLogout(onLogout);

  return (
    <NPSContext.Provider value={{
      markAllAsRead,
      messages,
      readAll,
      showSurvey,
      showMinimized,
      minimizeSurvey,
      maximizeSurvey,
    }}
    >
      {children}
    </NPSContext.Provider>
  );
};

const NPSProviderWrapper = ({ children }: NPSProviderProps) => {
  const useNPS = useFeatureFlag(FeatureFlags.NPS_SURVEY);

  return useNPS ? (
    <NPSProvider>{children}</NPSProvider>
  ) : children;
};

export default NPSProviderWrapper;
