import styled from 'styled-components';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import { useEffect } from 'react';
import { Body, PageHeading } from '../../components/Typography';
import theme from '../../design/theme';
import BottomTextAndArrow from '../../components/FormComponents/BottomTextAndArrow';
import TutorialImage from '../../assets/tutorial_hero_readings.png';
import NavHeader from '../../components/NavHeader/NavHeader';
import { Icon } from '../../components/Icon';
import { home } from '../../navigation/routes';

const Layout = styled.div`
  display: flex;
  flex-direction: column;
  `;

const Content = styled.div`
  display: flex;
  flex-direction: column;
  padding: 20px;
`;

const ImageContainer = styled.div`
  background-image: url(${TutorialImage});
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-end;
  min-height: 330px;
  text-align: center;
  padding: 0 20px;
`;

const ButtonContainer = styled.div`
  width: 90%;
  padding: 40px 20px;
  background-color: ${theme.color.background.darkMode.darker};
`;

const PageNumber = styled.div`
  display: flex;
  padding: 20px;
  align-items: center;
`;

const Line = styled.div`
  width: 50px;
  height: 1px;
  background-color: ${theme.color.text.darkMode.primary};
  margin: 0 10px;
`;

const StatusContainer = styled.div`
  display: grid;
  grid-auto-flow: column;
  gap: 60px;
  align-items: center;
  justify-content: center;
  padding: 20px 0;
`;

const ListItem = styled.div`
  display: flex;
  align-items: center;
  padding: 10px 0;
  margin-top: 10px;
`;

const StyledBody = styled(Body)`
  margin-left: 10px;
  font-weight: 600;
  width: 80%;
`;

const Button = styled(Link)`
  padding: 16px;
`;

const TutorialReadings = () => {
  const { t } = useTranslation();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const SkipButton = () => (
    <Button to={home()}>
      <Body>{t('Skip')}</Body>
    </Button>
  );

  return (
    <Layout>
      <NavHeader title={t('Sock Tutorial')} backButton backgroundDark rightButton={SkipButton()} />
      <ImageContainer>
        <PageHeading>{t('Understanding the Readings')}</PageHeading>
        <PageNumber>
          <Line />
          <Body>3 / 3</Body>
          <Line />
        </PageNumber>
      </ImageContainer>
      <Content>
        <Body>{t('The Owlet Sock has three main readings: baby’s oxygen level, heart rate, and movement.')}</Body>
        <br />
        <StatusContainer>
          <Icon icon="StatusOxygen" width={48} height={48} fill={theme.color.primary.default} />
          <Icon icon="StatusHeartrate" width={48} height={48} fill={theme.color.primary.default} />
          <Icon icon="Movement" width={48} height={48} fill={theme.color.primary.default} />
        </StatusContainer>
        <ListItem>
          <Icon icon="StatusOxygen" width={32} height={32} fill={theme.color.primary.default} />
          <StyledBody>{t('Oxygen Level')}</StyledBody>
        </ListItem>
        <Body>{t('Oxygen level refers to how much oxygen is in the blood.')}</Body>
        <br />
        <ListItem>
          <Icon icon="StatusHeartrate" width={32} height={32} fill={theme.color.primary.default} />
          <StyledBody>{t('Heart Rate')}</StyledBody>
        </ListItem>
        <Body>{t('Heart Rate is indicated as beats per minute (BPM). Baby’s heart rates are naturally much faster than adults.')}</Body>
        <br />
        <ListItem>
          <Icon icon="Movement" width={32} height={32} fill={theme.color.primary.default} />
          <StyledBody>{t('Movement')}</StyledBody>
        </ListItem>
        <Body>{t('When your baby is moving and we cannot get readings, these wiggling feet will appear and in the app and the green Base Station lights will bounce back and forth.')}</Body>
        <br />
        <ButtonContainer>
          <BottomTextAndArrow buttonText={t('Next')} route={home()} />
        </ButtonContainer>
      </Content>
    </Layout>
  );
};

export default TutorialReadings;
