import { useNavigate } from 'react-router';
import styled from 'styled-components';
import theme from '../../design/theme';
import Icon from '../Icon/Icon';

const Button = styled.div`
  padding: 16px;
`;

const Layout = styled.div`
  height: 80px;
  display: flex;
  background-color: ${theme.color.background.darkMode.darker};
  align-items: center;
`;

type BackButtonProps = {
  path?: string,
}

const BackButton = ({ path }: BackButtonProps) => {
  const navigate = useNavigate();
  const goBack = () => {
    if (path) {
      navigate(path, { replace: true });
    }
    navigate(-1);
  };
  return (
    <Layout>
      <Button onClick={() => goBack()}>
        <Icon icon="ChevronLeft" width={24} height={24} fill={theme.color.text.darkMode.primary} />
      </Button>
    </Layout>

  );
};

export default BackButton;
