import styled from 'styled-components';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import { useEffect } from 'react';
import { Body, Heading, PageHeading } from '../../components/Typography';
import theme from '../../design/theme';
import { tutorialNotifications } from './routes';
import BottomTextAndArrow from '../../components/FormComponents/BottomTextAndArrow';
import TutorialImage from '../../assets/tutorial_hero_sock.png';
import SockUnfoldedLeft from '../../assets/sock_unfolded_left.svg';
import SockUnfoldedRight from '../../assets/sock_unfolded_right.svg';
import SockConnect from '../../assets/sock_connect.svg';
import SockPosition1 from '../../assets/sock_position_1.png';
import SockPosition2 from '../../assets/sock_position_2.png';
import SockPosition3 from '../../assets/sock_position_3.png';
import SockBaseStation from '../../assets/sock_base_station.png';
import NavHeader from '../../components/NavHeader/NavHeader';
import { home } from '../../navigation/routes';

const Layout = styled.div`
  display: flex;
  flex-direction: column;
  `;

const Content = styled.div`
  display: flex;
  flex-direction: column;
  padding: 20px;
`;

const ImageContainer = styled.div`
  background-image: url(${TutorialImage});
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-end;
  min-height: 330px;
  width: 100%;
  text-align: center;
`;

const ButtonContainer = styled.div`
  width: 90%;
  padding: 40px 20px;
  background-color: ${theme.color.background.darkMode.darker};
`;

const PageNumber = styled.div`
  display: flex;
  padding: 20px;
  align-items: center;
`;

const Line = styled.div`
  width: 50px;
  height: 1px;
  background-color: ${theme.color.text.darkMode.primary};
  margin: 0 10px;
`;

const SockSizeContainer = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  align-items: center;
  padding: 20px 0;
`;

const SockColumn = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const Button = styled(Link)`
  padding: 16px;
`;

const TutorialSock = () => {
  const { t } = useTranslation();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const SkipButton = () => (
    <Button to={home()}>
      <Body>{t('Skip')}</Body>
    </Button>
  );

  return (
    <Layout>
      <NavHeader title={t('Sock Tutorial')} backButton backgroundDark rightButton={SkipButton()} />
      <ImageContainer>
        <PageHeading>{t('Sock Placement: How to Get Readings')}</PageHeading>
        <PageNumber>
          <Line />
          <Body>1 / 3</Body>
          <Line />
        </PageNumber>
      </ImageContainer>
      <Content>
        <br />
        <Heading>{t('1. Take the Sock off of the Base Station')}</Heading>
        <Body>{t('When you take the Sock off the charging port the Base Station will start bouncing green, indicating that the Sock is looking for readings from your child. It’s time to put the Sock on your child’s foot.')}</Body>
        <br />
        <Heading>{t('2. Choose a size')}</Heading>
        <Body>{t('Each box comes with 4 socks (2 sizes in a left and right). Smart Sock 3 Extension Packs contain a larger size 3 pair. Choose the appropriate size Sock for either the left or right foot.')}</Body>
        <br />
        <Body>{t('Important: Switch between left and right Socks every week or so to avoid irritation on baby’s skin.')}</Body>
        <br />
        <SockSizeContainer>
          <SockColumn>
            <Heading>{t('Left Sock')}</Heading>
            <br />
            <Body>{t('Size 1:')}</Body>
            <Body>{t('Less than 12 lbs (5.4 kg)')}</Body>
            <img src={SockUnfoldedLeft} width={80} height={100} alt={t('Sock')} />
            <Body>{t('Size 2:')}</Body>
            <Body>{t('12-30 lbs (5.4-13.5 kg)')}</Body>
            <img src={SockUnfoldedLeft} width={120} height={100} alt={t('Sock')} />
            <Body>{t('Size 3*:')}</Body>
            <Body>{t('30-55 lbs (13.5-24.9 kg)')}</Body>
            <img src={SockUnfoldedLeft} width={140} height={100} alt={t('Sock')} />
          </SockColumn>
          <SockColumn>
            <Heading>{t('Right Sock')}</Heading>
            <br />
            <Body>{t('Size 1:')}</Body>
            <Body>{t('Less than 12 lbs (5.4 kg)')}</Body>
            <img src={SockUnfoldedRight} width={80} height={100} alt={t('Sock')} />
            <Body>{t('Size 2:')}</Body>
            <Body>{t('12-30 lbs (5.4-13.5 kg)')}</Body>
            <img src={SockUnfoldedRight} width={120} height={100} alt={t('Sock')} />
            <Body>{t('Size 3*:')}</Body>
            <Body>{t('30-55 lbs (13.5-24.9 kg)')}</Body>
            <img src={SockUnfoldedRight} width={140} height={100} alt={t('Sock')} />
          </SockColumn>
        </SockSizeContainer>
        <Body>{t('* Sold seperately with Smart Sock Plus or Smart Sock Extension Pack.')}</Body>
        <br />
        <Heading>{t('3. Insert the sensor (If not already attached)')}</Heading>
        <br />
        <Body>{t('After selecting a size, make sure the sensor is properly attached to the sock. The notch on the sensor should line up with the notch in the sock.')}</Body>
        <img src={SockConnect} width="100%" height="100%" alt={t('Sock connecting')} style={{ paddingTop: 20, paddingBottom: 20 }} />
        <Heading>{t('4. Position the Sock')}</Heading>
        <br />
        <Body>{t('Place the notch in the Sock behind the pinky toe on the side of their foot.')}</Body>
        <img src={SockPosition1} width="100%" height="100%" alt={t('Displaying Sock with notch')} style={{ paddingTop: 20, paddingBottom: 20 }} />
        <br />
        <Body>{t('Secure the velcro from the toe strap around the foot above the toes.')}</Body>
        <img src={SockPosition2} width="100%" height="100%" alt={t('Securing Sock')} style={{ paddingTop: 20, paddingBottom: 20 }} />
        <br />
        <Body>{t('Then wrap the ankle strap around the back of the ankle and secure it on top of the foot.')}</Body>
        <img src={SockPosition3} width="100%" height="100%" alt={t('Wrapping Sock')} style={{ paddingTop: 20, paddingBottom: 20 }} />
        <br />
        <Heading>{t('5. Avoid these mistakes')}</Heading>
        <br />
        <Body>{t('Leaving these issues unresolved now could lead to discomfort for your baby, or being notified when you are trying to sleep.')}</Body>
        <br />
        <Heading>{t('Mismatched sock')}</Heading>
        <Body>{t('Make sure to use the left sock for the left foot and the right sock for the right foot.')}</Body>
        <br />
        <Heading>{t('Wrong size')}</Heading>
        <Body>{t('If the ankle strap velcro doesn’t fit all the way on the pad, you may need to move up to the size 2 sock.')}</Body>
        <br />
        <Heading>{t('Sock too loose')}</Heading>
        <Body>{t('The Sock and velco straps should be snug against the foot.')}</Body>
        <br />
        <Heading>{t('Sock too tight')}</Heading>
        <Body>{t('Don’t cinch the Sock too tight or it might be uncomfortable for your child.')}</Body>
        <br />
        <br />
        <Heading>{t('6. Check the Base Station')}</Heading>
        <br />
        <Body>{t('With the Sock now on the baby, check the Base Station. Once it softly pulses green that means that your child’s readings are being picked up and are normal.')}</Body>
        <img src={SockBaseStation} width="100%" height="100%" alt={t('Base Station')} style={{ paddingTop: 20, paddingBottom: 20 }} />
        <ButtonContainer>
          <BottomTextAndArrow buttonText={t('Next')} route={tutorialNotifications()} />
        </ButtonContainer>
      </Content>
    </Layout>
  );
};

export default TutorialSock;
