import dotenv from 'dotenv';

import { ConfigVarKind, ConfigVarName } from '../../shared/types';
import * as localConfig from './env/local';
import * as devConfig from './env/dev';
import * as prodConfig from './env/prod';
import * as euConfig from './env/prod-eu';

dotenv.config();

export enum ConfigEnvironment {
  LOCAL = 'local',
  DEV = 'dev',
  PROD = 'prod',
  EU = 'prod-eu'
}

const config: {[index: string]: ConfigVarKind} = {
  [ConfigEnvironment.LOCAL]: localConfig,
  [ConfigEnvironment.DEV]: devConfig,
  [ConfigEnvironment.PROD]: prodConfig,
  [ConfigEnvironment.EU]: euConfig,
};

export function getEnvironmentFromLocation(): ConfigEnvironment {
  /* Decipher the environment from the hostname.
   *
   * Allows us to figure out what environment should be served based soley on
   * the hostname from which the content was loaded. Examples of hostnames that
   * could be used include, but are not limited to:
   * - app.owletcare.co.uk
   * - app.owletcare.pt
   * - app.owletcare.com
   * - app.v1.owletcare.com
   * - app.dev.owletcare.dev
   * - app.v1.dev.owletcare.dev
   */
  const url = new URL(window.location.origin);
  const { host } = url;

  // We can assume we're only serving from owletcare/owletdata domains
  const subdomains = host.split('owlet')[0].replace(/\.*$/, '').split('.');

  // Note: not actually the TLD, but works for our purposes
  const tld = host.split('.').slice(-1)[0];

  // Localhost should use dev
  if (tld.includes('localhost')) {
    return ConfigEnvironment.LOCAL;
  }

  // We will assume that everything points to PROD and nothing to EU until we officially
  // make the site avaialble for the EU. The logic of this file will need to be reworked
  // since we will need to be reworked. Canada (.ca) points to PROD
  //
  // // Anything not served on a .com tld is assumed be EU
  // if (tld !== 'com' || subdomains.includes('eu')) {
  //   return ConfigEnvironment.EU;
  // }

  if (subdomains.length === 1 || (subdomains.length === 2 && subdomains[1].startsWith('v'))) {
    return ConfigEnvironment.PROD;
  }

  if (Object.keys(config).includes(subdomains[1])) {
    return subdomains[1] as ConfigEnvironment;
  }

  if (subdomains.length > 2 && Object.keys(config).includes(subdomains[2])) {
    return subdomains[2] as ConfigEnvironment;
  }

  return ConfigEnvironment.DEV;
}

export function getConfigSetting<K extends ConfigVarName>(key: K): ConfigVarKind[K] {
  if (process.env[`REACT_APP_${key}`]) {
    return process.env[`REACT_APP_${key}`] as ConfigVarKind[K];
  }

  const environment = getEnvironmentFromLocation();
  return config[environment][key];
}

export function getFirebaseConfig() {
  return {
    appId: getConfigSetting(ConfigVarName.FIREBASE_APP_ID),
    apiKey: getConfigSetting(ConfigVarName.FIREBASE_API_KEY),
    authDomain: getConfigSetting(ConfigVarName.FIREBASE_AUTH_DOMAIN),
    databaseURL: getConfigSetting(ConfigVarName.FIREBASE_DATABASE_URL),
    projectId: getConfigSetting(ConfigVarName.FIREBASE_PROJECT_ID),
  };
}
