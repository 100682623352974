import UseFirebasePostDataHook from '../../../backend/utils/useFirebasePostDataHook';
import { useErrorContext } from '../../../backend/contexts/errorContext';
import { useAuthContext } from '../../../backend/contexts/authContext';
import { useServiceUserContext } from '../../../backend/contexts/serviceUserContext';
import { mapUserToDevice } from '../../../backend/utils/owletApi';
import ChildListItem from './ChildListItem';

type ChildListProps = {
  dsn: string
  serviceKey: string,
}

const ChildList = ({ dsn, serviceKey }: ChildListProps) => {
  const { firebaseToken } = useAuthContext();
  const { withErrorHandler } = useErrorContext();
  const { serviceUsersList } = useServiceUserContext();
  const serviceUsers = serviceUsersList.filter(
    (child) => !child.service || child?.service?.id === serviceKey,
  );

  const mapUser = (serviceUserId: string) => {
    mapUserToDevice(`${firebaseToken}`, serviceUserId, dsn).then(() => {
      withErrorHandler(UseFirebasePostDataHook(
        'services',
        `${serviceKey}/serviceUserKeys`,
        { [serviceUserId]: true },
      ));
    });
  };

  return (
    <>
      {serviceUsers.map((serviceUser) => (
        <ChildListItem
          key={serviceUser.id}
          checked={serviceUser.service?.id === serviceKey}
          serviceUser={serviceUser}
          onClick={() => mapUser(serviceUser.id)}
        />
      ))}
    </>
  );
};

export default ChildList;
