import { Navigate, useLocation } from 'react-router-dom';
import { useAuthContext, AuthStatus } from '../../backend/contexts/authContext';
import { welcome, confirmationEmail } from './routes';

const PrivateRoute = ({ children }: { children: JSX.Element }) => {
  const { authStatus, currentUser } = useAuthContext();
  const location = useLocation();

  if (location.pathname !== confirmationEmail() && !currentUser?.emailVerified && currentUser) {
    return (
      <Navigate
        to={{
          pathname: confirmationEmail(),
        }}
      />
    );
  }

  return authStatus === AuthStatus.Authenticated
    ? children
    : (
      <Navigate
        to={{
          pathname: welcome(),
        }}
      />
    );
};

export default PrivateRoute;
