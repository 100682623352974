import styled from 'styled-components';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import { useEffect } from 'react';
import { Body, Heading, PageHeading } from '../../components/Typography';
import theme from '../../design/theme';
import { tutorialReadings } from './routes';
import BottomTextAndArrow from '../../components/FormComponents/BottomTextAndArrow';
import TutorialImage from '../../assets/tutorial_hero_notifications.png';
import BaseSnooze from '../../assets/base_snooze.png';
import BaseTurnOff from '../../assets/base_turnoff.png';
import NavHeader from '../../components/NavHeader/NavHeader';
import { Icon } from '../../components/Icon';
import { home } from '../../navigation/routes';

const Layout = styled.div`
  display: flex;
  flex-direction: column;
  `;

const Content = styled.div`
  display: flex;
  flex-direction: column;
  padding: 20px;
`;

const ImageContainer = styled.div`
  background-image: url(${TutorialImage});
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-end;
  min-height: 330px;
  text-align: center;
  padding: 0 20px;
`;

const ButtonContainer = styled.div`
  width: 90%;
  padding: 40px 20px;
  background-color: ${theme.color.background.darkMode.darker};
`;

const PageNumber = styled.div`
  display: flex;
  padding: 20px;
  align-items: center;
`;

const Line = styled.div`
  width: 50px;
  height: 1px;
  background-color: ${theme.color.text.darkMode.primary};
  margin: 0 10px;
`;

const SockSizeContainer = styled.div`
  display: grid;
  grid-auto-flow: column;
  gap: 40px;
  align-items: center;
  justify-content: center;
  padding: 20px 0;
`;

const ListItem = styled.div`
  display: flex;
  align-items: center;
  padding: 10px 0;
  margin-top: 10px;
`;

const RightContent = styled.div`
  margin-left: 20px;
  width: 80%;
`;

const StyledBody = styled(Body)`
  margin-left: 10px;
  font-weight: 600;
  width: 80%;
`;

const Button = styled(Link)`
  padding: 16px;
`;

const TutorialNotifications = () => {
  const { t } = useTranslation();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const SkipButton = () => (
    <Button to={home()}>
      <Body>{t('Skip')}</Body>
    </Button>
  );

  return (
    <Layout>
      <NavHeader title={t('Sock Tutorial')} backButton backgroundDark rightButton={SkipButton()} />
      <ImageContainer>
        <PageHeading>{t('Notifications: Know what’s going on')}</PageHeading>
        <PageNumber>
          <Line />
          <Body>2 / 3</Body>
          <Line />
        </PageNumber>
      </ImageContainer>
      <Content>
        <br />
        <Heading>{t('Getting a notification')}</Heading>
        <Body>{t('The Base Station is designed to notify you with light and sound if your child’s readings are out of the normal range or if monitoring is interrupted.')}</Body>
        <br />
        <Body>{t('There are 3 different Base Station notification types: Yellow, Blue, and Red.')}</Body>
        <br />
        <SockSizeContainer>
          <Icon icon="StatusBaseStationFlashing" width={68} height={68} fill={theme.color.status.warning} />
          <Icon icon="StatusBaseStationFlashing" width={68} height={68} fill={theme.color.status.outOfRange} />
          <Icon icon="StatusBaseStationFlashing" width={68} height={68} fill={theme.color.status.error} />
        </SockSizeContainer>
        <ListItem>
          <Icon icon="StatusBaseStationFlashing" width={40} height={40} fill={theme.color.status.warning} />
          <StyledBody>{t('Flashing Yellow')}</StyledBody>
        </ListItem>
        <br />
        <Heading>{t('What happened?')}</Heading>
        <Body>{t('Sock has fallen off, shifted, or is improperly placed.')}</Body>
        <br />
        <Heading>{t('What should I do?')}</Heading>
        <Body>{t('Snooze the notification. You’ll then have 1 min to re-secure the sock. Make sure the correct sock is on the correct foot.')}</Body>
        <br />
        <ListItem>
          <Icon icon="StatusBaseStationFlashing" width={40} height={40} fill={theme.color.status.outOfRange} />
          <StyledBody>{t('Flashing Blue')}</StyledBody>
        </ListItem>
        <br />
        <Heading>{t('What happened?')}</Heading>
        <Body>{t('Sock out of range, out of battery, or the signal is being blocked.')}</Body>
        <br />
        <Heading>{t('What should I do?')}</Heading>
        <Body>{t("Snooze the notification and check that your Sock is connected to Wi-Fi and that you are within range of the Base Station. Note: Your body can also block the Sock's signal. If your Sock is out of battery return it to the Base Station.")}</Body>
        <br />
        <ListItem>
          <Icon icon="StatusBaseStationFlashing" width={40} height={40} fill={theme.color.status.error} />
          <StyledBody>{t('Flashing Red')}</StyledBody>
        </ListItem>
        <br />
        <Heading>{t('What happened?')}</Heading>
        <Body>{t('Heart rate or oxygen level have left preset zones.')}</Body>
        <br />
        <ListItem>
          <Icon icon="StatusOxygen" width={24} height={24} fill={theme.color.text.darkMode.primary} />
          <RightContent>
            <Heading>{t('Low oxygen')}</Heading>
            <Body>{t('Child’s oxygen has dropped below 80%')}</Body>
          </RightContent>
        </ListItem>
        <ListItem>
          <Icon icon="StatusHeartrate" width={24} height={24} fill={theme.color.text.darkMode.primary} />
          <RightContent>
            <Heading>{t('Low heart rate')}</Heading>
            <Body>{t('Child’s heart rate goes below 60 BPM when oxygen is also below 85%')}</Body>
          </RightContent>
        </ListItem>
        <ListItem>
          <Icon icon="StatusHeartrate" width={24} height={24} fill={theme.color.text.darkMode.primary} />
          <RightContent>
            <Heading>{t('High heart rate')}</Heading>
            <Body>{t('Child’s heart rate goes above 220 BPM')}</Body>
          </RightContent>
        </ListItem>
        <br />
        <Heading>{t('What should I do?')}</Heading>
        <Body>{t('When receiving a red notification, the very first thing you should do is check on your baby and see if they are okay.')}</Body>
        <br />
        <Body>{t('Reference the guide tab for more specific guidelines on how to check on baby.')}</Body>
        <br />
        <Body>{t('If it was not an emergency, it was probably caused by one of the following:')}</Body>
        <br />
        <ListItem>
          <Icon icon="TutorialBottle" width={24} height={24} fill={theme.color.text.darkMode.primary} />
          <RightContent>
            <Heading>{t('Feeding/burping')}</Heading>
            <Body>{t('When the baby is feeding, oxygen naturally decreases and heart rate naturally rises.')}</Body>
          </RightContent>
        </ListItem>
        <ListItem>
          <Icon icon="TutorialIllness" width={24} height={24} fill={theme.color.text.darkMode.primary} />
          <RightContent>
            <Heading>{t('Illness')}</Heading>
            <Body>{t('Red notifications can be more frequent when the baby is sick or congested.')}</Body>
          </RightContent>
        </ListItem>
        <ListItem>
          <Icon icon="TutorialBreathing" width={24} height={24} fill={theme.color.text.darkMode.primary} />
          <RightContent>
            <Heading>{t('Periodic Breathing')}</Heading>
            <Body>{t('It is common for newborns to occasionally hold their breath for up to 10 seconds. This is called periodic breathing.')}</Body>
          </RightContent>
        </ListItem>
        <ListItem>
          <Icon icon="TutorialCrying" width={24} height={24} fill={theme.color.text.darkMode.primary} />
          <RightContent>
            <Heading>{t('Crying or moving')}</Heading>
            <Body>{t('Sometimes the baby’s readings can naturally change when crying or moving a lot.')}</Body>
          </RightContent>
        </ListItem>
        <ListItem>
          <Icon icon="TutorialPlacement" width={24} height={24} fill={theme.color.text.darkMode.primary} />
          <RightContent>
            <Heading>{t('Sock placement')}</Heading>
            <Body>{t('Sometimes the Sock can be close enough to get a reading, but still too loose, causing faint readings.')}</Body>
          </RightContent>
        </ListItem>
        <ListItem>
          <Icon icon="TutorialElevation" width={24} height={24} fill={theme.color.text.darkMode.primary} />
          <RightContent>
            <Heading>{t('Elevation or cold feet')}</Heading>
            <Body>{t('High elevation or cold feet can make red notifications more frequent.')}</Body>
          </RightContent>
        </ListItem>
        <br />
        <Heading>{t('Snoozing a notification')}</Heading>
        <Body>{t('When you get a notification you can snooze the sound for 60 seconds with a short press on the Base Station or in the app. After 60 seconds, if the issue was not resolved, the Base will sound again.')}</Body>
        <img src={BaseSnooze} width="100%" height="100%" alt={t('Base Station snooze')} style={{ paddingTop: 20, paddingBottom: 20 }} />
        <br />
        <Heading>{t('Turning off monitoring')}</Heading>
        <Body>{t('We recommend that you charge the Sock whenever not monitoring, but if you ever want to turn off monitoring, press and hold the Base Station until the light ring goes out and you hear a chirp. A short press will turn on the Base Station again.')}</Body>
        <img src={BaseTurnOff} width="100%" height="100%" alt={t('Base Station turn off')} style={{ paddingTop: 20, paddingBottom: 20 }} />
        <ButtonContainer>
          <BottomTextAndArrow buttonText={t('Next')} route={tutorialReadings()} />
        </ButtonContainer>
      </Content>
    </Layout>
  );
};

export default TutorialNotifications;
