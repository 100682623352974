import { useTranslation } from 'react-i18next';
import {
  ErrorFormHelperText,
  GoodFormHelperText,
  WarningFormHelperText,
} from './Styles';
import { determinePasswordStrength } from '../../../backend/utils/helpers';

interface PasswordStrengthIndicatorProps {
  passwordScore: number,
}

const PasswordStrengthIndicator = ({ passwordScore }: PasswordStrengthIndicatorProps) => {
  const { t } = useTranslation();

  const finalPasswordStrength = determinePasswordStrength(passwordScore);

  if (passwordScore <= 4 && passwordScore >= 2) {
    return <GoodFormHelperText id="component-error-text">{t(finalPasswordStrength)}</GoodFormHelperText>;
  }
  if (passwordScore === 1) {
    return <WarningFormHelperText>{t(finalPasswordStrength)}</WarningFormHelperText>;
  }
  return <ErrorFormHelperText id="component-error-text">{t(finalPasswordStrength)}</ErrorFormHelperText>;
};

export default PasswordStrengthIndicator;
