import { AxiosResponse } from 'axios';
import { getConfigSetting } from '../config/URLConfig';
import { owletAxios as axios } from './apiInstance';
import { ConfigVarName, GetIpAddressResponse, GetMiniTokenResponse } from '../../shared/types';
import {
  appDeviceModel, appVersion, osName, osVersion,
} from './helpers';
import { OWLET_USER_AGENT_HEADER } from '../../shared/constants';

const apiDomain = () => (
  getConfigSetting(ConfigVarName.OWLET_API_DOMAIN)
);

type GetMiniTokenRequest = (token: string) => Promise<AxiosResponse<GetMiniTokenResponse>>

const getMiniToken: GetMiniTokenRequest = (token) => (
  axios.get<GetMiniTokenResponse>(`https://ayla-sso.${apiDomain()}/mini/`, {
    headers: headers(token),
  })
);

type UserMapperRequest = (
  token: string,
  serviceUserId: string,
  dsn: string
) => Promise<AxiosResponse>

const mapUserToDevice: UserMapperRequest = (token, serviceUserId, dsn) => (
  axios.post(`https://user-mapper.${apiDomain()}/suid/${serviceUserId}`,
    { dsn },
    {
      headers: headers(token),
    })
);

const registerDevice: (token: string, dsn: string) => Promise<AxiosResponse> = (token, dsn) => (
  axios.post(`https://app-device-registration.${apiDomain()}/sock/${dsn}`, {}, {
    headers: headers(token),
  })
);

const removeDevice: (token: string, dsn: string) => Promise<AxiosResponse> = (token, dsn) => (
  axios.delete(`https://app-device-registration.${apiDomain()}/sock/${dsn}`, {
    headers: headers(token),
  })
);

export type SleepDataRequestParams = {
  token: string,
  accountId: string,
  serviceUserId: string,
  startTime: number,
  endTime: number,
  timeZone: string,
  version: string
}

const sleepData = ({
  token, accountId, serviceUserId, ...params
}: SleepDataRequestParams) => (
  axios.get(`https://sleep-data.${apiDomain()}/v1/accounts/${accountId}/profiles/${serviceUserId}/sleep`, {
    headers: headers(token),
    params,
  })
);

export type VitalsDataRequestParams = {
  token: string
  profileId: string
  accountId: string
  startTime: number
  endTime: number
  resolution: number
  version: string
}

const vitalsData = ({
  token, accountId, profileId, ...params
}: VitalsDataRequestParams) => (
  axios.get(`https://vital-data.${apiDomain()}/v1/accounts/${accountId}/profiles/${profileId}/vitals`, {
    headers: headers(token),
    params,
  })
);

const getPublicIpAddress: () => Promise<AxiosResponse<GetIpAddressResponse>> = () => axios.get('https://api.ipify.org?format=json');

export type NpsSurveyRequestParams = {
  token: string
  accountId: string
  requestId: string
  npsResponse: {
    appVersion: string
    clientDeviceType: string
    notes: string | undefined
    rating: number | null
    submissionTime: string
  }
}

const submitNpsSurvery = ({
  token, accountId, npsResponse, ...params
}: NpsSurveyRequestParams) => (
  axios.post(`https://accounts.${apiDomain()}/v1/accounts/${accountId}/npsResponses`,
    { ...params, npsResponse }, {
      headers: headers(token),
    })
);

const headers = (token: string) => (
  {
    Authorization: token,
    ...userAgent(),
  }
);

const userAgent = (): {[OWLET_USER_AGENT_HEADER]: string} => (
  {
    [OWLET_USER_AGENT_HEADER]: `OwletWebApp/${appVersion()} (${appDeviceModel()}, ${osName()} ${osVersion()})`,
  }
);

export {
  getMiniToken,
  getPublicIpAddress,
  mapUserToDevice,
  registerDevice,
  removeDevice,
  sleepData,
  vitalsData,
  submitNpsSurvery,
};
