import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';
import { Heading } from '../../components/Typography';
import theme from '../../design/theme';

interface InfoProps {
  title: string;
  ImageUrl: string;
}

const Container = styled.div`
  background: ${theme.color.background.darkMode.dark};
  margin: 24px auto;
  border-radius: 8px;
  width: 87%;
  overflow: hidden;
`;

const BottomContainer = styled.a`
  padding: 16px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  text-decoration: none;
`;

const InfoCard = ({ title, ImageUrl }: InfoProps) => {
  const { t } = useTranslation();

  return (
    <Container>
      <img src={ImageUrl} alt={t('Owlet Smart Sock')} width="100%" />
      <BottomContainer href="https://owletcare.com/pages/sock-update" target="_blank">
        <Heading>
          {title}
        </Heading>
        <ArrowForwardIosIcon
          fontSize="inherit"
          style={{ fontSize: '14px' }}
          color="primary"
        />
      </BottomContainer>
    </Container>
  );
};

export default InfoCard;
