export const rootPath = () => '/';
export const root = () => `/devices${rootPath()}`;
export const chooseDevicePath = () => 'choose-device';
export const chooseDevice = () => `${root()}${chooseDevicePath()}`;
export const registerDevicePath = () => 'register-device';
export const registerDevice = () => `${root()}${registerDevicePath()}`;
export const baseSetupPath = () => 'base-setup';
export const baseSetup = () => `${root()}${baseSetupPath()}`;
export const basePlugInPath = () => 'base-plug-in';
export const basePlugIn = () => `${root()}${basePlugInPath()}`;
export const wifiInstructionsPath = () => 'wifi-instructions';
export const wifiInstructions = () => `${root()}${wifiInstructionsPath()}`;
export const wifiConfirmationPath = () => 'wifi-confirmation';
export const wifiConfirmation = () => `${root()}${wifiConfirmationPath()}`;
export const safetyInfoPath = () => 'safety-info';
export const safetyInfo = () => `${root()}${safetyInfoPath()}`;
export const deviceNotFoundPath = () => 'device-not-found';
export const deviceNotFound = () => `${root()}${deviceNotFoundPath()}`;
export const searchSockPath = () => 'searching';
export const searchSock = () => `${root()}${searchSockPath()}`;
export const pairFailedPath = () => 'pair-failed';
export const pairFailed = () => `${root()}${pairFailedPath()}`;
export const checkOTAUpdatesPath = () => 'update-device';
export const checkOTAUpdates = () => `${root()}${checkOTAUpdatesPath()}`;
export const oTAUpdatesPausedPath = () => 'update-paused';
export const oTAUpdatesPaused = () => `${root()}${oTAUpdatesPausedPath()}`;
export const oTAUpdatesSuccessPath = () => 'update-success';
export const oTAUpdatesSuccess = () => `${root()}${oTAUpdatesSuccessPath()}`;
export const assignChildPath = (
  accountKey = ':accountKey',
  serviceKey = ':serviceKey',
  deviceKey = ':deviceKey',
) => `assign-child/account/${accountKey}/${serviceKey}/devices/${deviceKey}`;
export const assignChild = (
  accountKey = ':accountKey',
  serviceKey = ':serviceKey',
  deviceKey = ':deviceKey',
) => `${root()}${assignChildPath(accountKey, serviceKey, deviceKey)}`;
export const addChildPath = () => 'add-child';
export const addChild = () => `${root()}${addChildPath()}`;
export const aboutChildPath = () => 'child-profile/about';
export const aboutChild = () => `${root()}${aboutChildPath()}`;
export const notBornYetPath = () => 'child-profile/not-born-yet';
export const notBornYet = () => `${root()}${notBornYetPath()}`;
export const childIsBornPath = () => 'child-profile/is-born';
export const childIsBorn = () => `${root()}${childIsBornPath()}`;
export const tutorialSock = () => `${root()}${tutorialSockPath()}`;
export const tutorialSockPath = () => 'tutorial-sock';
export const tutorialNotifications = () => `${root()}${tutorialNotificationsPath()}`;
export const tutorialNotificationsPath = () => 'tutorial-notifications';
export const tutorialReadings = () => `${root()}${tutorialReadingsPath()}`;
export const tutorialReadingsPath = () => 'tutorial-readings';
export const success = () => `${root()}${successPath()}`;
export const successPath = () => 'onboarding-success';
