import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import { safetyInfo } from './routes';
import OwlIcon from '../../assets/owl_lookdown.svg';
import BottomTextAndArrow from '../../components/FormComponents/BottomTextAndArrow';
import { Body, PageHeading } from '../../components/Typography';
import theme from '../../design/theme';

const Layout = styled.div`
  display: flex;
  flex-direction: column;
  `;

const Content = styled.div`
  display: flex;
  flex-direction: column;
  padding: 20px;
`;

const ImageContainer = styled.img`
  width: 100px;
  height: 100px;
  margin: 30px 0;
  align-self: center;
`;

const ButtonContainer = styled.div`
  position: fixed;
  width: 90%;
  bottom: 0;
  padding: 40px 20px;
  background-color: ${theme.color.background.darkMode.darker};
`;

const CheckOTAUpdatesSuccess = () => {
  const { t } = useTranslation();

  return (
    <Layout>
      <Content>
        <ImageContainer
          alt={t('Owl Icon')}
          src={OwlIcon}
        />
        <PageHeading>{t('Your Owlet Sock is up to date!')}</PageHeading>
        <Body>{t('Next we’ll review some safety warnings.')}</Body>
      </Content>
      <ButtonContainer>
        <BottomTextAndArrow buttonText={t('Next')} route={safetyInfo()} />
      </ButtonContainer>
    </Layout>
  );
};

export default CheckOTAUpdatesSuccess;
