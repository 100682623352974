import {
  createContext,
  useCallback,
  useEffect,
  useState,
  useContext,
} from 'react';
import { AxiosError, aylaAxios, owletAxios } from '../utils/apiInstance';
import ErrorBanner from '../../ui/components/ErrorBanner';

type ErrorProviderProps = { children: React.ReactNode }

type WithErrorHandler = <T>(p: Promise<T>, cleanup?: () => void) => Promise<T | void>;

type StateType = {
  error: boolean,
  withErrorHandler: WithErrorHandler
};

const initialState: StateType = {
  error: false,
  withErrorHandler: () => Promise.resolve(),
};

const Context = createContext<StateType>(initialState);

const useErrorContext = () => {
  const context = useContext(Context);
  if (context === undefined) {
    throw new Error('ErrorContext context must be used within an ErrorProvider');
  }
  return context;
};

const ErrorProvider = ({ children }: ErrorProviderProps) => {
  const [error, setError] = useState<boolean>(initialState.error);

  const withErrorHandler: WithErrorHandler = useCallback(
    (request, cleanup) => request.catch((e: any) => {
      setError(true);
      cleanup?.();
    }), [],
  );

  useEffect(() => {
    const handleError = (e: AxiosError) => {
      const errorStatus = e.response?.status || -1;
      if (errorStatus >= 400 && errorStatus !== 401) {
        setError(true);
      }

      return Promise.reject(e);
    };

    const aylaInterceptor = aylaAxios.interceptors.response.use(undefined, handleError);
    const owletInterceptor = owletAxios.interceptors.response.use(undefined, handleError);

    return () => {
      aylaAxios.interceptors.request.eject(aylaInterceptor);
      owletAxios.interceptors.request.eject(owletInterceptor);
    };
  }, []);

  useEffect(() => {
    let timeoutId: ReturnType<typeof setTimeout>;
    if (error) {
      timeoutId = setTimeout(() => {
        setError(false);
      }, 4000);
    }
    return () => {
      clearTimeout(timeoutId);
    };
  }, [error]);

  return (
    <Context.Provider value={{ error, withErrorHandler }}>
      <>
        {error && <ErrorBanner onClose={() => { setError(false); }} />}
        {children}
      </>
    </Context.Provider>
  );
};

export { useErrorContext, ErrorProvider };
