import _ from 'lodash';
import getConnectionStatus from '../../../utils/deviceConnectionStatus';
import {
  AylaContextState,
  AylaUpdateVitalsPayload,
  DeviceConnectionStatus,
  DeviceDetails,
  DeviceStatus,
  SmartSockOTAState,
} from '../../../../shared/types';

export default (state: AylaContextState, payload: AylaUpdateVitalsPayload) => {
  const { lastUpdatedAtUTC, vitals: newVitals } = payload;
  const newDevice: DeviceDetails = {
    ...(state[payload.dsn] || {}),
    ...{ ...newVitals, lastUpdatedAtUTC },
  };

  newDevice.connectionStatus = getConnectionStatus(
    newDevice.setDeviceActiveAtUTC,
    newDevice.lastUpdatedAtUTC,
    newDevice.firstSetDeviceActiveAtUTC,
  );

  newDevice.hr = undefined;
  newDevice.ox = undefined;

  if (newDevice.connectionStatus === DeviceConnectionStatus.Disconnected) {
    newDevice.status = DeviceStatus.offline;
    newDevice.bso = undefined;
    newDevice.btt = undefined;
    newDevice.chg = undefined;
  } else if (newDevice.connectionStatus === DeviceConnectionStatus.Connecting) {
    newDevice.status = DeviceStatus.connecting;
    newDevice.bso = undefined;
    newDevice.btt = undefined;
    newDevice.chg = undefined;
  } else if (
    newVitals.ota === SmartSockOTAState.installing
        || newVitals.ota === SmartSockOTAState.installingCritical) {
    newDevice.status = DeviceStatus.otaInProgress;
  } else if (newVitals.chg === 2) {
    newDevice.status = DeviceStatus.sockCharged;
    newDevice.btt = undefined;
    newDevice.bso = undefined;
  } else if (newVitals.chg === 1) {
    newDevice.status = DeviceStatus.sockCharging;
    newDevice.btt = undefined;
    newDevice.bso = undefined;
  } else if (newVitals.bso === 0) {
    newDevice.status = DeviceStatus.basestationOff;
  } else if (newVitals.aps === 1) {
    newDevice.status = DeviceStatus.alertPaused;
  } else if (newVitals.alrt === 16) {
    newDevice.status = DeviceStatus.sockDisconnected;
    newDevice.btt = undefined;
  } else if (newVitals.alrt === 32 || newVitals.alrt === 64) {
    newDevice.status = DeviceStatus.sockPlacementIssue;
  } else if (newVitals.srf === 3) {
    newDevice.status = DeviceStatus.sockRecentlyPlaced;
  } else if (newVitals.sc === 2 && newVitals.srf === 2) {
    newDevice.status = DeviceStatus.kicking;
  } else if (newVitals.srf === 1) {
    newDevice.status = DeviceStatus.checkingOn;
  } else {
    newDevice.status = DeviceStatus.gettingData;
    newDevice.hr = payload.vitals.hr;
    newDevice.ox = payload.vitals.ox;
  }

  const updatedState = { ...state, ...{ [payload.dsn]: newDevice } };

  if (!_.isEqual(state, updatedState)) {
    return updatedState;
  }

  return state;
};
