import styled from 'styled-components';
import { useTranslation } from 'react-i18next';
import { Body, PageHeading } from '../Typography';
import { Icon } from '../Icon';
import theme from '../../design/theme';

const ListItem = styled.div`
  display: flex;
  align-items: center;
  padding: 10px 0;
  margin-top: 10px;
`;

const StyledPageHeading = styled(PageHeading)`
  color: ${theme.color.primary.default};
`;

const StyledBody = styled(Body)`
  padding-left: 10px;
`;

const ModalDayTotals = () => {
  const { t } = useTranslation();

  return (
    <div>
      <StyledPageHeading>{t('Day Totals')}</StyledPageHeading>
      <Body>{t('A day here, is defined as a 24 hour period from 12am to 11:59pm')}</Body>
      <ListItem>
        <Icon icon="StatusSleep" width={24} height={24} fill={theme.color.tertiary.rhythm} />
        <StyledBody>{t('The total amount of sleep in a day')}</StyledBody>
      </ListItem>
      <ListItem>
        <Icon icon="StatusOxygen" width={24} height={24} fill={theme.color.tertiary.wave} />
        <StyledBody>{t('The average oxygen level in a day')}</StyledBody>
      </ListItem>
      <ListItem>
        <Icon icon="StatusHeartrate" width={24} height={24} fill={theme.color.tertiary.strawberry} />
        <StyledBody>{t('The average heart rate level in a day')}</StyledBody>
      </ListItem>
    </div>
  );
};

export default ModalDayTotals;
