import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { FormControl } from '@mui/material';
import { LocalizationProvider } from '@mui/lab';
import AdapterDateFns from '@mui/lab/AdapterDateFns';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import {
  Screen,
  StyledInput,
  StyledInputLabel,
  StyledFormHelperText,
} from './Styles';
import { PageHeading } from '../../components/Typography';
import Button from '../../components/Button/Button';
import { useAuthContext } from '../../../backend/contexts/authContext';
import UseFirebasePostDataHook from '../../../backend/utils/useFirebasePostDataHook';
import useForm from '../../../backend/hooks/useForm';
import Phone from '../../components/Phone';
import { home } from '../../navigation/routes';
import ErrorBanner from '../../components/ErrorBanner';
import DateChooser from '../../components/FormComponents/DateChooser';
import { locales } from '../../../backend/config/i18n';
import theme from '../../design/theme';

const Details = () => {
  const { currentUser } = useAuthContext();
  const navigate = useNavigate();
  const { t, i18n } = useTranslation();

  const {
    handleSubmit,
    handleChange,
    data,
    errors,
  } = useForm({
    validations: {
      firstName: {
        custom: {
          isValid: (value: string) => value !== '',
          message:
            t('First name is empty'),
        },
      },
      lastName: {
        custom: {
          isValid: (value: string) => value !== '',
          message:
            t('Last name is empty'),
        },
      },
      phone: {
        custom: {
          isValid: (value: string) => value !== '',
          message:
            t('Phone is empty'),
        },
      },
    },
    initialValues: {
      firstName: '',
      lastName: '',
      birthDay: null,
      phone: null,
    },
    onSubmit: () => CreateUser(),
  });

  const {
    firstName, lastName, birthDay, phone,
  } = data;

  const CreateUser = async () => {
    const updateData = {
      email: currentUser?.email,
      firstName,
      lastName,
      phone: phoneNumber,
      birthdate: birthDateValue,
      accountKey: currentUser?.uid,
      id: currentUser?.uid,
    };

    await UseFirebasePostDataHook(
      'accountUsers', currentUser?.uid ? currentUser?.uid : '', updateData,
    ).then(() => {
      navigate(home(), { replace: true });
    }).catch(() => {
      handleError(true);
    });
  };

  const [birthDateValue, setBirthDateValue] = useState<number | undefined | null>(birthDay);
  const [errorMessageView, handleError] = useState<boolean | string>(false);
  const [phoneNumber, setPhoneNumber] = useState(phone);

  return (
    <form>
      {!errorMessageView
          || (
            <ErrorBanner
              onClose={() => {
                handleError(false);
              }}
            />
          )}
      <LocalizationProvider dateAdapter={AdapterDateFns} locale={locales[i18n.language]}>
        <Screen>
          <PageHeading>{t("'Hoo' are you?")}</PageHeading>
          <FormControl variant="standard">
            <StyledInputLabel
              id="firstName"
            >
              {t('First Name*')}
            </StyledInputLabel>
            <StyledInput
              name="firstName"
              value={firstName}
              error={!!errors.firstName}
              onChange={(e) => (handleChange('firstName', e.target.value))}
            />
            {errors.firstName && (
            <StyledFormHelperText
              id="component-error-text"
              error={!!errors.firstName}
            >
              {errors.firstName}
            </StyledFormHelperText>
            )}
          </FormControl>
          <FormControl variant="standard">
            <StyledInputLabel
              id="lastName"
            >
              {t('Last Name*')}
            </StyledInputLabel>
            <StyledInput
              name="lastName"
              value={lastName}
              error={!!errors.lastName}
              onChange={(e) => (handleChange('lastName', e.target.value))}
            />
            {errors.lastName && (
            <StyledFormHelperText
              id="component-error-text"
              error={!!errors.lastName}
            >
              {errors.lastName}
            </StyledFormHelperText>
            )}
          </FormControl>
          <FormControl variant="standard">
            <DateChooser dateValue={birthDateValue} label={t('Birth Date')} setter={setBirthDateValue} />
            {errors.birthday && (
              <StyledFormHelperText
                id="component-error-text"
                error={!!errors.birthDay}
              >
                {errors.birthDay}
              </StyledFormHelperText>
            )}
          </FormControl>
          <FormControl>
            <Wrapper>
              <Phone isDetails phoneNumber={phoneNumber} setPhoneNumber={setPhoneNumber} />
            </Wrapper>
            {errors.phone && (
              <StyledFormHelperText
                id="component-error-text"
                error={!!errors.phone}
              >
                {errors.phone}
              </StyledFormHelperText>
            )}
          </FormControl>
          <Button
            label={t('Save')}
            size="large"
            onClick={() => {
              handleSubmit();
            }}
          />
        </Screen>
      </LocalizationProvider>
    </form>
  );
};

const Wrapper = styled.div`
    .react-tel-input .special-label {
      display: block;
      color: ${theme.color.text.darkMode.primary};
      background-color: ${theme.color.background.darkMode.darker}
    }
  `;

export default Details;
