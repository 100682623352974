import { useEffect, useState } from 'react';
import { AxiosError } from 'axios';
import { useNavigate } from 'react-router-dom';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';
import { Body, PageHeading } from '../../components/Typography';
import { uninterceptedAxios } from '../../../backend/utils/apiInstance';
import { findRegisterableDevice, register as registerWithAyla } from '../../../backend/services/ayla';
import { getPublicIpAddress, registerDevice as registerWithOwlet } from '../../../backend/utils/owletApi';
import { useAuthContext } from '../../../backend/contexts/authContext';
import { useAylaContext } from '../../../backend/contexts/aylaContext';
import { deviceNotFound, searchSock } from './routes';
import DeviceImage from '../../assets/onboarding_push_device.svg';
import BackButton from '../../components/Button/BackButton';
import { useDeviceSetupContext } from '../../../backend/contexts/deviceSetupContext';
import { ECONNABORTED } from '../../../shared/constants';

const pollIntervalMs = 5000;
const giveUpAfterMs = 60000;

const Layout = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
`;

const BaseStation = styled.img`
  margin: 32px;
  width: 312px;
  height: 184px;
  display: flex;
  align-self: center;
  `;

const Title = styled(PageHeading)`
  margin-bottom: 8px;
`;

const Content = styled.div`
  display: flex;
  flex-direction: column;
  padding: 20px;
`;

const RegisterDevice = () => {
  const navigate = useNavigate();
  const { firebaseToken } = useAuthContext();
  const { aylaToken } = useAylaContext();
  const { setDsn: setNewDeviceDsn } = useDeviceSetupContext();
  const [publicIpAddress, setPublicIpAddress] = useState<string | undefined>();
  const [dsn, setDsn] = useState<string | undefined>();
  const { t } = useTranslation();

  useEffect(() => {
    getPublicIpAddress().then((
      { data },
    ) => { setPublicIpAddress(data.ip); });
  }, []);

  useEffect(() => {
    let axiosInterceptor: number;

    if (aylaToken && publicIpAddress) {
      const started = Date.now();
      const doRegister = () => findRegisterableDevice(
        aylaToken, publicIpAddress,
      ).then((data) => {
        setDsn(data?.dsn);
      });

      axiosInterceptor = uninterceptedAxios.interceptors.response.use(undefined,
        (error: AxiosError) => {
          const giveUp = (Date.now() - started) > giveUpAfterMs;
          if ((error?.code === ECONNABORTED || error?.response?.status === 404) && !giveUp) {
            setTimeout(doRegister, pollIntervalMs);
          } else if (giveUp) {
            navigate(deviceNotFound());
          }

          Promise.reject(error);
        });
      doRegister();
    }

    return () => {
      uninterceptedAxios.interceptors.response.eject(axiosInterceptor);
    };
  }, [aylaToken, navigate, publicIpAddress]);

  useEffect(() => {
    if (aylaToken && dsn && firebaseToken && publicIpAddress) {
      registerWithAyla(aylaToken, { device: { dsn, ip: publicIpAddress } }).then(() => {
        registerWithOwlet(firebaseToken, dsn).then(() => {
          setNewDeviceDsn(dsn);
          navigate(searchSock());
        });
      });
    }
  }, [aylaToken, dsn, firebaseToken, navigate, publicIpAddress, setNewDeviceDsn]);

  return (
    <Layout>
      <BackButton />
      <Content>
        <BaseStation src={DeviceImage} alt={t('Base Station image')} />
        <Title>{t('Click and hold down on the base station until you hear 2 beeps.')}</Title>
        <Body>{t('It can take up to 10 seconds to register after pushing the button.')}</Body>
      </Content>
    </Layout>
  );
};

export default RegisterDevice;
