import styled from 'styled-components';
import { useTranslation } from 'react-i18next';
import { Body, PageHeading } from '../../components/Typography';
import Button from '../../components/Button/Button';
import BackButton from '../../components/Button/BackButton';
import SockBase from '../../assets/onboarding_sock_contents.svg';
import { basePlugIn } from './routes';

const Layout = styled.div`
  display: flex;
  flex-direction: column;
  position: relative;
  `;

const Content = styled.div`
  display: flex;
  flex-direction: column;
  padding: 20px;
`;

const DeviceImage = styled.img`
  width: 100%;
  height: 100%;
  display: flex;
  align-self: center;
  margin-bottom: 30px;
`;

const ButtonContainer = styled.div`
  padding: 20px;
`;

const BaseSetup = () => {
  const { t } = useTranslation();
  return (
    <Layout>
      <BackButton />
      <Content>
        <DeviceImage src={SockBase} alt="Owl image" />
        <PageHeading>{t('Let’s set up your Sock and Base Station.')}</PageHeading>
        <br />
        <Body>
          {t('First we’ll connect to Wi-Fi so you can see readings on your mobile device.')}
        </Body>
        <br />
        <Body>
          {t('Then we’ll walk you through how to use your Sock.')}
        </Body>
      </Content>
      <ButtonContainer>
        <Button label={t('Get Started')} to={basePlugIn()} />
      </ButtonContainer>
    </Layout>
  );
};

export default BaseSetup;
