import styled from 'styled-components';
import { useTranslation } from 'react-i18next';
import { Body, PageHeading } from '../Typography';
import { Icon } from '../Icon';
import theme from '../../design/theme';

const BodyHeading = styled(Body)`
color: ${theme.color.tertiary.default};
`;

const StyledBody = styled(Body)`
font-weight: 600;
`;

const IconContainer = styled.div`
  display: flex;
  justify-content: center;
  padding: 10px 0;
`;

const ModalBabyStatus = () => {
  const { t } = useTranslation();

  return (
    <div>
      <PageHeading>{t('Owlet Sock Statuses')}</PageHeading>
      <Body>{t('Sock statuses are based off of algorithms composed of heart rate, oxygen level, and movement detection. Available statuses for the Owlet Sock are listed below.')}</Body>
      <br />
      <StyledBody>{t('Baby is wiggling')}</StyledBody>
      <Body>{t('When your baby is moving a lot, these wiggling feet will appear.')}</Body>
      <br />
      <IconContainer>
        <Icon icon="Movement" fill={theme.color.primary.default} width={96} height={96} />
      </IconContainer>
      <br />
      <Body>{t('Movement impacts the quality of readings, so rather than notifying you unnecessarily, the readings and notifications will be momentarily paused until baby is holding still again.')}</Body>
      <br />
      <StyledBody>{t('High/Low Readings')}</StyledBody>
      <Body>{t("The smart sock has picked up a reading outside of Owlet's preset ranges. If you see a high/low status, check on your child immediately.")}</Body>
      <br />
      <BodyHeading>{t('Low Oxygen:')}</BodyHeading>
      <Body>{t("Baby's O2 dipped below 80%")}</Body>
      <br />
      <BodyHeading>{t('High Heart Rate:')}</BodyHeading>
      <Body>{t("Baby's HR rose above 220 BPM")}</Body>
      <br />
      <BodyHeading>{t('Low Heart Rate:')}</BodyHeading>
      <Body>{t("Baby's HR dipped below 60 BPM")}</Body>
    </div>
  );
};

export default ModalBabyStatus;
