import styled from 'styled-components';
import { useTranslation } from 'react-i18next';
import { Body } from '../../components/Typography';
import BottomTextAndArrow from '../../components/FormComponents/BottomTextAndArrow';
import BackButton from '../../components/Button/BackButton';
import BaseImage from '../../assets/onboarding_device_plugin.svg';
import theme from '../../design/theme';
import { wifiInstructions } from './routes';

const Layout = styled.div`
  display: flex;
  flex-direction: column;
  position: relative;
  `;

const Content = styled.div`
  display: flex;
  flex-direction: column;
  padding: 20px;
`;

const DeviceImage = styled.img`
  width: 100%;
  height: 100%;
  display: flex;
  align-self: center;
  margin-bottom: 30px;
`;

const BottomRow = styled.div`
  position: fixed;
  width: 90%;
  bottom: 0;
  padding: 40px 20px;
  background-color: ${theme.color.background.darkMode.darker};
`;

const BasePlugIn = () => {
  const { t } = useTranslation();
  return (
    <Layout>
      <BackButton />
      <Content>
        <DeviceImage src={BaseImage} alt={t('Owl image')} />
        <Body>
          {t('1. Plug the base station into the wall.')}
        </Body>
        <br />
        <Body>
          {t('2. Make sure bluetooth is enabled on your mobile device.')}
        </Body>
      </Content>
      <BottomRow>
        <BottomTextAndArrow buttonText={t('I plugged in the base station')} route={wifiInstructions()} />
      </BottomRow>
    </Layout>
  );
};

export default BasePlugIn;
