import { useLocation, useMatch } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import Icon from '../Icon/Icon';
import { SmallLabel } from '../Typography';
import {
  history,
  serviceUserHistory,
  serviceUserInfo,
} from '../../navigation/routes';
import NavItem from './NavItem';

const HistoryIcon = () => {
  const { pathname } = useLocation();
  const childHistoryRoute = !!useMatch(serviceUserHistory());
  const serviceUserInfoRoute = useMatch(serviceUserInfo());
  const { t } = useTranslation();

  const currentServiceUser = serviceUserInfoRoute?.params?.serviceUserKey;
  const historyRoute = currentServiceUser ? serviceUserHistory(currentServiceUser) : history();
  const activeRoute = childHistoryRoute ? pathname : historyRoute;

  return (
    <NavItem to={`${activeRoute}`}>
      <Icon
        icon="NavHistory"
        width={24}
        height={24}
        style={pathname.startsWith(history()) ? {
          fill: 'rgb(215, 255, 211)',
        } : {
          fill: 'rgb(225, 207, 236)',
        }}
      />
      <SmallLabel style={pathname.startsWith(history()) ? {
        color: 'rgb(215, 255, 211)',
      } : {
        color: 'rgb(225, 207, 236)',
      }}
      >
        {t('History')}
      </SmallLabel>
    </NavItem>
  );
};

export default HistoryIcon;
