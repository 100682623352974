import { HistoryContextAction } from '.';
import { History } from '../context';

export default (state: History, action: HistoryContextAction) => {
  const newState: History = {};
  action.payload.forEach((result) => {
    if (!state[result.key] || state[result.key].error) {
      newState[result.key] = { data: result.data, error: result.error };
    }
  });
  return { ...state, ...newState };
};
