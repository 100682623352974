import styled from 'styled-components';
import { useTranslation } from 'react-i18next';
import { Body, Heading, PageHeading } from '../Typography';
import theme from '../../design/theme';
import IconDaytime from '../../assets/session_daytime.svg';
import IconNighttime from '../../assets/session_nighttime.svg';

const ListItem = styled.div`
  display: flex;
  align-items: center;
  padding: 10px 0;
  margin: 10px 0;
`;

const StyledPageHeading = styled(PageHeading)`
  color: ${theme.color.primary.default};
`;

const StyledBody = styled(Body)`
  padding-left: 10px;
`;

const ModalSleepSessions = () => {
  const { t } = useTranslation();

  return (
    <div>
      <StyledPageHeading>{t('Sleep Sessions')}</StyledPageHeading>
      <Body>
        {t('A sleep session is a period of time when your child slept, while using the Owlet Smart Sock')}
      </Body>
      <ListItem>
        <img
          alt={t('Daytime Icon')}
          src={IconDaytime}
          width={20}
        />
        <StyledBody>{t('A daytime sleep session')}</StyledBody>
      </ListItem>
      <ListItem>
        <img
          alt={t('Nighttime Icon')}
          src={IconNighttime}
          width={20}
        />
        <StyledBody>{t('A nighttime sleep session')}</StyledBody>
      </ListItem>
      <Heading>{t('Sleep Quality')}</Heading>
      <Body>
        {t('This status is determined based off of the amount of awake time in a given sleep session')}
      </Body>
      <br />
      <Heading>{t('Sleep Onset')}</Heading>
      <Body>{t('The time it takes your child to fall asleep')}</Body>
      <br />
      <Heading>{t('Wakings')}</Heading>
      <Body>{t('The number of times your child wakes up in a sleep session')}</Body>
      <br />
      <Heading>{t('Longest Sleep Segment')}</Heading>
      <Body>{t('The longest segment of continuous sleep in a session')}</Body>
    </div>
  );
};

export default ModalSleepSessions;
