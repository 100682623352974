import styled from 'styled-components';
import { useTranslation } from 'react-i18next';
import theme from '../../design/theme';
import { Body } from '../Typography';
import Icon from '../Icon/Icon';

const Container = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  min-height: 40px;
  background-color: #FFC45E;
  padding: 10px;
`;

const StyledBody = styled(Body)`
  color: ${theme.color.background.darkMode.dark};
  font-size: 14px;
`;

interface ErrorBannerProps {
  onClose: () => void
  message?: string
}

const ErrorBanner = ({ onClose, message }: ErrorBannerProps) => {
  const { t } = useTranslation();
  const defaultMessage = t('Sorry there was an error, please try your action again.');
  return (
    <Container>
      <StyledBody>
        {message || defaultMessage}
      </StyledBody>
      <Icon icon="Close" onClick={onClose} />
    </Container>
  );
};

export default ErrorBanner;
