import { useState } from 'react';
import { getAuth, sendPasswordResetEmail } from 'firebase/auth';
import { FormControl } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { Screen, StyledInput, StyledInputLabel } from './Styles';
import Button from '../../components/Button/Button';
import { logIn } from '../../navigation/routes';
import { Body, PageHeading } from '../../components/Typography';
import BackButton from '../../components/Button/BackButton';
import ErrorBanner from '../../components/ErrorBanner';

const ForgotPassword = () => {
  const auth = getAuth();
  const [email, updateEmail] = useState<string>('');
  const [emailLinkSuccess, updateEmailLinkSuccess] = useState<boolean>(false);
  const [errorMessageView, handleError] = useState<string>();
  const { t } = useTranslation();

  const ForgotPasswordLink = () => {
    sendPasswordResetEmail(auth, email)
      .then((res) => {
        updateEmailLinkSuccess(true);
      })
      .catch((error) => {
        if (error.code === 'auth/invalid-email') {
          handleError(t('Invalid Email. Please try again.'));
        }
        if (error.code === 'auth/user-not-found') {
          handleError(t('There was an error please try again. You may have input an incorrect email.'));
        }
      });
  };

  return (
    <form>
      {!errorMessageView
        || (
          <ErrorBanner
            onClose={() => {
              handleError(undefined);
            }}
            message={errorMessageView}
          />
        )}
      <BackButton />
      <Screen>
        <PageHeading>{t('Forgot Password?')}</PageHeading>
        {!emailLinkSuccess ? (
          <>
            <Body>{t('Enter the email associated with your account.')}</Body>
            <FormControl variant="standard">
              <StyledInputLabel
                id="email"
              >
                {t('Email')}
              </StyledInputLabel>
              <StyledInput
                name="email"
                value={email}
                onChange={(e) => updateEmail(e.target.value)}
              />
            </FormControl>
            <Button
              label={t('Send password reset email')}
              size="large"
              onClick={() => ForgotPasswordLink()}
            />
          </>
        ) : (
          <>
            <div>{t('An email has been sent to...')}</div>
            <div>{email}</div>
            <Button
              label={t('Done')}
              size="large"
              to={logIn()}
            />
          </>
        )}
      </Screen>
    </form>
  );
};

export default ForgotPassword;
