import { HistoryActions, HistoryContextAction, updateHistory } from './actions';
import { History } from './context';

export default (state: History, action: HistoryContextAction): History => {
  switch (action.type) {
    case HistoryActions.UpdateHistory: {
      return updateHistory(state, action);
    }
    default:
      throw new Error();
  }
};
