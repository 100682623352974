import React from 'react';
import ReactDOM from 'react-dom';
import './backend/config/i18n';
import './ui/design/index.css';
import App from './App';
// import reportWebVitals from './backend/utils/reportWebVitals';
import initRUM from './backend/services/datadog';
import { AuthProvider } from './backend/contexts/authContext';
import { ErrorProvider } from './backend/contexts/errorContext';
import { FeatureFlagProvider } from './backend/contexts/featureFlags';

import { getConfigSetting } from './backend/config/URLConfig';
import { ConfigVarName } from './shared/types';

ReactDOM.render(
  <React.StrictMode>
    <FeatureFlagProvider>
      <ErrorProvider>
        <AuthProvider>
          <App />
        </AuthProvider>
      </ErrorProvider>
    </FeatureFlagProvider>
  </React.StrictMode>,
  document.getElementById('root'),
);

// Enable Datadog RUM
if (getConfigSetting(ConfigVarName.ENABLE_DD_RUM)) {
  initRUM();
}

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
// reportWebVitals(undefined);
