import { Link } from 'react-router-dom';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';
import { useNavigate, useParams } from 'react-router';
import List from '@mui/material/List';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import { useState } from 'react';
import { AddCircleOutlineOutlined } from '@mui/icons-material';
import theme from '../../design/theme';
import { PageHeading, PageTitle } from '../../components/Typography';
import BackButton from '../../components/Button/BackButton';
import { childIsBorn, success } from './routes';
import ChildList from '../Device/ChildList';
import { useDeviceContext } from '../../../backend/contexts/deviceContext';
import { emptyDevice } from '../../../shared/types';

const AssignChildStep = () => {
  const { t } = useTranslation();
  const { deviceKey = '', serviceKey } = useParams();
  const { findDevice } = useDeviceContext();
  const deviceData = findDevice(`${deviceKey}`) || emptyDevice;
  const { dsn } = deviceData;
  const [nextButtonDisabled, setNextButtonDisabled] = useState(true);
  const navigation = useNavigate();

  const handleSubmit = (e: React.MouseEvent<HTMLButtonElement>) => {
    setNextButtonDisabled(true);
    e.preventDefault();
    navigation(success());
  };
  return (
    <Layout>
      <BackButton />
      <Content>
        <PageHeading>{t('Please select who will be using this Smart Sock.')}</PageHeading>
        <List onClick={() => setNextButtonDisabled(false)}>
          <ChildList
            dsn={dsn}
            serviceKey={`${serviceKey}`}
          />
          <AddButton
            to={childIsBorn()}
          >
            <AddCircleOutlineOutlined fontSize="small" />
            <LinkText>{t('Add Child')}</LinkText>
          </AddButton>
        </List>
        <BottomRow>
          <button
            style={nextButtonDisabled ? nextButtonDisabledStyle : nextButtonStyle}
            type="submit"
            onClick={(e) => handleSubmit(e)}
            disabled={nextButtonDisabled}
          >
            {t('Next')}
            <ArrowForwardIosIcon
              style={nextButtonDisabled ? nextButtonDisabledIconStyle : nextButtonIconStyle}
            />
          </button>
        </BottomRow>
      </Content>
    </Layout>
  );
};

export default AssignChildStep;

const Layout = styled.div`
  display: flex;
  flex-direction: column;
`;

const Content = styled.div`
  display: flex;
  flex-direction: column;
  padding: 20px;
`;

const AddButton = styled(Link)`
  display: flex;
  align-items: center;
  color: ${theme.color.primary.default};
  margin-left: 0vw;
  padding: 8px 0;
`;

const BottomRow = styled.div`
  position: fixed;
  display: flex;
  justify-content: flex-end;
  width: 90%;
  bottom: 0;
  padding: 40px 0;
  background-color: ${theme.color.background.darkMode.darker};
`;

const nextButtonStyle = {
  display: 'flex',
  justifyContent: 'flex-end',
  alignItems: 'center',
  backgroundColor: 'transparent',
  border: 'none',
  fontFamily: 'Source Sans Pro',
  color: '#73C7AD',
  fontSize: '20px',
};

const nextButtonDisabledStyle = {
  display: 'flex',
  alignItems: 'center',
  backgroundColor: 'transparent',
  border: 'none',
  fontFamily: 'Source Sans Pro',
  color: '#676767',
  fontSize: '20px',
};

const nextButtonDisabledIconStyle = {
  color: '#676767',
  fontSize: '18px',
  paddingLeft: '5px',
};

const nextButtonIconStyle = {
  color: '#73C7AD',
  fontSize: '18px',
  paddingLeft: '5px',
};

const LinkText = styled(PageTitle)`
  margin-left: 6px;
  color: ${theme.color.primary.default};
  font-weight: 500;
`;
