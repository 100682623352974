import styled from 'styled-components';
import { useTranslation } from 'react-i18next';
import { Body, PageHeading } from '../Typography';
import { Icon } from '../Icon';
import theme from '../../design/theme';
import ChartCircles from '../../assets/chart_circles.svg';

const ListItem = styled.div`
  display: flex;
  align-items: center;
  padding: 10px 0;
  margin-top: 10px;
`;

const StyledPageHeading = styled(PageHeading)`
  color: ${theme.color.primary.default};
`;

const StyledBody = styled(Body)`
  margin-left: 10px;
  font-weight: 600;
`;

const ModalChartLine = () => {
  const { t } = useTranslation();

  return (
    <div>
      <StyledPageHeading>{t('Oxygen and Heart Rate Data')}</StyledPageHeading>
      <Body>{t('Oxygen and heart rate are charted in 10 min averages, and updated every 30 minutes.')}</Body>
      <br />
      <Body>{t('Graph data is stored for 30 days.')}</Body>
      <br />
      <ListItem>
        <img src={ChartCircles} alt={t('Session icons')} />
        <StyledBody>{t('Beginning and end of session')}</StyledBody>
      </ListItem>
      <ListItem>
        <Icon icon="ChartDay" width={24} height={24} fill={theme.color.text.darkMode.primary} />
        <StyledBody>{t('New day')}</StyledBody>
      </ListItem>
    </div>
  );
};

export default ModalChartLine;
