import styled from 'styled-components';
import { useTranslation } from 'react-i18next';
import { Body, PageHeading } from '../Typography';
import theme from '../../design/theme';

const ListItem = styled.div`
  display: flex;
  align-items: center;
  padding: 10px 0;
  margin-top: 10px;
`;

const StyledPageHeading = styled(PageHeading)`
  color: ${theme.color.primary.default};
`;

const StyledBody = styled(Body)`
  margin-left: 10px;
  font-weight: 600;
`;

const Box = styled.div`
  height: 16px;
  width: 16px;
  border-radius: 2px;
`;

const Awake = styled(Box)`
  background: ${theme.color.data.awake};
`;

const LightSleep = styled(Box)`
  background: ${theme.color.data.lightSleep};
`;

const DeepSleep = styled(Box)`
  background: ${theme.color.data.deepSleep};
`;

const ModalChartBar = () => {
  const { t } = useTranslation();

  return (
    <div>
      <StyledPageHeading>{t('Sleep Data')}</StyledPageHeading>
      <Body>{t('Our sleep algorithms were carefully created based on heart rate, oxygen and movement; and are used to determine deep and light sleep, as well as awake periods. There are several factors that may impact the accuracy of these algorithms, such as co-sleeping, swings/bouncers, rocking, etc.')}</Body>
      <br />
      <Body>{t('Graph data and notifications are stored for 30 days.')}</Body>
      <ListItem>
        <Awake />
        <StyledBody>{t('Awake')}</StyledBody>
      </ListItem>
      <ListItem>
        <LightSleep />
        <StyledBody>{t('Light Sleep')}</StyledBody>
      </ListItem>
      <ListItem>
        <DeepSleep />
        <StyledBody>{t('Deep Sleep')}</StyledBody>
      </ListItem>
    </div>
  );
};

export default ModalChartBar;
