import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import styled from 'styled-components';
import { useAylaContext } from '../../../backend/contexts/aylaContext';
import { checkOTAUpdates, oTAUpdatesSuccess } from './routes';
import { useDeviceSetupContext } from '../../../backend/contexts/deviceSetupContext';
import { SmartSockOTAState } from '../../../shared/types';
import OwlIcon from '../../assets/owl_lookdown.svg';
import { Body, PageHeading } from '../../components/Typography';

const Layout = styled.div`
  display: flex;
  flex-direction: column;
  `;

const Content = styled.div`
  display: flex;
  flex-direction: column;
  padding: 20px;
`;

const ImageContainer = styled.img`
  width: 100px;
  height: 100px;
  margin: 30px 0;
  align-self: center;
`;

const CheckOTAUpdatesPaused = () => {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const { dsn } = useDeviceSetupContext();
  const { deviceDetails } = useAylaContext();

  useEffect(() => {
    if (dsn) {
      const otaState = deviceDetails[dsn]?.ota ?? SmartSockOTAState.unknown;

      if (otaState === SmartSockOTAState.none) {
        navigate(oTAUpdatesSuccess());
      } else if (otaState !== SmartSockOTAState.waitingForSockToBePluggedIn) {
        navigate(checkOTAUpdates());
      }
    }
  }, [deviceDetails, dsn, navigate]);

  return (
    <Layout>
      <Content>
        <ImageContainer
          alt={t('Owl Icon')}
          src={OwlIcon}
        />
        <PageHeading>{t('Sock update paused')}</PageHeading>
        <Body>{t('To finish the update, please insert the sock into the Base Station.')}</Body>
      </Content>
    </Layout>
  );
};

export default CheckOTAUpdatesPaused;
