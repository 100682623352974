import styled from 'styled-components';
import { useTranslation } from 'react-i18next';
import theme from '../../design/theme';
import OwletLogo from '../../assets/owlet_logo.jpg';

const Container = styled.div`
  background-color: ${theme.color.background.darkMode.dark};
  height: 80px;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  position: sticky;
  top: 0;
  z-index: 1;
`;

const HomeTopBannerLogo = styled.div`
  height: 30px;
  width: 100px;
`;

const Logo = styled.img`
  width: 100%;
  height: 100%;
`;

const NavHeaderHome = () => {
  const { t } = useTranslation();
  return (
    <Container>
      <HomeTopBannerLogo>
        <Logo
          alt={t('Owlet Logo')}
          src={OwletLogo}
        />
      </HomeTopBannerLogo>
    </Container>
  );
};

export default NavHeaderHome;
