import { Route, Routes } from 'react-router-dom';
import AccountInfo from './AccountInfo/AccountInfo';
import UserInfo from './views/UserInfo';

// confirm types are as expected from the db.
// manage this model usually this is where typescript is useful.

const routes = [
  { path: 'account-info', Component: AccountInfo },
  { path: 'user-info', Component: UserInfo },
];

const Account = () => (
  <Routes>
    {routes.map(({ path, Component }) => (
      <Route
        key={path}
        path={path}
        element={<Component />}
      />
    ))}
  </Routes>
);

export default Account;

// Accounts /accounts/{uid}
// {
//   "accountAttribute": "value",
//   "serviceKeys": {
//     "1ef395e8-0236-4d19-91f2-8cbd787b9684": true,
//     "6afd0b6f-c4aa-404f-9754-09e452d1a4f5": true,
//     "9817a0e5-109e-4fec-8311-2c1d7833fbe2": true
//   },
//   "serviceUserKeys" : {
//     "-Lq94gq7kUfYQyJCa7Iz": true,
//     "-M9Zrzjd7OwbmwKexig-": true,
//     "-MGEBQPRdEjyFybY3Tgj": true,
//     "-Meb0fUaYJTNKxJ-QGlI": true,
//     "-Meb0l7vO1WW09WthcZE": true,
//     "-Meb0qeI4VG9MUVluHx_": true,
//     "-Meb11KpNhAf0jctvKGq": true,
//     "-Meb1BXwsJ0_DwkTIDQL": true,
//     "-Meb1GJYW3H_yyNl_UXk": true,
//     "-Meb1KcTjpTxecvn2ho-": true,
//     "-Mfzsexqa7LoNYxrT2ny": true
//   }
// }
