import { Link } from 'react-router-dom';
import styled from 'styled-components';

const NavItem = styled(Link)`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 0 8px;
  text-decoration: none;
`;

export default NavItem;
