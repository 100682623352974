import styled from 'styled-components';
import { useTranslation } from 'react-i18next';
import theme from '../../design/theme';
import Icon from '../Icon/Icon';
import { SmallLabel } from '../Typography';
import { HistoryTab } from '../../../shared/types';
import useFeatureFlag from '../../../backend/hooks/useFeatureFlag';
import { FeatureFlags } from '../../../shared/constants';

const NavbarContainer = styled.div`
  display: flex;
  justify-content: space-around;
  align-items: center;
  background-color: ${theme.color.background.darkMode.dark};
  min-height: 55px;
  margin-top: 1px;
`;

const NavItem = styled.button`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 0 8px;
  text-decoration: none;
`;

type SubNavProps = {
  activeTab: HistoryTab.OVERVIEW | HistoryTab.GRAPH;
  setHistoryTab: (v: HistoryTab) => void;
};

const SubNav = ({ activeTab, setHistoryTab }: SubNavProps) => {
  const showGraphs = useFeatureFlag(FeatureFlags.HISTORY_GRAPHS);
  const { t } = useTranslation();

  const tabActive = (tab: HistoryTab) => tab === activeTab;

  return (
    <NavbarContainer>
      <NavItem onClick={() => setHistoryTab(HistoryTab.OVERVIEW)}>
        <Icon
          icon="NavOverview"
          width={24}
          height={24}
          style={tabActive(HistoryTab.OVERVIEW) ? {
            fill: theme.color.primary.default,
          } : {
            fill: theme.color.text.darkMode.primary,
          }}
        />
        <SmallLabel style={tabActive(HistoryTab.OVERVIEW) ? {
          color: theme.color.primary.default,
        } : {
          color: theme.color.text.darkMode.primary,
        }}
        >
          {t('Overview')}
        </SmallLabel>
      </NavItem>
      {showGraphs
        ? (
          <NavItem onClick={() => setHistoryTab(HistoryTab.GRAPH)}>
            <Icon
              icon="NavGraph"
              width={24}
              height={24}
              style={tabActive(HistoryTab.GRAPH) ? {
                fill: theme.color.primary.default,
              } : {
                fill: theme.color.text.darkMode.primary,
              }}
            />
            <SmallLabel style={tabActive(HistoryTab.GRAPH) ? {
              color: theme.color.primary.default,
            } : {
              fill: theme.color.text.darkMode.primary,
            }}
            >
              {t('Graphs')}
            </SmallLabel>
          </NavItem>
        ) : null}

    </NavbarContainer>
  );
};

export default SubNav;
