import { HistoryPayload } from '../context';
import updateHistory from './updateHistory';

export enum HistoryActions {
  UpdateHistory = 'UpdateHistory',
}

type UpdateHistoryAction = {
  type: HistoryActions.UpdateHistory;
  payload: HistoryPayload[];
}

type HistoryContextAction = UpdateHistoryAction

export type { UpdateHistoryAction, HistoryContextAction };

export { updateHistory };
