import {
  createContext, useCallback, useContext, useEffect, useState,
} from 'react';
import _ from 'lodash';
import useLogout from '../hooks/useLogout';
import setFirebaseObserver from '../utils/firebaseObserver';
import { Device, FirebaseResponseStatus } from '../../shared/types';
import { useServiceContext } from './serviceContext';

type DeviceProviderProps = { children: React.ReactNode }

type NormalizedDevices = {[key:string]: Device}

type StateType = {
  findDevice: (s: string) => Device | undefined,
  findDeviceKeyByDsn: (s: string) => string | undefined,
  devices: NormalizedDevices,
  deviceList: Device[],
  status: FirebaseResponseStatus,
};

const initialState: StateType = {
  findDevice: (_s) => undefined,
  findDeviceKeyByDsn: (_s) => undefined,
  devices: {},
  deviceList: [],
  status: FirebaseResponseStatus.idle,
};

const Context = createContext<StateType>(initialState);

const useDeviceContext = () => {
  const context = useContext(Context);
  if (context === undefined) {
    throw new Error('ServiceContext context must be used within an ServiceProvider');
  }
  return context;
};

const DeviceProvider = ({ children }: DeviceProviderProps) => {
  const { servicesList } = useServiceContext();
  const [devices, setDevices] = useState<NormalizedDevices>(initialState.devices);
  const [status, setStatus] = useState<FirebaseResponseStatus>(initialState.status);
  const deviceList = Object.values(devices);
  const findDevice = useCallback((deviceKey: string) => (
    devices[deviceKey]
  ), [devices]);

  const findDeviceKeyByDsn = useCallback((dsn: string) => (
    _.findKey(devices, (device) => device.dsn === dsn)
  ), [devices]);

  useEffect(() => {
    const unsubscribeObservers: (() => void)[] = [];
    const deviceKeys = servicesList.map((device) => device.deviceKey);

    setDevices((previousDevices) => Object.keys(previousDevices).reduce(
      (acc, key) => {
        if (!deviceKeys.includes(key)) {
          return acc;
        }
        return { ...acc, ...{ [key]: previousDevices[key] } };
      },
      initialState.devices,
    ));

    servicesList.forEach(({ deviceKey }) => {
      const refKey = `devices/${deviceKey}`;
      const setDeviceData = (
        newStatus: FirebaseResponseStatus,
        newDevice: Device,
      ) => {
        if (newDevice) {
          const newDeviceNormalized = { [deviceKey]: newDevice };

          setDevices(
            (previousDevices) => (
              { ...previousDevices, ...newDeviceNormalized }),
          );
        }
        setStatus(newStatus);
      };

      const observer = setFirebaseObserver<Device>(refKey, setDeviceData);
      unsubscribeObservers.push(observer);
    });

    return () => {
      unsubscribeObservers.forEach((unsubscribe) => {
        unsubscribe();
      });
    };
  }, [servicesList]);

  const clearDevices = useCallback(() => {
    setDevices(initialState.devices);
    setStatus(initialState.status);
  }, []);

  useLogout(clearDevices);

  return (
    <Context.Provider value={{
      devices, deviceList, findDevice, findDeviceKeyByDsn, status,
    }}
    >
      {children}
    </Context.Provider>
  );
};

export { DeviceProvider, useDeviceContext };
