import { useTranslation } from 'react-i18next';
import DeviceCard from './DeviceCard';
import InfoCard from '../Home/InfoCard';
import SockImage from '../../assets/info_product_sock.png';
import TopBanner from './TopBanner';
import theme from '../../design/theme';
import { useAylaContext } from '../../../backend/contexts/aylaContext';
import { useDeviceContext } from '../../../backend/contexts/deviceContext';
import { useServiceUserContext } from '../../../backend/contexts/serviceUserContext';
import { emptyDevice, emptyServiceUser } from '../../../shared/types';

const homeInfoArea = {
  height: '100%',
  background: theme.color.background.darkMode.darker,
  overflowY: 'scroll' as const,
};

type ChildProfileProps = {
  serviceUserKey: string;
  showBackButton?: boolean
}

const ChildProfile = ({ serviceUserKey, showBackButton = false }: ChildProfileProps) => {
  const { deviceDetails } = useAylaContext();
  const { findServiceUser } = useServiceUserContext();
  const { findDevice } = useDeviceContext();
  const { service } = findServiceUser(serviceUserKey) || emptyServiceUser;
  const { dsn } = findDevice(`${service?.deviceKey}`) || emptyDevice;
  const details = deviceDetails[dsn] || {};
  const { t } = useTranslation();

  return (
    <div>
      <div style={homeInfoArea}>
        <TopBanner
          hasDevice={!!service}
          showBackButton={showBackButton}
          deviceDetails={details}
          serviceUserKey={serviceUserKey}
        />
        { service ? (
          <DeviceCard
            serviceUserKey={serviceUserKey}
            deviceDetails={details}
            deviceKey={service?.deviceKey}
          />
        ) : (
          <InfoCard ImageUrl={SockImage} title={t('Learn more about the Owlet Smart Sock')} />
        )}
      </div>
    </div>
  );
};

export default ChildProfile;
