import { Navigate } from 'react-router-dom';
import styled from 'styled-components';
import { useServiceUserContext } from '../../../backend/contexts/serviceUserContext';
import { emptyServiceUser } from '../../../shared/types';
import EmptyOverview from '../../components/History/EmptyOverview';
import { serviceUserHistory } from '../../navigation/routes';

const Layout = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  overflow: scroll;
`;

const HistoryWrapper = () => {
  const { serviceUsersList } = useServiceUserContext();
  const { id } = serviceUsersList[0] || emptyServiceUser;

  if (id) {
    return <Navigate to={serviceUserHistory(id)} />;
  }

  return (
    <Layout>
      <EmptyOverview />
    </Layout>
  );
};

export default HistoryWrapper;
