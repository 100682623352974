import AdapterDateFns from '@mui/lab/AdapterDateFns';
import LocalizationProvider from '@mui/lab/LocalizationProvider';
import DesktopDatePicker from '@mui/lab/DesktopDatePicker';
import styled from 'styled-components';
import TextField from '@mui/material/TextField';
import {
  subDays,
  addDays,
  isSameDay,
  format,
} from 'date-fns';
import Icon from '../Icon/Icon';
import theme from '../../design/theme';
import { useHistoryContext } from '../../../backend/contexts/history';
import { PageTitle } from '../Typography';

const Container = styled.div`
  display: grid;
  grid-template-columns: 15% 70% 15%;
  flex-direction: row;
  align-items: center;
  background-color: ${theme.color.background.darkMode.dark};
`;

const DateControls = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`;

const DateHeading = styled(PageTitle)`
  padding: 0 10px;
`;

const DatePickerHeader = () => {
  const { historyDate: date, setHistoryDate: setDate } = useHistoryContext();
  const currentDate = new Date();

  const formattedDate = () => (
    date ? format(date, 'EEE, MMM d') : ''
  );

  const subDay = (): void => {
    if (date && !isSameDay(subDays(currentDate, 30), date)) setDate(subDays(date, 1));
  };

  const addDay = (): void => {
    if (date && !isSameDay(currentDate, date)) setDate(addDays(date, 1));
  };

  const rightIconColor = () => (
    date && !isSameDay(currentDate, date) ? theme.color.text.darkMode.primary : 'transparent'
  );

  const leftIconColor = () => (
    date && !isSameDay(subDays(currentDate, 30), date) ? theme.color.text.darkMode.primary : 'transparent'
  );

  return (
    <LocalizationProvider dateAdapter={AdapterDateFns}>
      <Container>
        <DesktopDatePicker
          value={date}
          onChange={(newDate) => {
            setDate(newDate);
          }}
          openTo="day"
          minDate={subDays(currentDate, 30)}
          maxDate={currentDate}
          renderInput={(params) => (
            <TextField
              {...params}
              sx={{
                input: {
                  padding: '16.5px 0px',
                  width: '0px',
                },
                button: {
                  color: theme.color.text.darkMode.primary,
                },
                fieldset: {
                  border: 'none',
                },
              }}
            />
          )}
        />
        <DateControls>
          <Icon icon="ChevronLeft" onClick={() => subDay()} width={24} height={24} fill={leftIconColor()} data-testid="subDay" />
          <DateHeading>
            {formattedDate()}
          </DateHeading>
          <Icon icon="ChevronRight" onClick={() => addDay()} width={24} height={24} fill={rightIconColor()} data-testid="addDay" />
        </DateControls>
      </Container>
    </LocalizationProvider>
  );
};

export default DatePickerHeader;
