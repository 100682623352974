import styled from 'styled-components';
import theme from '../../design/theme';
import { Caption } from '../Typography';
import Icon from '../Icon/Icon';
import MinutesToHoursAndMinutes from '../Time';

const Layout = styled.section`
  margin: 20px 0 40px;
`;

const PillContainer = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  grid-template-rows: 1fr;
  grid-gap: 8px;
  margin-top: 20px;
`;

const Pill = styled.section`
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: ${theme.color.background.darkMode.dark};
  width: 100%;
  height: 36px;
  border-radius: 50px;
`;

const StyledCaption = styled(Caption)`
  margin-left: 6px;
`;

interface DayTotalsProps {
  sleepMinutes: number;
  totalAvgs: {
    avgHeart: number | string,
    avgOxygen: number | string,
  }
}

const DayTotals = ({ sleepMinutes, totalAvgs }: DayTotalsProps) => (
  <Layout>
    <PillContainer>
      <Pill>
        <Icon icon="StatusSleep" width={16} height={16} fill={theme.color.tertiary.rhythm} />
        <StyledCaption><MinutesToHoursAndMinutes totalMinutes={sleepMinutes} /></StyledCaption>
      </Pill>
      <Pill>
        <Icon icon="StatusOxygen" width={16} height={16} fill={theme.color.tertiary.wave} />
        <StyledCaption>
          {`${totalAvgs.avgOxygen}% avg`}
        </StyledCaption>
      </Pill>
      <Pill>
        <Icon icon="StatusHeartrate" width={16} height={16} fill={theme.color.tertiary.strawberry} />
        <StyledCaption>
          {`${totalAvgs.avgHeart} avg`}
        </StyledCaption>
      </Pill>
    </PillContainer>
  </Layout>
);

export default DayTotals;
