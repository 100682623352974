const breakpoints = {
  min: 300,
  xsmall: 420,
  small: 640,
  med: 768,
  large: 960,
  xlarge: 1300,
};

export default breakpoints;
