import { createContext, useContext } from 'react';
// eslint-disable-next-line @typescript-eslint/no-unused-vars
import _splitio from '@splitsoftware/splitio-react';

interface FeatureFlagContextState {
  userInit: (userKey: string) => void
  userDeinit: () => void
  featureFlagClient?: SplitIO.IClient
}

const initialState: FeatureFlagContextState = {
  userInit: (_) => undefined,
  userDeinit: () => undefined,
};

const Context = createContext<FeatureFlagContextState>(initialState);

const useFeatureFlagContext = () => {
  const context = useContext(Context);
  if (context === undefined) {
    throw new Error('FeatureFlagContext context must be used within a FeatureFlagProvider');
  }
  return context;
};

export { useFeatureFlagContext };
export default Context;
