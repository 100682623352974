/* eslint-disable @typescript-eslint/no-explicit-any */
// useFormHook for reference: https://github.com/fgerschau/react-custom-form-validation-example/blob/master/src/useForm.ts
import { useState } from 'react';

const useForm = (options?: {
    validations?: any,
    initialValues?: any
    onSubmit?: any;
}) => {
  const [data, setData] = useState(options?.initialValues || {});
  const [errors, setErrors] = useState<any>({});

  const handleChange = (key: string, value: any) => {
    setData({
      ...data,
      [key]: value,
    });
  };

  const handleSubmit = async () => {
    const validations = options?.validations;
    if (validations) {
      let valid = true;
      const newErrors: any = {};
      Object.keys(validations).forEach((key: string) => {
        const value = data[key];
        const validation = validations[key];
        if (validation?.required?.value && !value) {
          valid = false;
          newErrors[key] = validation?.required?.message;
        }

        const pattern = validation?.pattern;
        if (pattern?.value && !RegExp(pattern.value).test(value)) {
          valid = false;
          newErrors[key] = pattern.message;
        }

        const custom = validation?.custom;
        if (custom?.isValid && !custom.isValid(value)) {
          valid = false;
          newErrors[key] = custom.message;
        }
      });

      if (!valid) {
        setErrors(newErrors);

        return;
      }
    }

    setErrors({});

    options?.onSubmit?.();
  };

  return {
    data,
    handleChange,
    handleSubmit,
    errors,
  };
};

export default useForm;
