import {
  createContext, useCallback, useContext, useEffect, useMemo, useState,
} from 'react';
import _ from 'lodash';
import useLogout from '../hooks/useLogout';
import setFirebaseObserver from '../utils/firebaseObserver';
import {
  FirebaseResponseStatus, Service, ServiceUser,
} from '../../shared/types';
import { useAccountContext } from './accountContext';
import { useServiceContext } from './serviceContext';

type ServiceUserProviderProps = { children: React.ReactNode }

type NormalizedServiceUsers = {[key:string]: ServiceUser}

type StateType = {
  findServiceUser: (s: string) => ServiceUser | undefined,
  addServiceUser: (s: NormalizedServiceUsers) => void,
  removeServiceUser: (s: string) => void,
  serviceUsers: NormalizedServiceUsers,
  serviceUsersList: ServiceUser[],
  status: FirebaseResponseStatus,
};

const initialState: StateType = {
  findServiceUser: (_s) => undefined,
  addServiceUser: (_s) => undefined,
  removeServiceUser: (_s) => undefined,
  serviceUsers: {},
  serviceUsersList: [],
  status: FirebaseResponseStatus.idle,
};

const Context = createContext<StateType>(initialState);

const useServiceUserContext = () => {
  const context = useContext(Context);
  if (context === undefined) {
    throw new Error('ServiceContext context must be used within an ServiceProvider');
  }
  return context;
};

const findServiceForUser = (serviceUserKey: string, services: Service[]) => (
  services.find((service) => {
    const serviceUserKeys = Object.keys(service.serviceUserKeys);
    return serviceUserKeys.includes(serviceUserKey);
  })
);

const ServiceUserProvider = ({ children }: ServiceUserProviderProps) => {
  const { account } = useAccountContext();
  const { servicesList } = useServiceContext();
  const [serviceUsers, setServiceUsers] = useState<NormalizedServiceUsers>(
    initialState.serviceUsers,
  );
  const [status, setStatus] = useState<FirebaseResponseStatus>(initialState.status);
  const serviceUsersList = useMemo(() => Object.values(serviceUsers), [serviceUsers]);
  const findServiceUser = useCallback((serviceUserKey: string) => (
    serviceUsers[serviceUserKey]
  ), [serviceUsers]);

  const addServiceUser = useCallback((user: NormalizedServiceUsers) => {
    setServiceUsers((prevServiceUsers) => ({ ...prevServiceUsers, ...user }));
  }, []);

  const removeServiceUser = useCallback((key: string) => {
    setServiceUsers((prevServiceUsers) => (_.omit(prevServiceUsers, [key])));
  }, []);

  useEffect(() => {
    const unsubscribeObservers: (() => void)[] = [];
    const userList = Object.keys(account.serviceUserKeys);

    setServiceUsers((previousServiceUsers) => Object.keys(previousServiceUsers).reduce(
      (acc, key) => {
        if (!userList.includes(key)) {
          return acc;
        }
        return { ...acc, ...{ [key]: previousServiceUsers[key] } };
      },
      initialState.serviceUsers,
    ));

    userList.forEach((serviceUserKey) => {
      const refKey = `serviceUsers/${serviceUserKey}`;
      const setServiceUserData = (
        newStatus: FirebaseResponseStatus,
        newServiceUser: ServiceUser,
      ) => {
        if (newServiceUser) {
          const newServiceUserNormalized = {
            [serviceUserKey]: {
              ...newServiceUser,
              service: findServiceForUser(serviceUserKey, servicesList),
            },
          };

          setServiceUsers(
            (previousServiceUsers) => ({ ...previousServiceUsers, ...newServiceUserNormalized }),
          );
        }
        setStatus(newStatus);
      };

      const observer = setFirebaseObserver<ServiceUser>(refKey, setServiceUserData);
      unsubscribeObservers.push(observer);
    });

    return () => {
      unsubscribeObservers.forEach((unsubscribe) => {
        unsubscribe();
      });
    };
  }, [account, servicesList]);

  const clearServiceUsers = useCallback(() => {
    setServiceUsers(initialState.serviceUsers);
    setStatus(initialState.status);
  }, []);

  useLogout(clearServiceUsers);

  return (
    <Context.Provider value={{
      addServiceUser,
      findServiceUser,
      removeServiceUser,
      serviceUsers,
      serviceUsersList,
      status,
    }}
    >
      {children}
    </Context.Provider>
  );
};

export { ServiceUserProvider, useServiceUserContext };
