import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { getAuth, createUserWithEmailAndPassword, sendEmailVerification } from 'firebase/auth';
import {
  FormControl, FormHelperText, InputAdornment, IconButton,
} from '@mui/material';
import { useTranslation, Trans } from 'react-i18next';
import styled from 'styled-components';
import zxcvbn, { IZXCVBNResult } from 'zxcvbn-typescript';
import Checkbox from '../../components/FormComponents/Checkbox';
import Button from '../../components/Button/Button';
import {
  Screen,
  StyledFormHelperText,
  StyledInput,
  StyledInputLabel,
  StyledPasswordOff,
  StyledPasswordOn,
} from './Styles';
import { confirmationEmail } from '../../navigation/routes';
import { PageHeading } from '../../components/Typography';
import BackButton from '../../components/Button/BackButton';
import ErrorBanner from '../../components/ErrorBanner';
import useForm from '../../../backend/hooks/useForm';
import theme from '../../design/theme';
import PasswordStrengthIndicator from './PasswordStrengthIndicator';

const ExternalListItem = styled.a`
  text-decoration: underline;
  color: ${theme.color.primary.default};
`;

const PasswordStrengthHelpText = styled.div`
  display: flex;
`;

const PASSWORD_MIN_LEN = 8;
const PASSWORD_MAX_LEN = 100;
const PASSWORD_MIN_SCORE = 2; // Strong

const Login = () => {
  const auth = getAuth();
  const navigate = useNavigate();
  const { t } = useTranslation();
  const [warningMessage, setWarningMessage] = useState<string>();
  const [passwordStrength, setPasswordStrength] = useState<IZXCVBNResult>();

  const checkPasswordValidity = (submittedPassword: string) => {
    if (submittedPassword.length < PASSWORD_MIN_LEN) {
      setWarningMessage(t('Password must be 8+ characters long'));
      return false;
    }
    if (submittedPassword.length >= PASSWORD_MAX_LEN) {
      setWarningMessage(t('Password must be less than 100 characters'));
      return false;
    }
    if (!/\d/.test(submittedPassword)) {
      setWarningMessage(t('Password must contain at least one number'));
      return false;
    }
    if (!/[A-Z]/.test(submittedPassword)) {
      setWarningMessage(t('Password must contain at least one uppercase letter'));
      return false;
    }
    if (!/[a-z]/.test(submittedPassword)) {
      setWarningMessage(t('Password must contain at least one lowercase letter'));
      return false;
    }
    if (!/[`!@#$%^&*()_+\-=[\]{};':"\\|,.<>/?~]/.test(submittedPassword)) {
      setWarningMessage(t('Password must contain at least one special character'));
      return false;
    }
    if (typeof passwordStrength !== 'undefined' && passwordStrength.score < PASSWORD_MIN_SCORE) {
      setWarningMessage('Password strength rating must be \'Strong\' or higher.');
      return false;
    }
    setWarningMessage(undefined);
    return true;
  };

  const {
    handleSubmit,
    handleChange,
    data,
    errors,
  } = useForm({
    validations: {
      email: {
        custom: {
          isValid: (value: string) => value !== '',
          message:
            t('Email is empty'),
        },
      },
      password: {
        custom: {
          isValid: (value: string) => checkPasswordValidity(value),
        },
      },
      passwordConfirmation: {
        custom: {
          isValid: (value: string) => value === password,
          message:
            t('Passwords don\'t match'),
        },
      },
      privacyPolicy: {
        custom: {
          isValid: (value: string) => value,
        },
      },
    },
    initialValues: {
      email: '',
      password: '',
      passwordConfirmation: '',
      privacyPolicy: false,
    },
    onSubmit: () => createFireBaseUser(),
  });

  const {
    email, password, passwordConfirmation, privacyPolicy,
  } = data;
  const [showPassword, setShowPassword] = useState(false);
  const [showPasswordConfirmation, setShowPasswordConfirmation] = useState(false);
  const [errorMessageView, handleError] = useState<string>();

  useEffect(() => {
    setPasswordStrength(zxcvbn(password));
  }, [password]);

  const createFireBaseUser = () => {
    createUserWithEmailAndPassword(auth, email, password)
      .then((userCredential) => {
        const { user } = userCredential;
        sendEmailVerification(user).catch((error) => {
          console.log(error);
        });
        navigate(confirmationEmail());
      })
      .catch((error) => {
        const errorCode = error.code;
        if (errorCode === 'auth/email-already-in-use') {
          handleError(t('Invalid email. Please try again.'));
        }
        if (errorCode === 'auth/invalid-email') {
          handleError(t('Invalid email. Please try again.'));
        }
        if (errorCode === 'auth/weak-password') {
          handleError(t('Password not strong enough. Please try again.'));
        }
      });
  };
  return (
    <form>
      {!errorMessageView
        || (
          <ErrorBanner
            onClose={() => {
              handleError(undefined);
            }}
            message={errorMessageView}
          />
        )}
      <BackButton />
      <Screen>
        <PageHeading>{t('Hi there! Let\'s make you an account.')}</PageHeading>
        <FormControl variant="standard">
          <StyledInputLabel
            id="email"
          >
            {t('Email')}
          </StyledInputLabel>
          <StyledInput
            name="email"
            value={email}
            onChange={(e) => (handleChange('email', e.target.value))}
          />
          {!!errors.email && (
          <StyledFormHelperText
            id="component-error-text"
            error={!!errors.email}
          >
            {errors.email}
          </StyledFormHelperText>
          )}
        </FormControl>
        <FormControl variant="standard">
          <StyledInputLabel
            id="password"
          >
            {t('Password')}
          </StyledInputLabel>
          <StyledInput
            name="password"
            value={password}
            type={showPassword ? 'text' : 'password'}
            error={!!errors.password}
            onChange={(e) => (handleChange('password', e.target.value))}
            endAdornment={(
              <InputAdornment position="end">
                <IconButton
                  aria-label="toggle password visibility"
                  onClick={() => setShowPassword(!showPassword)}
                  onMouseDown={() => setShowPassword(!showPassword)}
                  edge="end"
                >
                  {showPassword ? <StyledPasswordOff /> : <StyledPasswordOn />}
                </IconButton>
              </InputAdornment>
            )}
          />
          {passwordStrength?.password && (
            <PasswordStrengthHelpText>
              <StyledFormHelperText id="component-error-text" style={{ paddingRight: '5px' }}>{t('Password Strength:')}</StyledFormHelperText>
              <PasswordStrengthIndicator passwordScore={passwordStrength?.score} />
            </PasswordStrengthHelpText>
          )}
          {warningMessage ? (
            <StyledFormHelperText
              id="component-error-text"
              error={!!warningMessage}
            >
              {warningMessage}
            </StyledFormHelperText>
          ) : null}
        </FormControl>
        <FormControl variant="standard">
          <StyledInputLabel
            id="confirmPassword"
          >
            {t('Re-enter Password')}
          </StyledInputLabel>
          <StyledInput
            name="confirmPassword"
            value={passwordConfirmation}
            type={showPasswordConfirmation ? 'text' : 'password'}
            error={!!errors.passwordConfirmation}
            onChange={(e) => (handleChange('passwordConfirmation', e.target.value))}
            endAdornment={(
              <InputAdornment position="end">
                <IconButton
                  aria-label="toggle password visibility"
                  onClick={() => setShowPasswordConfirmation(!showPasswordConfirmation)}
                  onMouseDown={() => setShowPasswordConfirmation(!showPasswordConfirmation)}
                  edge="end"
                >
                  {showPasswordConfirmation ? <StyledPasswordOff /> : <StyledPasswordOn />}
                </IconButton>
              </InputAdornment>
            )}
          />
          {!!errors.passwordConfirmation && (
            <FormHelperText
              id="component-error-text"
              error={!!errors.passwordConfirmation}
            >
              {t('Passwords do not match')}
            </FormHelperText>
          )}
        </FormControl>
        <Checkbox
          label={(
            <Trans style={{ flexFlow: 'wrap' }}>
              <span>
                I have read and agree to Owlet's
                {' '}
                <ExternalListItem
                  href="https://owletcare.com/pages/terms"
                  target="_blank"
                  rel="noreferrer"
                >
                  Terms and Conditions
                </ExternalListItem>
                {' '}
                and
                {' '}
                <ExternalListItem
                  href="https://owletcare.com/pages/privacy"
                  target="_blank"
                  rel="noreferrer"
                >
                  Privacy Policy
                </ExternalListItem>
                .
              </span>
            </Trans>
          )}
          id="agreement"
          name="agreement"
          onChange={() => (
            handleChange('privacyPolicy', !privacyPolicy)
          )}
        />
        <Button
          disabled={!((email.includes('@') && password.length >= 6 && privacyPolicy))}
          label={t('Create Account')}
          size="large"
          onClick={() => {
            handleSubmit();
          }}
        />
      </Screen>
    </form>
  );
};

export default Login;
