import { createContext, useContext } from 'react';
import { InboxMessage } from 'leanplum-sdk';

interface NPSContextState {
  markAllAsRead: () => void
  messages: InboxMessage[];
  readAll: () => void
  showSurvey: boolean;
  showMinimized: boolean;
  minimizeSurvey: () => void;
  maximizeSurvey: () => void;
}

const initialState: NPSContextState = {
  markAllAsRead: () => undefined,
  messages: [],
  readAll: () => undefined,
  showSurvey: false,
  showMinimized: false,
  minimizeSurvey: () => undefined,
  maximizeSurvey: () => undefined,
};

const Context = createContext<NPSContextState>(initialState);

const useNPSContext = () => {
  const context = useContext(Context);
  if (context === undefined) {
    throw new Error('NPSContext context must be used within an NPSProvider');
  }
  return context;
};

export { useNPSContext };
export default Context;
