import styled from 'styled-components';
import { useTranslation } from 'react-i18next';
import { Body, PageHeading } from '../Typography';
import SleepyOwl from '../../assets/owl_sleepy.svg';

const Layout = styled.section`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 20px;
  height: inherit;
  `;

const Content = styled.div`
display: flex;
flex-direction: column;
align-items: center;
`;

const OwlImage = styled.img`
  width: 64px;
  height: 64px;
  margin-bottom: 30px;
`;

const PageHeader = styled(PageHeading)`
  text-align: center;
`;

const EmptyOverview = () => {
  const { t } = useTranslation();

  return (
    <Layout>
      <Content>
        <OwlImage
          alt={t('sleepy owl')}
          src={SleepyOwl}
        />
        <PageHeader>{t('You have no data on this day')}</PageHeader>
        <Body>{t('Use the smart sock to collect data')}</Body>
      </Content>
    </Layout>
  );
};

export default EmptyOverview;
