export const FIREBASE_APP_ID = '1:561089101102:web:f73f3d31ffff622786cebf';
export const FIREBASE_API_KEY = 'AIzaSyCBJ_5TRcPz_cQA4Xdqpcuo9PE5lR8Cc7k';
export const FIREBASE_PROJECT_ID = 'owletcare-prod';
export const FIREBASE_AUTH_DOMAIN = `${FIREBASE_PROJECT_ID}.firebaseapp.com`;
export const FIREBASE_DATABASE_URL = `https://${FIREBASE_PROJECT_ID}.firebaseio.com`;

export const OWLET_API_DOMAIN = 'owletdata.com';
export const AYLA_ENV = 'owlue1';
export const AYLA_APP_ID = 'owa-rg-id';
export const AYLA_APP_SECRET = 'owa-dx85qljgtR6hmVflyrL6LasCxA8';

export const ENABLE_DD_RUM = false;
export const ENABLE_DD_RUM_SESSION_REPLAY = false;

export const FEATURE_FLAG_API_KEY = 'cg8rm35gkgmi17s7di1a109qhvub96ncinrr';

export const NPS_APP_ID = 'app_zHs40jt2H1fWjCaBIHIDsmPq7PGxdVEstZvFMq5lqGg';
export const NPS_API_KEY = 'prod_BU6sbRPCAFrAEaBulFqoIOIAE2jHKeJ0L3oOAXGMLx4';
export const NPS_USE_PROD = true;
