import { Link } from 'react-router-dom';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';
import theme from '../../../design/theme';
import Icon from '../../../components/Icon/Icon';
import { Heading, PageHeading } from '../../../components/Typography';
import { aboutChild, notBornYet } from '../../../navigation/routes';
import { aboutChild as deviceAboutChild, notBornYet as deviceNotBornYet } from '../../DeviceSetup/routes';
import BackButton from '../../../components/Button/BackButton';
import { useDeviceSetupContext } from '../../../../backend/contexts/deviceSetupContext';

const Layout = styled.div`
  display: flex;
  flex-direction: column;
`;

const Content = styled.div`
  display: flex;
  flex-direction: column;
  padding: 20px;
`;

const ListItem = styled(Link)`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 16px 0;
  margin-top: 40px;
`;

const LeftSide = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`;

const Title = styled(Heading)`
  margin-left: 10px;
`;

const IsBorn = () => {
  const { dsn } = useDeviceSetupContext();
  const { t } = useTranslation();
  return (
    <Layout>
      <BackButton />
      <Content>
        <PageHeading>{t('Has your child been born yet?')}</PageHeading>
        <ListItem to={dsn ? deviceAboutChild() : aboutChild()}>
          <LeftSide>
            <Title>{t('Yes')}</Title>
          </LeftSide>
          <Icon icon="ChevronRight" width={24} height={24} fill={theme.color.text.darkMode.primary} />
        </ListItem>
        <ListItem to={dsn ? deviceNotBornYet() : notBornYet()}>
          <LeftSide>
            <Title>{t('No')}</Title>
          </LeftSide>
          <Icon icon="ChevronRight" width={24} height={24} fill={theme.color.text.darkMode.primary} />
        </ListItem>
      </Content>
    </Layout>
  );
};

export default IsBorn;
