import {
  datadogRum,
  RumEvent,
  RumEventDomainContext,
  RumErrorEvent,
} from '@datadog/browser-rum';
import { User } from 'firebase/auth';
import { getConfigSetting, getEnvironmentFromLocation } from '../config/URLConfig';
import { ConfigVarName } from '../../shared/types';
import { appVersion } from '../utils/helpers';

const isAyla404 = (
  event: RumErrorEvent,
  context: any,
) => (
  event?.error?.message
    && event.error.message.includes('Request failed with status code 404')
    && context?.error?.config?.url
    && context?.error?.config.url.includes('aylanetworks.com')
);
const scrubRumEvents = (event: RumEvent, context: RumEventDomainContext) => {
  // discard a RUM error if...
  if (event.type === 'error' && isAyla404(event, context)) {
    return false;
  }
  return true;
};

export default function init() {
  datadogRum.init({
    applicationId: 'ed509430-4e69-495e-8d83-1cae5b0960d7',
    allowedTracingOrigins: [/https:\/\/.*\.owletdata\.com/],
    beforeSend: scrubRumEvents,
    clientToken: 'pub6814b19c4908235e9664fe046240a26f',
    site: 'datadoghq.com',
    service: 'owlet-web-app',
    env: getEnvironmentFromLocation(),
    version: appVersion(),
    sampleRate: 100,
    trackInteractions: true,
    defaultPrivacyLevel: 'mask-user-input',
  });

  if (getConfigSetting(ConfigVarName.ENABLE_DD_RUM_SESSION_REPLAY)) {
    datadogRum.startSessionReplayRecording();
  }
}

export function identify(user: User) {
  datadogRum.setUser({
    id: user.uid,
  });
}
