import { useNavigate } from 'react-router-dom';
import styled from 'styled-components';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import theme from '../../design/theme';
import { ButtonText } from '../Typography';

interface SharedProps {
  buttonText: string;
  isDisabled?: boolean;
}

interface NavProps extends SharedProps {
  route: string;
  onClick?: never;
}

interface OnClickProps extends SharedProps {
  route?: never;
  onClick: (e: React.MouseEvent<HTMLButtonElement>) => void;
}

type BottomTextAndArrowProps = NavProps | OnClickProps

type StyledProps = {
  $isDisabled?: boolean;
  callBack?: () => void;
}

const Layout = styled.button`
  display: flex;
  justify-content: flex-end;
  align-items: center;
  width: 100%;
`;

const LinkContainer = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: center;
  `;

const TextStyled = styled(ButtonText)`
  color: ${({ $isDisabled }: StyledProps) => ($isDisabled ? theme.color.text.darkMode.disabled : theme.color.primary.default)};
`;

const IconStyle = styled(ArrowForwardIosIcon)`
  color: ${({ $isDisabled }: StyledProps) => ($isDisabled ? theme.color.text.darkMode.disabled : theme.color.primary.default)};
  font-size: 16px;
`;

const BottomTextAndArrow = ({
  buttonText, route, isDisabled, onClick,
}: BottomTextAndArrowProps) => {
  const navigate = useNavigate();
  const onClickHandler = onClick || (() => navigate(route as string));

  return (
    <Layout
      onClick={onClickHandler}
      tabIndex={0}
      disabled={isDisabled}
    >
      <LinkContainer>
        <TextStyled $isDisabled={isDisabled}>{buttonText}</TextStyled>
        <IconStyle $isDisabled={isDisabled} />
      </LinkContainer>
    </Layout>
  );
};

export default BottomTextAndArrow;
