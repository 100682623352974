import { useState } from 'react';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';
import { Body, Heading, PageHeading } from '../../components/Typography';
import theme from '../../design/theme';
import BottomTextAndArrow from '../../components/FormComponents/BottomTextAndArrow';
import Checkbox from '../../components/FormComponents/Checkbox';
import { accountInfo } from '../../navigation/routes';
import { useAccountContext } from '../../../backend/contexts/accountContext';
import { addChild, assignChild } from './routes';
import { useDeviceSetupContext } from '../../../backend/contexts/deviceSetupContext';

const Layout = styled.div`
  display: flex;
  flex-direction: column;
  `;

const Content = styled.div`
  display: flex;
  flex-direction: column;
  padding: 20px;
`;

const AgreeBox = styled.div`
  display: flex;
  padding: 20px 0;
`;

const ButtonContainer = styled.div`
  width: 90%;
  padding: 40px 20px;
  background-color: ${theme.color.background.darkMode.darker};
`;

const SafetyInfo = () => {
  const [nextButtonDisabled, setNextButtonDisabled] = useState(true);
  const { t } = useTranslation();
  const { accountKey, account } = useAccountContext();
  const { serviceCreated } = useDeviceSetupContext();

  const nextStep = () => {
    const serviceUserKeys = Object.keys(account.serviceUserKeys);

    if (!serviceCreated) {
      return accountInfo();
    }

    if (serviceUserKeys.length === 0) {
      return addChild();
    }
    return assignChild(accountKey, serviceCreated.id, serviceCreated.deviceKey);
  };

  return (
    <Layout>
      <Content>
        <PageHeading>{t('Important Safety Information')}</PageHeading>
        <br />
        <Heading>{t('Correct sock fit')}</Heading>
        <Body>{t('Your baby’s safety is our top priority and we know it’s yours too.')}</Body>
        <br />
        <Body>
          {t('We want you to be aware that incorrect use of the sock could result in discomfort, potentially leading to red marks, pressure sores, or blisters on your baby’s foot. Keep your baby’s foot dry, and use the correct sock size and follow our placement guidelines.')}
        </Body>
        <br />
        <Heading>{t('Sock Hygiene')}</Heading>
        <Body>
          {t('Gently hand wash the sock and sensor at least once every week with a mild detergent. Wait until the sock is completely dry before continuing use.')}
        </Body>
        <br />
        <Heading>{t('Safe sleep practices')}</Heading>
        <Body>
          {t('Remember to always practice safe sleep practices and avoid unsafe sleep surfaces including swings, car seats and co-sleeping.')}
        </Body>
        <br />
        <Body>
          {t('The Owlet Sock is not a replacement for safe sleep and parental care. Please ensure you are following safe sleep guidelines and are using the Owlet Sock as an aid to know how your baby is doing in addition to all the care, safety and love they receive from you.')}
        </Body>
        <AgreeBox>
          <Checkbox
            label={t('I agree to use my Owlet Sock in accordance with the product placement guidelines.')}
            id="agreement"
            name="agreement"
            onChange={() => { setNextButtonDisabled(!nextButtonDisabled); }}
          />
        </AgreeBox>
        <ButtonContainer>
          <BottomTextAndArrow isDisabled={nextButtonDisabled} buttonText={t('Next')} route={nextStep()} />
        </ButtonContainer>
      </Content>
    </Layout>
  );
};

export default SafetyInfo;
