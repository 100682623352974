import { getConfigSetting } from './URLConfig';
import { featureFlagDefaultKey } from '../../shared/constants';
import { ConfigVarName } from '../../shared/types';

const authorizationKey = getConfigSetting(ConfigVarName.FEATURE_FLAG_API_KEY);

export default (key = featureFlagDefaultKey) => ({
  core: {
    authorizationKey,
    key,
  },
  debug: false,
});
