export const FIREBASE_APP_ID = '1:395737756031:web:98d91edb9b2f186a24aa4a';
export const FIREBASE_API_KEY = 'AIzaSyDOHaYy7ocsGjN0MDTHCvpMQV4tYTGDqsQ';
export const FIREBASE_PROJECT_ID = 'owletcare-prod-eu';
export const FIREBASE_AUTH_DOMAIN = `${FIREBASE_PROJECT_ID}.firebaseapp.com`;
export const FIREBASE_DATABASE_URL = `https://${FIREBASE_PROJECT_ID}.firebaseio.com`;

export const OWLET_API_DOMAIN = 'eu.owletdata.com';
export const AYLA_ENV = 'field-eu';
export const AYLA_APP_ID = 'owa-eA-id';
export const AYLA_APP_SECRET = 'owa-keBVoii26Xg13GvRFHtBU2PuQlM';

export const ENABLE_DD_RUM = false;
export const ENABLE_DD_RUM_SESSION_REPLAY = false;

export const FEATURE_FLAG_API_KEY = 'cg8rm35gkgmi17s7di1a109qhvub96ncinrr';

export const NPS_APP_ID = 'app_zHs40jt2H1fWjCaBIHIDsmPq7PGxdVEstZvFMq5lqGg';
export const NPS_API_KEY = 'prod_BU6sbRPCAFrAEaBulFqoIOIAE2jHKeJ0L3oOAXGMLx4';
export const NPS_USE_PROD = true;
