import {
  useCallback,
  useMemo,
  useState,
} from 'react';
import styled from 'styled-components';
import _ from 'lodash';
import PullToRefresh from 'react-simple-pull-to-refresh';
import { isSameDay } from 'date-fns';
import { useTranslation } from 'react-i18next';
import { SleepDataResponse, VitalsDataResponse } from '../../../shared/types';
import DayTotals from './DayTotals';
import SessionCard from './SessionCard';
import EmptyOverview from './EmptyOverview';
import theme from '../../design/theme';
import { Body } from '../Typography';
import { Icon } from '../Icon';
import Modal from '../Modal/Modal';
import ModalDayTotals from '../Modal/ModalDayTotals';
import ModalSleepSessions from '../Modal/ModalSleepSessions';
import Loader from '../../navigation/Loader';
import { useHistoryContext } from '../../../backend/contexts/history/context';
import ReloadOverview from './ReloadOverview';

type OverviewProps = {
  historyDate: Date | null;
  loading: boolean;
  overviewKey: string;
  vitalsKey: string;
  reloadOverview: () => Promise<void>;
}

const Layout = styled.div`
  padding: 20px;

  .pullRefresh {
    overflow: scroll;
  }
`;

const Heading = styled.section`
  display: flex;
  align-items: center;
`;

const StyledBody = styled(Body)`
  margin-right: 8px;
`;

const Overview = ({
  loading, historyDate, overviewKey, vitalsKey, reloadOverview,
}: OverviewProps) => {
  const { history } = useHistoryContext();
  const overviewState = useMemo(() => history[overviewKey] || {}, [overviewKey, history]);
  const vitalsState = useMemo(() => history[vitalsKey] || {}, [vitalsKey, history]);
  const overviewData = overviewState.data;
  const vitalsData = vitalsState.data;
  const overviewError = overviewState.error;

  const { t } = useTranslation();

  const [modalTotalsActive, setModalTotalsActive] = useState<boolean>(false);
  const [modalSessionsActive, setModalSessionsActive] = useState<boolean>(false);

  const sessions = useMemo(() => (
    (overviewData as SleepDataResponse)?.sessions || []
  ), [overviewData]);

  const filterSessions = useMemo(() => {
    if (historyDate) {
      return sessions.filter((session) => {
        const startTime = new Date(session.startTime * 1000);
        const endTime = new Date(session.endTime * 1000);

        return isSameDay(historyDate, startTime) || isSameDay(historyDate, endTime);
      });
    }
    return sessions;
  }, [sessions, historyDate]);

  const totalSleepMinutes = useMemo(() => {
    if (!overviewData) return 0;
    const { data } = (overviewData as SleepDataResponse);
    if (!data) return 0;
    let totalMinutes = 0;
    const sleepStates = [...data.sleepStates];
    const timeWindowStartTimes = [...data.timeWindowStartTimes];

    sleepStates.forEach((v, i) => {
      if (v === 15 || v === 8) {
        const timeWindow = new Date(timeWindowStartTimes[i] * 1000);
        if (isSameDay(historyDate as Date, timeWindow)) totalMinutes += 1;
      }
    });

    return totalMinutes;
  }, [overviewData, historyDate]);

  const getAvgs = useCallback(
    () => {
      if (!_.isEmpty(vitalsData)) {
        const { data } = (vitalsData as VitalsDataResponse);
        return {
          avgHeart: _.round(_.sum(data.heartRate.avg) / data.heartRate.avg.length),
          avgOxygen: _.round(_.sum(data.oxygen.avg) / data.oxygen.avg.length),
        };
      }
      return { avgHeart: '', avgOxygen: '' };
    },
    [vitalsData],
  );

  if (loading) {
    return <Loader />;
  }

  if (overviewError) {
    return <ReloadOverview onClick={reloadOverview} />;
  }

  return (
    <>
      { _.isEmpty(filterSessions) && (
        <PullToRefresh onRefresh={reloadOverview} pullingContent="">
          <EmptyOverview />
        </PullToRefresh>
      )}
      { !_.isEmpty(filterSessions) && (
        <Layout>
          <PullToRefresh onRefresh={reloadOverview} pullingContent="" className="pullRefresh">
            <>
              <Heading>
                <StyledBody>{t('Day Totals')}</StyledBody>
                <Icon
                  icon="Help"
                  width={20}
                  height={20}
                  fill={theme.color.text.darkMode.primary}
                  onClick={() => setModalTotalsActive(!modalTotalsActive)}
                />
              </Heading>
              <DayTotals sleepMinutes={totalSleepMinutes} totalAvgs={getAvgs()} />
              <Heading>
                <StyledBody>{t('Sleep Sessions')}</StyledBody>
                <Icon
                  icon="Help"
                  width={20}
                  height={20}
                  fill={theme.color.text.darkMode.primary}
                  onClick={() => setModalSessionsActive(!modalSessionsActive)}
                />
              </Heading>
              { filterSessions.map((session) => (
                <SessionCard key={session.startTime} session={session} />
              ))}
              <Modal
                isActive={modalTotalsActive}
                onClose={() => setModalTotalsActive(!modalTotalsActive)}
              >
                <ModalDayTotals />
              </Modal>
              <Modal
                isActive={modalSessionsActive}
                onClose={() => setModalSessionsActive(!modalSessionsActive)}
              >
                <ModalSleepSessions />
              </Modal>
            </>
          </PullToRefresh>
        </Layout>
      )}
    </>
  );
};

export default Overview;
