import _ from 'lodash';
import getConnectionStatus from '../../../utils/deviceConnectionStatus';
import {
  AylaContextState,
  AylaUpdateDeviceActivePayload,
  DeviceConnectionStatus,
  DeviceDetails,
} from '../../../../shared/types';

export default (state: AylaContextState, payload: AylaUpdateDeviceActivePayload) => {
  const { deviceActiveAt } = payload;
  const newDevice: DeviceDetails = {
    ...(state[payload.dsn] || {}),
    ...{ setDeviceActiveAtUTC: deviceActiveAt },
  };

  if (!newDevice.firstSetDeviceActiveAtUTC) {
    newDevice.firstSetDeviceActiveAtUTC = deviceActiveAt;
  }

  newDevice.connectionStatus = getConnectionStatus(
    newDevice.setDeviceActiveAtUTC,
    newDevice.lastUpdatedAtUTC,
    newDevice.firstSetDeviceActiveAtUTC,
  );

  if (newDevice.connectionStatus === DeviceConnectionStatus.Disconnected) {
    newDevice.bso = undefined;
    newDevice.btt = undefined;
    newDevice.chg = undefined;
  }

  const updatedState = { ...state, ...{ [payload.dsn]: newDevice } };

  if (!_.isEqual(state, updatedState)) {
    return updatedState;
  }

  return state;
};
