import styled from 'styled-components';
import { Radio } from '@mui/material';
import Avatar from '../../components/Avatar';
import { ServiceUser } from '../../../shared/types';
import { Body } from '../../components/Typography';

type ChildProfileListItemProps = {
  checked: boolean
  onClick: () => void
  serviceUser: ServiceUser
};

const Container = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 80px;
  width: 100%;
`;

const AvatarContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  margin-right: 10px;
`;

const LeftContainer = styled.div`
  display: flex;
  align-items: center;
`;

const ChildListItem = ({ checked, onClick, serviceUser }: ChildProfileListItemProps) => {
  const data = serviceUser;

  return (
    <Container>
      <LeftContainer>
        <AvatarContainer>
          <Avatar image={data?.avatar} size="xsmall" />
        </AvatarContainer>
        <Body>{data?.firstName}</Body>
      </LeftContainer>
      <Radio
        checked={checked}
        name={data.id}
        onClick={onClick}
        value={data.id}
        sx={{
          color: '#C2C2C2',
          '& .MuiSvgIcon-root': {
            fontSize: 24,
          },
          '&.Mui-checked': {
            color: '#9DE3CD',
          },
        }}
      />
    </Container>
  );
};

export default ChildListItem;
