import styled from 'styled-components';
import { Link } from 'react-router-dom';
import { FormHelperText, Input, InputLabel } from '@mui/material';
import { Visibility, VisibilityOff } from '@mui/icons-material';
import theme from '../../design/theme';

export const Screen = styled.section`
  display: flex;
  flex-direction: column;
  padding: 20px;
  color: white;

  .MuiFormControl-root{
    margin: 10px 0;
    color: white;
  }

  .item{
    margin: 10px 0;
  }
`;

export const StyledInputLabel = styled(InputLabel)`
  color: ${theme.color.text.darkMode.primary} !important;
`;

export const StyledInput = styled(Input)`
  color: ${theme.color.text.darkMode.primary} !important;
  border-bottom: 1px solid ${theme.color.text.darkMode.primary};
`;

export const StyledFormHelperText = styled(FormHelperText)`
  color: ${theme.color.text.darkMode.primary} !important;
`;

export const GoodFormHelperText = styled(FormHelperText)`
  color: ${theme.color.text.good} !important;
`;
export const WarningFormHelperText = styled(FormHelperText)`
  color: ${theme.color.text.warning} !important;
`;

export const ErrorFormHelperText = styled(FormHelperText)`
  color: ${theme.color.text.error} !important;
`;

export const StyledLink = styled(Link)`
  padding: 16px 0;
  text-decoration: none;
  color: ${theme.color.primary.default};
`;

export const StyledPasswordOff = styled(VisibilityOff)`
  color: ${theme.color.text.darkMode.primary};
`;

export const StyledPasswordOn = styled(Visibility)`
  color: ${theme.color.text.darkMode.primary};
`;
