import { AxiosResponse } from 'axios';
import { getConfigSetting } from '../config/URLConfig';
import { aylaAxios as axios, uninterceptedAxios } from './apiInstance';
import {
  AylaDevice,
  AylaDeviceProperty,
  AylaSetActiveDataPoint,
  ConfigVarName,
  RegisterDeviceBody,
} from '../../shared/types';

const baseUrl = `https://ads-${getConfigSetting(ConfigVarName.AYLA_ENV)}.aylanetworks.com/`;

type PostAppActiveRequest = (
  token: string,
  DSN: string
) => Promise<AxiosResponse<AylaSetActiveDataPoint>>;

const setAppActive: PostAppActiveRequest = (token, DSN) => (
  axios.post<AylaSetActiveDataPoint>(`${baseUrl}apiv1/dsns/${DSN}/properties/APP_ACTIVE/datapoints.json`,
    { datapoint: { value: '1' } },
    { headers: { Authorization: token } })
);

const getDevices: (token: string) => Promise<AxiosResponse<AylaDevice[]>> = (token) => (
  axios.get<AylaDevice[]>(`${baseUrl}apiv1/devices.json`, {
    headers: { Authorization: token },
  })
);

const getDevice: (token: string, dsn: string) =>
  Promise<AxiosResponse<AylaDevice>> = (token, dsn) => (
    axios.get<AylaDevice>(`${baseUrl}apiv1/dsns/${dsn}.json`, {
      headers: { Authorization: token },
    })
  );

const unregisterDevice: (token: string, id: string) =>
  Promise<AxiosResponse<AylaDevice>> = (token, id) => (
    axios.delete<AylaDevice>(`${baseUrl}apiv1/devices/${id}.json`, {
      headers: { Authorization: token },
    })
  );

const getRegisterableDevices: (token: string, ip: string) => Promise<AxiosResponse<AylaDevice>> = (
  token,
  ip,
) => {
  uninterceptedAxios.defaults.timeout = 4000;
  return uninterceptedAxios.get<AylaDevice>(`${baseUrl}apiv1/devices/register.json?ip=&`, {
    headers: { Authorization: token },
    params: {
      ip,
      regtype: 'Button-Push',
    },
  });
};

const registerDevice: (
  token: string,
  data: RegisterDeviceBody
) => Promise<AxiosResponse<AylaDevice>> = (
  token,
  data,
) => axios.post<AylaDevice>(`${baseUrl}apiv1/devices.json`,
  data,
  { headers: { Authorization: token } });

type GetPropertiesRequest = (
  token: string,
  DSN: string
) => Promise<AxiosResponse<AylaDeviceProperty[]>>;

const getProperties: GetPropertiesRequest = (token, DSN) => (
  axios.get<AylaDeviceProperty[]>(`${baseUrl}apiv1/dsns/${DSN}/properties.json`, {
    headers: { Authorization: token },
  })
);

type GetAylaTokenResponseType = {
  access_token: string, // eslint-disable-line camelcase
  expires_in: number // eslint-disable-line camelcase
}

const signIn: (token: string) => Promise<AxiosResponse<GetAylaTokenResponseType>> = (token) => (
  axios.post<GetAylaTokenResponseType>(`${baseUrl}api/v1/token_sign_in.json`, {
    token,
    app_id: getConfigSetting(ConfigVarName.AYLA_APP_ID),
    app_secret: getConfigSetting(ConfigVarName.AYLA_APP_SECRET),
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
    },
  })
);

// Are the secrets required???
const aylaSignOut: (token: string) => Promise<AxiosResponse> = (token) => (
  axios.post(`${baseUrl}users/sign_out.json`,
    {
      user: {
        access_token: token,
      },
      headers: {
        Authorization: token,
        Accept: 'application/json',
        'Content-Type': 'application/json',
      },
    })
);

// curl --request POST \
//      --url https://user-owldev.aylanetworks.com/users/sign_out.json \
//      --header 'Accept: application/json' \
//      --header 'Authorization: 0952c2dfaa394058888db488155cea75' \

export {
  setAppActive,
  getDevices,
  getProperties,
  getRegisterableDevices,
  registerDevice,
  signIn,
  aylaSignOut,
  getDevice,
  unregisterDevice,
};
