import { Link, useNavigate } from 'react-router-dom';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';
import theme from '../../design/theme';
import SockDevice from '../../assets/device_sock3.png';
import Icon from '../../components/Icon/Icon';
import { Heading, PageHeading } from '../../components/Typography';
import { baseSetup } from './routes';

const Layout = styled.div`
  display: flex;
  flex-direction: column;
`;

const ButtonContainer = styled.div`
  height: 80px;
  display: flex;
  background-color: ${theme.color.background.darkMode.darker};
  align-items: center;
`;

const BackButton = styled.div`
  padding: 16px;
`;

const Content = styled.div`
  display: flex;
  flex-direction: column;
  padding: 20px;
`;

const ListItem = styled(Link)`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 16px 0;
  margin-top: 40px;
`;

const LeftSide = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`;

const Name = styled(Heading)`
  margin-left: 10px;
`;

const DeviceIcon = styled.img`
  width: 40px;
  height: 40px;
`;

const ChooseDeviceType = () => {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const goBack = () => {
    navigate(-2);
  };
  return (
    <Layout>
      <ButtonContainer>
        <BackButton onClick={() => goBack()}>
          <Icon icon="ChevronLeft" width={24} height={24} fill={theme.color.text.darkMode.primary} />
        </BackButton>
      </ButtonContainer>
      <Content>
        <PageHeading>{t('Which device would you like to set up?')}</PageHeading>
        <ListItem to={baseSetup()}>
          <LeftSide>
            <DeviceIcon src={SockDevice} alt={t('Sock Device')} />
            <Name>{t('Smart Sock 3')}</Name>
          </LeftSide>
          <Icon icon="ChevronRight" width={24} height={24} fill={theme.color.text.darkMode.primary} />
        </ListItem>
      </Content>
    </Layout>
  );
};

export default ChooseDeviceType;
