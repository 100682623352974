import styled from 'styled-components';
import { useTranslation } from 'react-i18next';
import HappyOwl from '../../../assets/owl_happy.svg';
import BottomTextAndArrow from '../../../components/FormComponents/BottomTextAndArrow';
import { home } from '../../../navigation/routes';
import theme from '../../../design/theme';
import { Body, PageHeading } from '../../../components/Typography';

const Layout = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  padding-top: 80px;
`;

const OwlImage = styled.img`
  width: 150px;
  height: 150px;
  margin-bottom: 30px;
`;

const BottomRow = styled.div`
  position: fixed;
  width: 100%;
  bottom: 0;
  padding: 40px 20px;
  background-color: ${theme.color.background.darkMode.darker};
`;

const OwlSet = () => {
  const { t } = useTranslation();
  return (
    <Layout>
      <OwlImage
        alt="owl icon"
        src={HappyOwl}
      />
      <PageHeading>{t('You\'re "owl" set')}</PageHeading>
      <Body>{t('Your child was succefully created.')}</Body>
      <BottomRow>
        <BottomTextAndArrow buttonText={t('Home')} route={home()} />
      </BottomRow>
    </Layout>
  );
};

export default OwlSet;
