import {
  createContext, useCallback, useContext, useEffect, useState, useReducer,
} from 'react';
import { usePageVisibility } from 'react-page-visibility';
import {
  AylaContextState,
  AylaContextActions,
} from '../../../shared/types';
import {
  connectDevice, getAylaToken, getDSNs, handleSignOut,
} from '../../services/ayla';
import { AxiosError, aylaAxios } from '../../utils/apiInstance';
import { useAuthContext } from '../authContext';
import aylaReducer, { initialReducerState } from './reducer';

const localAylaTokenKey = 'aylaToken';
const localAylaRefreshAtKey = 'aylaRefresh';
const secondToMsMultiplier = 950;

type AylaProviderProps = { children: React.ReactNode }

type AylaContextType = {
  signOut: () => void;
  aylaToken?: string;
  deviceDetails: AylaContextState;
  refreshDevices: () => void;
}

const initialContextState: AylaContextType = {
  signOut: () => undefined,
  deviceDetails: initialReducerState,
  refreshDevices: () => undefined,
};

const AylaContext = createContext<AylaContextType>(initialContextState);

const useAylaContext = () => {
  const context = useContext(AylaContext);
  if (context === undefined) {
    throw new Error('Ayla context must be used within a AylaProvider');
  }
  return context;
};

const localAylaRefreshAt = () => {
  const localString = window.localStorage.getItem(localAylaRefreshAtKey) ?? undefined;
  if (localString) {
    const localNum = parseInt(localString, 10);
    if (new Date(localNum).getTime() > Date.now()) {
      return localNum;
    }
  }

  return undefined;
};

const localAylaToken = () => {
  if (!localAylaRefreshAt()) {
    return undefined;
  }
  return window.localStorage.getItem(localAylaTokenKey) ?? undefined;
};

const calculateRefresh = (seconds: number) => {
  const milliseconds = (seconds * secondToMsMultiplier);
  return milliseconds + Date.now();
};

const calculateTimeout = (refreshAt?: number) => {
  if (!refreshAt) {
    return undefined;
  }

  return refreshAt - Date.now();
};

const AylaProvider = ({ children }: AylaProviderProps) => {
  const { firebaseToken, currentUser } = useAuthContext();
  const isPageOpen = usePageVisibility();
  const [deviceDetails, dispatch] = useReducer(aylaReducer, initialReducerState);
  const [aylaToken, setAylaToken] = useState<string | undefined>(localAylaToken());
  const [aylaRefreshAt, setAylaRefreshAt] = useState<number | undefined>(localAylaRefreshAt());
  const [DSNs, setDSNs] = useState<string[]>([]);
  const [deviceRefreshRequestAt, setDeviceRefreshRequestAt] = useState<number | undefined>();

  const setAylaData = useCallback((token?: string, refreshAt?: number) => {
    setAylaToken(token);
    if (token) {
      window.localStorage.setItem(localAylaTokenKey, token);
    } else {
      window.localStorage.removeItem(localAylaTokenKey);
    }
    setAylaRefreshAt(refreshAt);
    if (refreshAt) {
      window.localStorage.setItem(localAylaRefreshAtKey, refreshAt.toString());
    } else {
      window.localStorage.removeItem(localAylaRefreshAtKey);
    }
  }, []);

  const clearAylaData = useCallback(() => {
    setAylaData(undefined, undefined);
    setDSNs([]);
    dispatch({ type: AylaContextActions.Reset });
  }, [setAylaData]);

  const refreshDevices = useCallback(() => {
    setDeviceRefreshRequestAt(Date.now());
  }, []);

  const updateDeviceVitals = useCallback((dsn, vitals, lastUpdatedAtUTC) => {
    dispatch({
      type: AylaContextActions.UpdateVitals,
      payload: {
        dsn,
        vitals,
        lastUpdatedAtUTC,
      },
    });
  }, []);

  const updateAppActiveTimes = useCallback((deviceActiveAt, dsn) => {
    dispatch({
      type: AylaContextActions.UpdateDeviceActive,
      payload: {
        deviceActiveAt,
        dsn,
      },
    });
  }, []);

  useEffect(() => {
    const aylaAxiosInterceptor = aylaAxios.interceptors.response.use(undefined,
      (error: AxiosError) => {
        if (error?.response?.status === 401) {
          if (aylaToken) {
            clearAylaData();
          }
        }
        if (error?.response?.status === 403) {
          refreshDevices();
        }
        return Promise.reject(error);
      });

    return () => {
      aylaAxios.interceptors.request.eject(aylaAxiosInterceptor);
    };
  }, [aylaToken, clearAylaData, refreshDevices]);

  useEffect(() => {
    let refreshTimeout: NodeJS.Timer;
    if (firebaseToken && !aylaToken && !aylaRefreshAt && currentUser?.emailVerified) {
      getAylaToken(firebaseToken).then((data) => {
        if (data) {
          const refreshAt = calculateRefresh(data.expiresIn);
          setAylaData(data.accessToken, refreshAt);
        }
      });
    } else if (firebaseToken && aylaToken && aylaRefreshAt) {
      const timeout = calculateTimeout(aylaRefreshAt);
      refreshTimeout = setTimeout(() => {
        handleSignOut(aylaToken, clearAylaData);
      }, timeout);
    }

    return () => {
      clearTimeout(refreshTimeout);
    };
  }, [setAylaData, aylaRefreshAt, aylaToken, clearAylaData, firebaseToken, currentUser]);

  useEffect(() => {
    if (aylaToken) {
      getDSNs(aylaToken).then((list) => {
        setDSNs(list);
      });
    } else {
      setDSNs([]);
    }
  }, [aylaToken, deviceRefreshRequestAt]);

  useEffect(() => {
    let timeoutList: NodeJS.Timer[] = [];

    if (aylaToken && isPageOpen) {
      const connectDeviceCallback = (DSN: string) => {
        const timeouts = connectDevice(DSN, aylaToken, updateAppActiveTimes, updateDeviceVitals);

        timeoutList = [...timeoutList, ...timeouts];
      };

      DSNs.forEach((DSN) => {
        connectDeviceCallback(DSN);
      });
    }

    return () => {
      const clearTimeoutCallBack = (timeout: NodeJS.Timeout) => {
        clearTimeout(timeout);
      };

      timeoutList.forEach((timeout) => {
        clearTimeoutCallBack(timeout);
      });
    };
  }, [aylaToken, DSNs, isPageOpen, updateDeviceVitals, updateAppActiveTimes]);

  return (
    <AylaContext.Provider
      value={{
        aylaToken,
        deviceDetails,
        refreshDevices,
        signOut: () => {
          if (aylaToken) {
            handleSignOut(aylaToken, clearAylaData);
          }
        },
      }}
    >
      {children}
    </AylaContext.Provider>
  );
};

export { AylaProvider, initialReducerState, useAylaContext };
