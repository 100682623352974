import { Helmet } from 'react-helmet-async';

function RouteWithTitle({
  title,
  children,
}: { title: string, children: React.ReactNode }) {
  return (
    <>
      <Helmet>
        <title>{title}</title>
      </Helmet>
      {children}
    </>
  );
}

export default RouteWithTitle;
