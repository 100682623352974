import Button from '@mui/material/Button';

type ButtonProps = {
  buttonText: string | React.ReactNode
  color: string
  height?: string
  width?: string
  onClick: () => void;
}

const MaterialButton = ({
  buttonText, color, height, width, onClick,
}: ButtonProps) => {
  const buttonStyle = {
    height,
    width,
    backgroundColor: color,
    color: '#34323F',
  };
  return (
    <Button
      variant="contained"
      style={buttonStyle}
      onClick={onClick}
    >
      {buttonText}
    </Button>
  );
};

export default MaterialButton;
