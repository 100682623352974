import { useState, useEffect } from 'react';
import styled from 'styled-components';
import { useNavigate } from 'react-router-dom';
import FormLabel from '@mui/material/FormLabel';
import { makeStyles } from '@mui/styles';
import { Input } from '@mui/material';
import Select from '@mui/material/Select';
import { MenuItem } from '@material-ui/core';
import InputLabel from '@mui/material/InputLabel';
import FormControl from '@mui/material/FormControl';
import {
  getDatabase,
  push,
  child,
  ref,
} from 'firebase/database';
import { LocalizationProvider } from '@mui/lab';
import AdapterDateFns from '@mui/lab/AdapterDateFns';
import { useTranslation } from 'react-i18next';
import theme from '../../../design/theme';
import { locales } from '../../../../backend/config/i18n';
import UseFirebasePostDataHook from '../../../../backend/utils/useFirebasePostDataHook';
import { useAuthContext } from '../../../../backend/contexts/authContext';
import { useServiceUserContext } from '../../../../backend/contexts/serviceUserContext';
import CheckboxForm from '../../../components/FormComponents/CheckboxForm';
import {
  genderOptions, relationshipOptions, nicuStayOptions, allRaces, FormData,
} from '../../../../shared/constants';
import DateChooser from '../../../components/FormComponents/DateChooser';
import NavHeader from '../../../components/NavHeader/NavHeader';
import { useDeviceSetupContext } from '../../../../backend/contexts/deviceSetupContext';
import { convertToBool } from '../../../../backend/utils/helpers';
import { mapUserToDevice } from '../../../../backend/utils/owletApi';
import { useErrorContext } from '../../../../backend/contexts/errorContext';
import BottomTextAndArrow from '../../../components/FormComponents/BottomTextAndArrow';
import { success } from '../../DeviceSetup/routes';

const DialogGrid = styled.div`
  overflow-y: scroll;
`;

const ScrollableArea = styled.div`
  padding: 20px;
  `;

const AboutChildArea = styled.div`
  margin-top: 20px;
  `;

const RaceSelectionArea = styled.div`
  margin-top: 40px;
  `;

const ButtonContainer = styled.div`
  width: 90%;
  padding: 40px 20px;
  background-color: ${theme.color.background.darkMode.darker};
`;

const AboutChild = () => {
  const { currentUser } = useAuthContext();
  const { addServiceUser } = useServiceUserContext();

  const navigation = useNavigate();
  const [firstName, setFirstName] = useState('');
  const [birthDateValue, setBirthDateValue] = useState<number | undefined>(undefined);
  const [genderValue, setGenderValue] = useState('');
  const [relationshipValue, setRelationshipValue] = useState('');
  const [dueDateValue, setDueDateValue] = useState<number | undefined>(undefined);
  const [nicuStayValue, setNicuStayValue] = useState('');
  const [raceSelection, setRaceSelection] = useState<string[]>([]);
  const [nextButtonDisabled, setNextButtonDisabled] = useState(true);
  const { t, i18n } = useTranslation();

  const db = getDatabase();
  const { dsn, serviceCreated } = useDeviceSetupContext();
  const { withErrorHandler } = useErrorContext();
  const { firebaseToken } = useAuthContext();

  const formValues = {
    firstName,
    birthDateValue,
    genderValue,
    relationshipValue,
    dueDateValue,
    nicuStayValue,
  };

  const mapUser = (serviceUserId: string, serviceKey: string) => {
    mapUserToDevice(`${firebaseToken}`, serviceUserId, dsn).then(() => {
      withErrorHandler(UseFirebasePostDataHook(
        'services',
        `${serviceKey}/serviceUserKeys`,
        { [serviceUserId]: true },
      ));
    });
  };

  const postNewProfile = async (values: FormData) => {
    const newProfileKey = push(child(ref(db), 'serviceUsers')).key;
    const postData = {
      accountKey: `${currentUser?.uid}`,
      type: 'child',
      firstName: values.firstName || '',
      birthDate: values.birthDateValue,
      dueDate: values.dueDateValue,
      gender: values.genderValue === 'Unspecified' ? null : values.genderValue,
      nicuStay: convertToBool(values.nicuStayValue),
      relationship: values.relationshipValue === 'Unspecified' ? null : values.relationshipValue,
      id: newProfileKey,
      races: raceSelection,
    };
    await UseFirebasePostDataHook('/serviceUsers', newProfileKey, postData).then(() => {
      const id = `${newProfileKey}`;
      addServiceUser({
        [id]: {
          ...postData, avatar: '', id, races: [],
        },
      });
      if (dsn && serviceCreated) {
        mapUser(id, serviceCreated.id);
      }
      if (serviceCreated) {
        navigation(success());
      } else {
        navigation('/child-profile/success');
      }
    });
  };

  const handleSubmit = (e: React.MouseEvent<HTMLButtonElement>) => {
    setNextButtonDisabled(true);
    e.preventDefault();
    postNewProfile(formValues);
  };

  useEffect(() => {
    const bday = birthDateValue;
    const name = firstName.length > 0;
    const dDate = dueDateValue;

    if (bday && name && dDate) {
      setNextButtonDisabled(false);
    } else {
      setNextButtonDisabled(true);
    }
  }, [firstName, dueDateValue, birthDateValue]);

  const useStyles = makeStyles({
    inputLabel: {
      color: '#D4D4F9 !important',
      '&.Mui-focused': {
        color: '#D4D4F9 !important',
      },
      textTransform: 'uppercase',
    },
    localizationProvider: {
      color: '#D4D4F9 !important',
    },
    textField: {
      color: '#D4D4F9 !important',
      backgroundColor: 'transparent !important',
    },
    underline: {
      '&:after': {
        color: '#D4D4F9 !important',
        borderBottom: '1px solid #D4D4F9 !important',
      },
      '&:before': {
        borderBottom: '1px solid #D4D4F9 !important',
      },
    },
    select: {
      color: '#D4D4F9 !important',
      '& .MuiSvgIcon-root': {
        color: '#D4D4F9 !important',
      },
      '&:before': {
        borderColor: '#D4D4F9 !important',
      },
      '&:after': {
        borderColor: '#D4D4F9 !important',
        color: '#D4D4F9 !important',
      },
    },
    date: {
      '& .MuiInputBase-root-MuiOutlinedInput-root': {
        borderColor: '#D4D4F9 !important',
        color: '#D4D4F9 !important',
      },
      '& .MuiOutlinedInput-notchedOutline': {
        borderColor: '#D4D4F9 !important',
        color: '#D4D4F9 !important',
      },
      '&:before': {
        borderColor: '#D4D4F9 !important',
      },
      '&:after': {
        borderColor: '#D4D4F9 !important',
        color: '#D4D4F9 !important',
      },
    },
  });

  const classes = useStyles();

  return (
    <LocalizationProvider dateAdapter={AdapterDateFns} locale={locales[i18n.language]}>
      <DialogGrid>
        <NavHeader backButton backgroundDark />
        <ScrollableArea>
          <AboutChildArea>
            <FormControl variant="standard">
              <InputLabel
                id="first-name"
                className={classes.inputLabel}
              >
                {t('First Name*')}
              </InputLabel>
              <Input
                name="firstName"
                className={[classes.underline, classes.textField].join(' ')}
                value={firstName}
                onChange={(e) => setFirstName(e.target.value)}
                required
              />
            </FormControl>
            <DateChooser dateValue={birthDateValue} label={`${t('Birth Date')}*`} setter={setBirthDateValue} />
            <FormControl variant="standard" style={inputStyle}>
              <InputLabel id="gender-value" className={classes.inputLabel}>{t('Enter gender')}</InputLabel>
              <Select
                defaultValue=""
                id="gender-value"
                label={t('Enter gender')}
                onChange={(e) => setGenderValue(e.target.value)}
                className={classes.select}
                value={genderValue}
              >
                {genderOptions.map((option) => (
                  <MenuItem
                    key={option.value}
                    value={option.value}
                  >
                    {t(option.text)}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
            <FormControl variant="standard" style={inputStyle}>
              <InputLabel id="relationship-value" className={classes.inputLabel}>{t('Enter relationship')}</InputLabel>
              <Select
                defaultValue=""
                id="relationship-value"
                label={t('Enter relationship')}
                onChange={(e) => setRelationshipValue(e.target.value)}
                className={classes.select}
                value={relationshipValue}
              >
                {relationshipOptions.map((option) => (
                  <MenuItem
                    key={option.value}
                    value={option.value}
                  >
                    {t(option.text)}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
            <DateChooser dateValue={dueDateValue} label={t('Due Date*')} setter={setDueDateValue} />
            <FormControl variant="standard" style={inputStyle}>
              <InputLabel id="nicu-stay-value" className={classes.inputLabel}>{t('NICU Stay?')}</InputLabel>
              <Select
                defaultValue=""
                id="nicu-stay-value"
                onChange={(e) => setNicuStayValue(e.target.value)}
                className={classes.select}
                value={nicuStayValue}
              >
                {nicuStayOptions.map((option) => (
                  <MenuItem
                    key={option.value}
                    value={option.value}
                  >
                    {t(option.text)}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </AboutChildArea>
          <RaceSelectionArea>
            <FormLabel component="legend" style={{ color: theme.color.text.darkMode.primary }}>{t('Race(optional)')}</FormLabel>
            <CheckboxForm
              allData={allRaces}
              selectedData={raceSelection}
              setSelectedData={setRaceSelection}
            />
          </RaceSelectionArea>
          <ButtonContainer>
            <BottomTextAndArrow isDisabled={nextButtonDisabled} buttonText={t('Next')} onClick={handleSubmit} />
          </ButtonContainer>
        </ScrollableArea>
      </DialogGrid>
    </LocalizationProvider>
  );
};

const inputStyle = {
  width: '85%',
  marginTop: '20px',
  height: '85%',
};

export default AboutChild;
