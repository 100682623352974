import styled from 'styled-components';
import { Link } from 'react-router-dom';
import Avatar from '../../components/Avatar';
import { updateServiceUser } from '../../navigation/routes';
import { emptyServiceUser } from '../../../shared/types';
import { Body } from '../../components/Typography';
import Icon from '../../components/Icon/Icon';
import theme from '../../design/theme';
import { useAccountContext } from '../../../backend/contexts/accountContext';
import { useServiceUserContext } from '../../../backend/contexts/serviceUserContext';

type ChildProfileListItemProps = {
  serviceUserKey: string
};

const Layout = styled(Link)`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 16px 0;
`;

const LeftSide = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`;

const Name = styled(Body)`
  margin-left: 10px;
`;

const ChildProfileListItem = ({ serviceUserKey }: ChildProfileListItemProps) => {
  const { accountKey } = useAccountContext();
  const { findServiceUser } = useServiceUserContext();
  const data = findServiceUser(serviceUserKey) || emptyServiceUser;

  return (
    <Layout key={serviceUserKey} to={updateServiceUser(accountKey, serviceUserKey)}>
      <LeftSide>
        <Avatar size="xsmall" image={data?.avatar} />
        <Name>{data?.firstName}</Name>
      </LeftSide>
      <Icon icon="ChevronRight" width={24} height={24} fill={theme.color.text.darkMode.primary} />
    </Layout>
  );
};

export default ChildProfileListItem;
