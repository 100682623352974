import { Helmet } from 'react-helmet-async';
import { Navigate } from 'react-router';
import { useTranslation } from 'react-i18next';
import { AuthStatus } from '../../../backend/contexts/authContext';
import { root } from '../../navigation/routes';
import Welcome from './Welcome';

type WelcomeWrapperProps = {
  authStatus: AuthStatus
}

const WelcomeWrapper = ({ authStatus }: WelcomeWrapperProps) => {
  const { t } = useTranslation();
  return (
    <>
      {authStatus === AuthStatus.Unauthenticated
        ? (
          <>
            <Helmet>
              <title>{t('Login')}</title>
            </Helmet>
            <Welcome />
          </>
        )
        : (<Navigate to={root()} />)}
      <div />
    </>
  );
};

export default WelcomeWrapper;
