import _ from 'lodash';
import {
  useCallback,
  useMemo,
  useReducer,
  useState,
} from 'react';
import { SleepDataResponse, VitalsDataResponse } from '../../../shared/types';
import { sleepData, vitalsData as getVitalsData } from '../../utils/owletApi';
import { UpdateHistoryAction, HistoryActions } from './actions';
import HistoryContext, { HistoryState, initialState, HistoryPayload } from './context';
import historyReducer from './reducer';

type HistoryProviderProps = { children: React.ReactNode }

const HistoryProvider = ({ children }: HistoryProviderProps) => {
  const [state, dispatch] = useReducer(historyReducer, initialState.history);
  const [historyDate, setHistoryDate] = useState<Date | null>(new Date());

  const updateHistory = useCallback(async (results: HistoryState, setLoading) => {
    const payload: HistoryPayload[] = [];
    const reload = results.sleep.reload || results.vitals.reload;

    if (state[results.sleep.key] && state[results.vitals.key] && !reload) {
      setLoading?.(false);
      return;
    }

    if (!state[results.sleep.key] || results.sleep.reload) {
      setLoading?.(true);
      await sleepData(results.sleep.params).then(({ data }: {data: SleepDataResponse}) => {
        payload.push({
          key: results.sleep.key,
          data: data.data ? data : {},
          error: false,
        });
      }).catch(() => {
        payload.push({
          key: results.sleep.key,
          data: {},
          error: true,
        });
      });
    }

    if (!state[results.vitals.key] || results.vitals.reload) {
      setLoading?.(true);
      await getVitalsData(results.vitals.params).then(({ data }: {data: VitalsDataResponse}) => {
        payload.push({
          key: results.vitals.key,
          data: data.data ? data : {},
          error: false,
        });
      }).catch(() => {
        payload.push({
          key: results.vitals.key,
          data: {},
          error: true,
        });
      });
    }

    const action: UpdateHistoryAction = {
      type: HistoryActions.UpdateHistory,
      payload,
    };

    if (!_.isEmpty(payload)) {
      dispatch(action);
    }
    setLoading?.(false);
  }, [state]);

  const findHistoryByServiceUser = useCallback((serviceUser: string) => (
    state[serviceUser]
  ), [state]);

  const value = useMemo(
    () => (
      {
        findHistoryByServiceUser,
        updateHistory,
        history: state,
        historyDate,
        setHistoryDate,
      }
    ),
    [updateHistory, findHistoryByServiceUser, state, historyDate],
  );

  return (
    <HistoryContext.Provider value={value}>
      {children}
    </HistoryContext.Provider>
  );
};

export default HistoryProvider;
