import FormControl from '@mui/material/FormControl';
import { MobileDatePicker } from '@mui/lab';
import { makeStyles } from '@mui/styles';
import { TextField, TextFieldProps } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { isDate, dateResolver } from '../../../backend/utils/helpers';

type DateChooserPropTypes = {
  dateValue: number | undefined | null,
  label: string,
  setter: (a: number | undefined) => void,
}

const DateChooser = ({ dateValue, label, setter }: DateChooserPropTypes) => {
  const { t } = useTranslation();
  const useStyles = makeStyles({
    dateChooserStyles: {
      '& label': {
        color: '#D4D4F9',
        textTransform: 'uppercase',
      },
      '& label.Mui-focused': {
        color: '#D4D4F9',
      },
      '& .MuiInput-root': {
        color: '#D4D4F9',
      },
      '& .MuiInput-underline': {
        borderBottomColor: '#D4D4F9',
      },
      '& .MuiInput-underline:before': {
        borderBottomColor: '#D4D4F9',
      },
      '& .MuiInput-underline:after': {
        borderBottomColor: '#D4D4F9',
      },
    },
  });

  const classes = useStyles();

  return (
    <FormControl variant="standard" style={inputStyle}>
      <MobileDatePicker
        cancelText={t('Cancel')}
        okText={t('OK')}
        label={label}
        onAccept={(date) => setter(isDate(date))}
        onChange={() => undefined}
        value={dateResolver(dateValue)}
        showToolbar={false}
        renderInput={(params: TextFieldProps) => (
          <TextField
            className={classes.dateChooserStyles}
            variant="standard"
            {...params}
          />
        )}
      />
    </FormControl>
  );
};

const inputStyle = {
  width: '85%',
  marginTop: '20px',
  height: '85%',
};

export default DateChooser;
