import styled from 'styled-components';
import { useTranslation } from 'react-i18next';
import { Body, PageHeading } from '../../components/Typography';
import BottomTextAndArrow from '../../components/FormComponents/BottomTextAndArrow';
import { tutorialSock } from './routes';
import BackButton from '../../components/Button/BackButton';
import theme from '../../design/theme';
import HappyOwl from '../../assets/owl_happy.svg';

const Layout = styled.div`
  display: flex;
  flex-direction: column;
  position: relative;
  `;

const Content = styled.div`
  display: flex;
  flex-direction: column;
  padding: 20px;
`;

const OwlImage = styled.img`
  width: 120px;
  height: 120px;
  align-self: center;
  margin-bottom: 30px;
`;

const ButtonContainer = styled.div`
  position: fixed;
  width: 90%;
  bottom: 0;
  padding: 40px 20px;
  background-color: ${theme.color.background.darkMode.darker};
`;

const Success = () => {
  const { t } = useTranslation();
  return (
    <Layout>
      <BackButton />
      <Content>
        <OwlImage
          alt="owl icon"
          src={HappyOwl}
        />
        <PageHeading>{t('Your Owlet Sock is ‘Owl’ set up and ready to go.')}</PageHeading>
        <Body>{t('Next we’ll show you how to get baby’s readings.')}</Body>
      </Content>
      <ButtonContainer>
        <BottomTextAndArrow buttonText={t('Start Owlet Sock Tutorial')} route={tutorialSock()} />
      </ButtonContainer>
    </Layout>
  );
};

export default Success;
