import styled from 'styled-components';
import { useTranslation } from 'react-i18next';
import theme from '../../design/theme';
import { Body } from '../Typography';

const Container = styled.div`
  display: none;

  @media (min-width: 768px) {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 40px;
    width: 100%;
    background-color: #FFC45E;
  }
`;

const StyledBody = styled(Body)`
  color: ${theme.color.background.darkMode.dark};
  font-size: 14px;
`;

const DesktopBanner = () => {
  const { t } = useTranslation();
  return (
    <Container>
      <StyledBody>
        {t('This site is optimized for mobile devices. You may experience some issues on your laptops or desktops.')}
      </StyledBody>
    </Container>
  );
};

export default DesktopBanner;
