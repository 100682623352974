import { colors, font, breakpoints } from './tokens';

const theme = {
  color: {
    ...colors,
  },
  font: {
    ...font,
  },
  mediaQuery: {
    ...breakpoints,
  },
  remSize: 16,
};

export default theme;
