import styled from 'styled-components';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import { useNavigate } from 'react-router';
import { useTranslation } from 'react-i18next';
import { Body, ButtonText, PageHeading } from '../../components/Typography';
import BottomTextAndArrow from '../../components/FormComponents/BottomTextAndArrow';
import BackButton from '../../components/Button/BackButton';
import SockBaseBack from '../../assets/onboarding-back-base3.svg';
import theme from '../../design/theme';
import { registerDevice } from './routes';

const Layout = styled.div`
  display: flex;
  flex-direction: column;
  position: relative;
  `;

const Content = styled.div`
  display: flex;
  flex-direction: column;
  padding: 20px;
`;

const ButtonContainer = styled.div`
  position: fixed;
  display: flex;
  flex-direction: column;
  width: 90%;
  bottom: 0;
  padding: 40px 20px;
  background-color: ${theme.color.background.darkMode.darker};
`;

const DeviceImage = styled.img`
  width: 90%;
  height: 100%;
  display: flex;
  align-self: center;
  margin-top: 40px;
`;

const GrayLink = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: center;
  width: 100%;
  margin-bottom: 30px;
`;

const TextStyled = styled(ButtonText)`
  color: ${theme.color.text.darkMode.disabled};
`;

const IconStyle = styled(ArrowForwardIosIcon)`
  color: ${theme.color.text.darkMode.disabled};
  margin-right: 6px;
`;

const WifiConfirmation = () => {
  const navigate = useNavigate();
  const { t } = useTranslation();

  const goBack = () => {
    navigate(-1);
  };

  return (
    <Layout>
      <BackButton />
      <Content>
        <PageHeading>{t('Quick Check')}</PageHeading>
        <Body>
          {t('Check the status of the Wi-Fi on the back of the Base Station.')}
        </Body>
        <DeviceImage src={SockBaseBack} alt={t('Owl image')} />
      </Content>
      <ButtonContainer>
        <GrayLink onClick={() => goBack()}>
          <TextStyled>{t('The Wi-Fi light is off')}</TextStyled>
          <IconStyle />
        </GrayLink>
        <BottomTextAndArrow buttonText={t('The Wi-Fi light is on')} route={registerDevice()} />
      </ButtonContainer>
    </Layout>
  );
};

export default WifiConfirmation;
