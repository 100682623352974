import {
  createContext,
  useCallback,
  useContext,
  useEffect,
  useState,
} from 'react';
import useLogout from '../hooks/useLogout';
import setFirebaseObserver from '../utils/firebaseObserver';
import {
  Account, AccountUser, emptyAccount, emptyAccountUser, FirebaseResponseStatus,
} from '../../shared/types';
import { useAuthContext } from './authContext';
import { useFeatureFlagContext } from './featureFlags';

type AccountProviderProps = { children: React.ReactNode }

type StateType = {
  account: Account,
  accountStatus: FirebaseResponseStatus,
  accountKey?: string,
  accountUser: AccountUser,
  accountUserStatus: FirebaseResponseStatus,
};

const initialState: StateType = {
  account: emptyAccount,
  accountStatus: FirebaseResponseStatus.idle,
  accountUser: emptyAccountUser,
  accountUserStatus: FirebaseResponseStatus.idle,
};

const Context = createContext<StateType>(initialState);

const useAccountContext = () => {
  const context = useContext(Context);
  if (context === undefined) {
    throw new Error('AccountContext context must be used within an AccountProvider');
  }
  return context;
};

const AccountProvider = ({ children }: AccountProviderProps) => {
  const { currentUser } = useAuthContext();
  const { userInit } = useFeatureFlagContext();
  const [account, setAccount] = useState<Account>(initialState.account);
  const [accountUser, setAccountUser] = useState<AccountUser>(initialState.accountUser);
  const [accountStatus, setAccountStatus] = useState<FirebaseResponseStatus>(
    initialState.accountStatus,
  );
  const [accountUserStatus, setAccountUserStatus] = useState<FirebaseResponseStatus>(
    initialState.accountUserStatus,
  );
  const accountKey = currentUser?.uid;

  useEffect(() => {
    let unsubscribeAccount = () => { /* no-op */ };
    let unsubscribeAccountUser = () => { /* no-op */ };

    if (accountKey) {
      const accountRefKey = `accounts/${accountKey}`;
      const accountUserRefKey = `accountUsers/${accountKey}`;

      const setAccountData = (newStatus1: FirebaseResponseStatus, newAccount: Account) => {
        setAccount({ ...emptyAccount, ...newAccount });
        setAccountStatus(newStatus1);
      };

      const setAccountUserData = (
        newStatus: FirebaseResponseStatus, newAccountUser: AccountUser,
      ) => {
        setAccountUser({ ...emptyAccountUser, ...newAccountUser });
        setAccountUserStatus(newStatus);
        if (newAccountUser) {
          userInit(newAccountUser.id);
        }
      };

      unsubscribeAccount = setFirebaseObserver<Account>(accountRefKey, setAccountData);
      unsubscribeAccountUser = setFirebaseObserver<AccountUser>(
        accountUserRefKey, setAccountUserData,
      );
    }

    return () => {
      unsubscribeAccount();
      unsubscribeAccountUser();
    };
  }, [accountKey, userInit]);

  const clearAccounts = useCallback(() => {
    setAccount(initialState.account);
    setAccountStatus(initialState.accountStatus);
    setAccountUser(initialState.accountUser);
    setAccountUserStatus(initialState.accountUserStatus);
  }, []);

  useLogout(clearAccounts);

  return (
    <Context.Provider value={{
      account, accountStatus, accountKey, accountUser, accountUserStatus,
    }}
    >
      {children}
    </Context.Provider>
  );
};

export { useAccountContext, AccountProvider };
