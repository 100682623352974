import styled from 'styled-components';
import { useTranslation } from 'react-i18next';
import SleepyOwl from '../../assets/owl_sleepy.svg';
import Button from '../Button/Button';
import { PageHeading } from '../Typography';

const Layout = styled.section`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 20px;
  height: inherit;
  `;

const Content = styled.div`
display: flex;
flex-direction: column;
align-items: center;
`;

const OwlImage = styled.img`
  width: 64px;
  height: 64px;
  margin-bottom: 30px;
`;

const PageHeader = styled(PageHeading)`
  text-align: center;
`;

type ReloadOverviewProps = {
  onClick: () => void;
}

const ReloadOverview = ({ onClick }: ReloadOverviewProps) => {
  const { t } = useTranslation();

  return (
    <Layout>
      <Content>
        <OwlImage
          alt={t('sleepy owl')}
          src={SleepyOwl}
        />
        <PageHeader>{t('Sorry there was an error, please try your action again.')}</PageHeader>
        <Button label={t('Reload')} onClick={() => onClick()} />
      </Content>
    </Layout>
  );
};

export default ReloadOverview;
