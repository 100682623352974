import { updateDeviceActive, updateVitals } from './actions';
import { AylaContextActions, AylaContextState, AylaContextAction } from '../../../shared/types';

export const initialReducerState: AylaContextState = {};

export default (state: AylaContextState, action: AylaContextAction) => {
  switch (action.type) {
    case AylaContextActions.UpdateDeviceActive:
      return updateDeviceActive(state, action.payload);
    case AylaContextActions.UpdateVitals:
      return updateVitals(state, action.payload);
    case AylaContextActions.Reset:
      return initialReducerState;
    default:
      throw new Error();
  }
};
