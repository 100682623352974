const MinutesToHoursAndMinutes = ({ totalMinutes }: { totalMinutes: number }) => {
  const H = Math.floor(totalMinutes / 60);
  const M = totalMinutes % 60;

  return (
    <span>
      {' '}
      {H}
      h
      {' '}
      {M}
      m
    </span>
  );
};

export default MinutesToHoursAndMinutes;
