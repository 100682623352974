import SurveyMinimized from './SurveyMinimized';
import SurveyExpanded from './SurveyExpanded';
import { useNPSContext } from '../../../backend/contexts/nps';

const Survey = () => {
  const {
    showSurvey,
    markAllAsRead,
    readAll,
    showMinimized,
    minimizeSurvey,
    maximizeSurvey,
  } = useNPSContext();

  return (
    <>
      <SurveyMinimized
        isActive={showMinimized}
        onClose={() => markAllAsRead()}
        onClick={() => maximizeSurvey()}
      />
      <SurveyExpanded
        isActive={showSurvey}
        onSubmit={() => readAll()}
        onClose={() => minimizeSurvey()}
      />
    </>
  );
};

export default Survey;
