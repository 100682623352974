import styled from 'styled-components';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import theme from '../../design/theme';
import { deviceResource } from '../../navigation/routes';
import { emptyService, emptyServiceUser, deviceOptions } from '../../../shared/types';
import { Body } from '../../components/Typography';
import Icon from '../../components/Icon/Icon';
import { useAccountContext } from '../../../backend/contexts/accountContext';
import { useServiceContext } from '../../../backend/contexts/serviceContext';
import { useServiceUserContext } from '../../../backend/contexts/serviceUserContext';

type DeviceListItem = {
  serviceKey: string
  serviceUserKeys: Record<React.Key, boolean>
}

const Layout = styled(Link)`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 16px 0;
`;

const LeftSide = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`;

const Name = styled(Body)`
  margin-left: 10px;
`;

const DeviceIcon = styled.div`
  width: 36px;
  height: 36px;
  background-color: ${theme.color.background.darkMode.dark};
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const DeviceListItem = ({ serviceKey, serviceUserKeys }: DeviceListItem) => {
  const { accountKey } = useAccountContext();
  const { findService } = useServiceContext();
  const { deviceKey, type } = findService(serviceKey) || emptyService;
  const { findServiceUser } = useServiceUserContext();
  const serviceUserData = findServiceUser(Object.keys(serviceUserKeys)[0]) || emptyServiceUser;
  const { firstName } = serviceUserData;
  const { t } = useTranslation();

  return (
    <Layout to={
      deviceResource(accountKey, serviceKey, deviceKey)
    }
    >
      <LeftSide>
        <DeviceIcon>
          <Icon icon="StatusBaseStation3" fill={theme.color.primary.default} width={24} height={24} />
        </DeviceIcon>
        <Name>
          {`${deviceOptions[type]} (${firstName || t('Unassigned')})`}
        </Name>
      </LeftSide>
      <Icon icon="ChevronRight" width={24} height={24} fill={theme.color.text.darkMode.primary} />
    </Layout>
  );
};

export default DeviceListItem;
