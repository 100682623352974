import styled from 'styled-components';
import { Link } from 'react-router-dom';
import { AddCircleOutlineOutlined } from '@mui/icons-material';
import LaunchIcon from '@mui/icons-material/Launch';
import { useTranslation } from 'react-i18next';
import theme from '../../../design/theme';
import { Body, Label, PageTitle } from '../../../components/Typography';
import NavHeader from '../../../components/NavHeader/NavHeader';
import { childIsBorn, deviceCollection } from '../../../navigation/routes';
import ChildProfileListItem from '../ChildProfileListItem';
import DeviceListItem from '../DeviceListItem';
import { useServiceContext } from '../../../../backend/contexts/serviceContext';
import { useServiceUserContext } from '../../../../backend/contexts/serviceUserContext';
import Icon from '../../../components/Icon/Icon';

const AccountGrid = styled.div`
  background-color: rgb(40, 43, 55);
  overflow: scroll;
`;

const AccountPageContents = styled.div`
  margin: 24px;
`;

const Divider = styled.div`
  border-bottom: .5px rgb(74, 76, 93) solid;
  width: 100%;
  margin-top: 3vh;
`;

const StyledLabel = styled(Label)`
  padding: 32px 0 16px;
`;

const ListItem = styled(Link)`
  padding: 16px 0;
  display: flex;
  align-items: center;
  justify-content: space-between;
  text-decoration: none;
`;

const ExternalListItem = styled.a`
  padding: 16px 0;
  display: flex;
  align-items: center;
  justify-content: space-between;
  text-decoration: none;
`;

const AddButton = styled(Link)`
  display: flex;
  align-items: center;
  color: ${theme.color.primary.default};
  margin: 16px 0;
  padding: 8px 0;
`;

const LinkText = styled(PageTitle)`
  margin-left: 6px;
  color: ${theme.color.primary.default};
  font-weight: 500;
`;

const AccountInfo = () => {
  const { servicesList } = useServiceContext();
  const { serviceUsersList } = useServiceUserContext();
  const { t } = useTranslation();

  return (
    <AccountGrid>
      <NavHeader title={t('Account')} />
      <AccountPageContents>
        <StyledLabel>{t('My Account')}</StyledLabel>
        <ListItem to="/account/user-info">
          <Body>{t('My Profile')}</Body>
          <Icon icon="ChevronRight" width={24} height={24} fill={theme.color.text.darkMode.primary} />
        </ListItem>
        <ExternalListItem href="https://support.owletcare.com/hc/en-us" target="_blank">
          <Body>{t('Support')}</Body>
          <LaunchIcon style={{ color: theme.color.text.darkMode.primary }} />
        </ExternalListItem>
        <ExternalListItem href="https://owletcare.com/pages/terms" target="_blank">
          <Body>{t('Terms and Conditions')}</Body>
          <LaunchIcon style={{ color: theme.color.text.darkMode.primary }} />
        </ExternalListItem>
        <ExternalListItem href="https://owletcare.com/pages/privacy" target="_blank">
          <Body>{t('Privacy Policy')}</Body>
          <LaunchIcon style={{ color: theme.color.text.darkMode.primary }} />
        </ExternalListItem>
        <Divider />
        <StyledLabel>{t('Child Profiles')}</StyledLabel>
        {serviceUsersList.map(({ id: serviceUserKey }) => (
          <ChildProfileListItem
            key={serviceUserKey}
            serviceUserKey={serviceUserKey}
          />
        ))}
        <AddButton
          to={childIsBorn()}
        >
          <AddCircleOutlineOutlined fontSize="small" />
          <LinkText>{t('Add Child')}</LinkText>
        </AddButton>
        <Divider />
        <StyledLabel>{t('My Owlet Devices')}</StyledLabel>
        {servicesList.map(({ id: serviceKey, serviceUserKeys }) => (
          <DeviceListItem
            serviceKey={serviceKey}
            serviceUserKeys={serviceUserKeys}
            key={`account-info-device-list-${serviceKey}`}
          />
        ))}
        <Divider />
        <AddButton to={deviceCollection()}>
          <AddCircleOutlineOutlined fontSize="small" />
          <LinkText>{t('Add Device')}</LinkText>
        </AddButton>
      </AccountPageContents>
    </AccountGrid>
  );
};

export default AccountInfo;
