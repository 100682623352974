export const root = () => '/';
export const home = () => '/home';
export const accountCollection = () => '/account';
export const accountResource = (accountKey = ':accountKey') => `${accountCollection()}/${accountKey}`;
export const accountInfo = () => `${accountCollection()}/account-info`;
export const serviceUserCollection = (
  accountKey = ':accountKey',
) => `${accountResource(accountKey)}/serviceUsers`;
export const serviceUserResource = (
  accountKey = ':accountKey',
  serviceUserKey = ':serviceUserKey',
) => `${serviceUserCollection(accountKey)}/${serviceUserKey}`;
export const serviceUserInfo = (
  accountKey = ':accountKey',
  serviceUserKey = ':serviceUserKey',
) => `${serviceUserResource(accountKey, serviceUserKey)}/info`;
export const updateServiceUser = (
  accountKey = ':accountKey',
  serviceUserKey = ':serviceUserKey',
) => `${serviceUserResource(accountKey, serviceUserKey)}/update`;
export const serviceCollection = (
  accountKey = ':accountKey',
) => `${accountResource(accountKey)}/services`;
export const serviceResource = (
  accountKey = ':accountKey',
  serviceUserKey = ':serviceKey',
) => `${serviceCollection(accountKey)}/${serviceUserKey}`;
export const deviceCollection = () => '/devices';
export const serviceDeviceCollection = (
  accountKey = ':accountKey',
  serviceKey = ':serviceKey',
) => `${serviceResource(accountKey, serviceKey)}/devices`;
export const deviceResource = (
  accountKey = ':accountKey',
  serviceKey = ':serviceKey',
  deviceKey = ':deviceKey',
) => `${serviceDeviceCollection(accountKey, serviceKey)}/${deviceKey}`;
export const serviceUser = (serviceUserKey = ':serviceUserKey') => `/serviceUser/${serviceUserKey}`;
export const serviceUserWithDevice = (
  serviceUserKey = ':serviceUserKey',
  deviceKey = ':deviceKey',
) => `${serviceUser(serviceUserKey)}/devices/${deviceKey}`;
export const childProfile = () => '/child-profile';
export const childIsBorn = () => `${childProfile()}/is-born`;
export const aboutChild = () => `${childProfile()}/about`;
export const notBornYet = () => `${childProfile()}/not-born-yet`;
export const guide = () => '/guide';
export const history = () => '/history';
export const serviceUserHistory = (serivceUserKey = ':serviceUserKey') => `${history()}/${serivceUserKey}`;
export const logIn = () => '/log-in';
export const signUp = () => '/sign-up';
export const forgotPassword = () => '/forgot-password';
export const details = () => '/details';
export const welcome = () => '/welcome';
export const confirmationEmail = () => '/confirmation-email';
export const gettingStarted = () => '/getting-started';
