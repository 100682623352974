import styled from 'styled-components';
import { ReactElement } from 'react';
import theme from '../../design/theme';
import CheckBG from './image/checkbox-bg.svg';
import { Caption } from '../Typography';

interface CheckboxProps {
  label: string | ReactElement;
  id: string;
  name: string;
  className?: string;
  onChange?: () => void;
}

const Layout = styled.div<Partial<CheckboxProps>>`
  flex-flow: row nowrap;
  justify-content: flex-start;
  align-items: center;
  width: 100%;

  input {
    width: 22px;
    height: 22px;
  }
`;

const Input = styled.input<Partial<CheckboxProps>>`
  appearance: none;
  display: flex;
  flex-grow: 0;
  flex-shrink: 0;
  margin-right: 10px;
  border-radius: 3px;
  border: 2px solid ${theme.color.white};
  outline: none;
  background-color: none;
  cursor: pointer;

  &:hover {
    background-color: ${theme.color.background.darkMode.darker};
  }

  &:focus {
    outline: none;
  }

  :checked {
    border: none;
    background-color: ${theme.color.background.darkMode.darker};
    background-image: url(${CheckBG});
    background-repeat: no-repeat;
    background-position: center center;
    background-size: 125%;
    overflow: visible;
  }
`;

const StyledLabel = styled(Caption)`
  display: flex;
  align-items: center;

  &:hover {
    cursor: pointer;
  }

  a {
    display: inline;
  }
`;

const Checkbox = ({
  label, id, name, className, onChange,
}: CheckboxProps) => (
  <Layout className={className} onChange={onChange}>
    <StyledLabel>
      <Input
        type="checkbox"
        id={id}
        name={name}
      />
      {label}
    </StyledLabel>
  </Layout>
);

export default Checkbox;
