import { useState, useEffect } from 'react';
import { makeStyles } from '@mui/styles';
import ExitToAppIcon from '@mui/icons-material/ExitToApp';
import InputLabel from '@mui/material/InputLabel';
import FormControl from '@mui/material/FormControl';
import { Input } from '@mui/material';
import AdapterDateFns from '@mui/lab/AdapterDateFns';
import { useNavigate } from 'react-router';
import { LocalizationProvider } from '@mui/lab';
import { getAuth } from 'firebase/auth';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import BottomTextAndArrow from '../../../components/FormComponents/BottomTextAndArrow';
import DateChooser from '../../../components/FormComponents/DateChooser';
import { accountInfo, root } from '../../../navigation/routes';
import { useAccountContext } from '../../../../backend/contexts/accountContext';
import { useAuthContext } from '../../../../backend/contexts/authContext';
import { useAylaContext } from '../../../../backend/contexts/aylaContext';
import { useFeatureFlagContext } from '../../../../backend/contexts/featureFlags';
import UseFirebasePostDataHook from '../../../../backend/utils/useFirebasePostDataHook';
import NavHeader from '../../../components/NavHeader/NavHeader';
import { Heading, Label } from '../../../components/Typography';
import { locales } from '../../../../backend/config/i18n';
import Phone from '../../../components/Phone';
import theme from '../../../design/theme';

type FormData = {
  firstName: string | undefined;
  lastName: string | undefined | null;
  birthdate: number | undefined | null;
  phone: string | undefined | null;
}

const UserInfo = () => {
  const { currentUser } = useAuthContext();
  const { signOut } = useAylaContext();
  const { accountUserStatus, accountUser: data } = useAccountContext();
  const {
    firstName, lastName, birthdate, phone,
  } = data;
  const { userDeinit } = useFeatureFlagContext();
  const classes = useStyles();
  const navigate = useNavigate();
  const first = currentUser?.displayName?.split(' ')[0];
  const last = currentUser?.displayName?.split(' ')[1];
  const { t, i18n } = useTranslation();

  const [firstNameValue, setFirstNameValue] = useState<string | undefined>(firstName || '');
  const [lastNameValue, setLastNameValue] = useState<string | undefined>(lastName || '');
  const [birthDateValue, setBirthDateValue] = useState<number | undefined | null>(birthdate);
  const [phoneNumber, setPhoneNumber] = useState<string | undefined | null>(phone || '');
  const [nextButtonDisabled, setNextButtonDisabled] = useState(true);

  useEffect(() => {
    const bday = birthDateValue ? (birthDateValue !== birthdate) : false;
    const userFirstName = firstNameValue ? firstNameValue !== firstName : false;
    const userLastName = lastNameValue ? lastNameValue !== lastName : false;
    const userPhoneNumber = phoneNumber ? phoneNumber !== phone : false;

    if (bday || userFirstName || userLastName || userPhoneNumber) {
      setNextButtonDisabled(false);
    } else {
      setNextButtonDisabled(true);
    }
  }, [
    firstName,
    lastName,
    birthdate,
    birthDateValue,
    firstNameValue,
    lastNameValue,
    phoneNumber,
    phone,
  ]);

  useEffect(() => {
    if (accountUserStatus === 'success') {
      setFirstNameValue(firstName || first);
      setLastNameValue(lastName || last);
      setBirthDateValue(birthdate || null);
    }

    if (accountUserStatus === 'success' && !firstName && !lastName && !phone) {
      UseFirebasePostDataHook('accountUsers', currentUser?.uid ? currentUser?.uid : '', {
        email: currentUser?.email,
        firstName: first || null,
        lastName: last || null,
        accountKey: currentUser?.uid,
        phone: phone || null,
        id: currentUser?.uid,
      });
    }
  }, [
    firstName,
    lastName,
    birthdate,
    accountUserStatus,
    first,
    last,
    currentUser,
    phone,
  ]);

  const signOutUser = () => {
    getAuth().signOut().then(() => {
      signOut();
      userDeinit();
      navigate(root());
    }).catch((error) => {
      console.error(error);
    });
  };

  const formValues = {
    firstName: firstNameValue,
    lastName: lastNameValue,
    birthdate: birthDateValue,
    phone: phoneNumber,
  };

  const updateAccountuserData = async (values: FormData) => {
    const updateData = {
      email: data.email,
      accountKey: currentUser?.uid,
      firstName: values.firstName,
      lastName: values.lastName,
      birthdate: values.birthdate,
      phone: values.phone,
      id: currentUser?.uid,
    };
    await UseFirebasePostDataHook('accountUsers', currentUser?.uid ? currentUser?.uid : '', updateData).then(() => {
      navigate(accountInfo());
    });
  };

  const handleSubmit = (e: React.MouseEvent<HTMLElement> | React.KeyboardEvent<HTMLElement>) => {
    setNextButtonDisabled(true);
    e.preventDefault();
    updateAccountuserData(formValues);
  };

  return (
    <LocalizationProvider dateAdapter={AdapterDateFns} locale={locales[i18n.language]}>
      <div style={dialogGrid}>
        <NavHeader title={t('My Profile')} backButton />
        <div style={userEmailTitleArea}>
          <Label>{t('Account E-mail')}</Label>
        </div>
        <div style={userEmailValueArea}>
          <Heading>{data.email || ''}</Heading>
        </div>
        <div style={personalInfoTitleArea}>
          <Label>{t('Personal Information')}</Label>
        </div>
        <div style={scrollableArea}>
          <div style={aboutChildArea}>
            <div style={aboutChildStyle}>
              <span style={inputStyle}>
                <FormControl variant="standard" style={inputStyle}>
                  <InputLabel
                    id="first-name"
                    className={classes.inputLabel}
                  >
                    {t('First Name')}
                  </InputLabel>
                  <Input
                    name="first-name"
                    className={[classes.underline, classes.textField].join(' ')}
                    style={width85Percent}
                    value={firstNameValue}
                    onChange={(e) => setFirstNameValue(e.target.value)}
                  />
                </FormControl>
              </span>
              <span style={inputStyle}>
                <FormControl variant="standard" style={inputStyle}>
                  <InputLabel
                    id="last-name"
                    className={classes.inputLabel}
                  >
                    {t('Last Name')}
                  </InputLabel>
                  <Input
                    name="last-name"
                    className={[classes.underline, classes.textField].join(' ')}
                    style={width85Percent}
                    value={lastNameValue}
                    onChange={(e) => setLastNameValue(e.target.value)}
                  />
                </FormControl>
              </span>
              <span style={inputStyle}>
                <FormControl variant="standard" style={inputStyle}>
                  <Wrapper>
                    <Phone phoneNumber={phoneNumber} setPhoneNumber={setPhoneNumber} />
                  </Wrapper>
                </FormControl>
              </span>
              <span style={inputStyle}>
                <DateChooser dateValue={birthDateValue} label={t('Birth Date')} setter={setBirthDateValue} />
              </span>
              <div
                style={deleteButtonStyle}
                onKeyDown={() => signOutUser()}
                onClick={() => signOutUser()}
                role="button"
                tabIndex={0}
              >
                <ExitToAppIcon style={signOutIcon} />
                {t('Log Out')}
              </div>
            </div>
          </div>
          {!nextButtonDisabled ? (
            <div
              style={nextButtonArea}
              onKeyDown={(e) => handleSubmit(e)}
              onClick={(e) => handleSubmit(e)}
              role="button"
              tabIndex={0}
            >
              <BottomTextAndArrow
                buttonText={t('Update')}
                route="/account/account-info"
              />
            </div>
          )
            : (
              <div />
            )}
        </div>
      </div>
    </LocalizationProvider>
  );
};

export default UserInfo;

const Wrapper = styled.div`
    .react-tel-input .special-label {
      display: block;
      color: ${theme.color.text.darkMode.primary};
      background-color: ${theme.color.background.darkMode.account}
    }
  `;

const dialogGrid = {
  display: 'grid',
  gridTemplateColumns: '40px 80vw 25px',
  gridTemplateRows: '10vh 5vh 5vh 7vh 73vh',
  height: '100vh',
  width: '100vw',
  backgroundColor: 'rgb(39, 43, 55)',
  zIndex: '40',
};

const userEmailTitleArea = {
  gridColumn: '2 / 4',
  gridRow: '2',
  display: 'flex',
  justifyContent: 'flex-start',
  alignItems: 'flex-end',
};

const personalInfoTitleArea = {
  gridColumn: '2 / 4',
  gridRow: '4',
  display: 'flex',
  justifyContent: 'flex-start',
  alignItems: 'flex-end',
};

const userEmailValueArea = {
  gridColumn: '2 / 4',
  gridRow: '3',
  display: 'flex',
  justifyContent: 'flex-start',
  alignItems: 'center',
};

const scrollableArea = {
  gridColumn: '2 / 4',
  gridRow: '5 / 7',
  display: 'grid',
  gridTemplateColumns: '10% 90%',
  gridTemplateRows: '60% 40%',
};

const aboutChildArea = {
  gridColumn: '1 / 3',
  gridRow: '1',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'flex-start',
  width: '100%',
};

const aboutChildStyle = {
  width: '100%',
};

const nextButtonArea = {
  gridColumn: '2',
  gridRow: '2',
  height: '100%',
  width: '100%',
};

const inputStyle = {
  width: '100%',
  marginTop: '20px',
  height: '85%',
};

const deleteButtonStyle = {
  marginTop: '30px',
  color: 'rgb(242, 162, 122)',
  width: '100%',
  height: '100%',
  display: 'flex',
  justifyContent: 'flex-start',
  alignItems: 'center',
};

const signOutIcon = {
  marginRight: '10px',
};

const width85Percent = {
  width: '85%',
};

const useStyles = makeStyles({
  inputLabel: {
    textTransform: 'uppercase',
    color: '#D4D4F9 !important',
    '&.Mui-focused': {
      color: '#D4D4F9 !important',
    },
  },
  localizationProvider: {
    color: '#D4D4F9 !important',
  },
  textField: {
    color: '#D4D4F9 !important',
  },
  underline: {
    '&:after': {
      color: '#D4D4F9 !important',
      borderBottom: '1px solid #D4D4F9 !important',
    },
    '&:before': {
      borderBottom: '1px solid #D4D4F9 !important',
    },
  },
  datePickerStyles: {
    '& label': {
      color: '#D4D4F9',
    },
    '& label.Mui-focused': {
      color: '#D4D4F9',
    },
    '& .MuiInput-root': {
      color: '#D4D4F9',
    },
    '& .MuiInput-underline': {
      borderBottomColor: '#D4D4F9',
    },
    '& .MuiInput-underline:before': {
      borderBottomColor: '#D4D4F9',
    },
    '& .MuiInput-underline:after': {
      borderBottomColor: '#D4D4F9',
    },
  },
});
