import { AnimatePresence, motion } from 'framer-motion';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import theme from '../../design/theme';
import { Icon } from '../Icon';
import { Body } from '../Typography';

interface ModalProps {
  isActive: boolean;
  onClose?: () => void;
  onClick: () => void;
}

const Layout = styled.div`
  position: fixed;
  top: 40px;
  right: 36px;
  transform: rotate(-90deg);
  transform-origin: 100% 0;
  z-index: 101;
`;

const Content = styled(motion.section)`
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 8px 12px;
  border-radius: 10px 10px 0 0 ;
  background-color: ${theme.color.tertiary.default};
  box-shadow: 0px -5px 15px rgba(0, 0, 0, 0.35);
`;

const StyledBody = styled(Body)`
  padding-right: 10px;
  color: ${theme.color.background.darkMode.darker};
`;

const SurveyMinimized = ({ isActive, onClose, onClick } : ModalProps) => {
  const { t } = useTranslation();

  return (
    <Layout>
      <AnimatePresence>
        {isActive && (
          <Content
            initial={{ y: 100, opacity: 0 }}
            animate={{ y: 0, opacity: 100 }}
            exit={{ y: 100, opacity: 0 }}
            transition={{ ease: 'easeInOut', duration: 0.3 }}
          >
            <StyledBody onClick={onClick}>{t('Take Survey')}</StyledBody>
            <Icon icon="Close" height={19} width={19} fill={theme.color.background.darkMode.darker} onClick={onClose} />
          </Content>
        )}
      </AnimatePresence>
    </Layout>
  );
};

export default SurveyMinimized;
