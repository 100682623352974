import styled from 'styled-components';
import { useTranslation } from 'react-i18next';
import theme from '../../design/theme';
import { PageHeading } from '../../components/Typography';
import BackButton from '../../components/Button/BackButton';
import { childIsBorn } from './routes';
import SleepyOwl from '../../assets/owl_sleepy.svg';
import BottomTextAndArrow from '../../components/FormComponents/BottomTextAndArrow';

const Layout = styled.div`
  display: flex;
  flex-direction: column;
`;

const Content = styled.div`
  display: flex;
  flex-direction: column;
  padding: 20px;
`;

const ButtonContainer = styled.div`
  position: fixed;
  width: 90%;
  bottom: 0;
  padding: 40px 20px;
  background-color: ${theme.color.background.darkMode.darker};
`;

const OwlImageContainer = styled.div`
  align-items: center;
  display: flex;
  flex-direction: column;
`;
const OwlImage = styled.img`
  width: 100px;
  height: 100px;
  margin-bottom: 30px;
`;

const AddChildStep = () => {
  const { t } = useTranslation();
  return (
    <Layout>
      <BackButton />
      <Content>
        <OwlImageContainer>
          <OwlImage
            alt={t('sleepy owl')}
            src={SleepyOwl}
          />
        </OwlImageContainer>
        <PageHeading>{t('Your device is ready to be assigned to your child.')}</PageHeading>
        <ButtonContainer>
          <BottomTextAndArrow buttonText={t('Create Child Profile')} route={childIsBorn()} />
        </ButtonContainer>
      </Content>
    </Layout>
  );
};

export default AddChildStep;
