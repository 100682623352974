import { DeviceSetupProvider } from '../../../backend/contexts/deviceSetupContext';
import DeviceSetupRouter from './DeviceSetupRouter';

const DeviceSetup = () => (
  <DeviceSetupProvider>
    <DeviceSetupRouter />
  </DeviceSetupProvider>
);

export default DeviceSetup;
