import styled from 'styled-components';
import theme from '../design/theme';

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100vh;
`;

const Circle = styled.div`
  border: 6px solid ${theme.color.background.darkMode.darker};
  border-top: 6px solid ${theme.color.primary.default};
  border-radius: 50%;
  width: 60px;
  height: 60px;
  animation: spin 1s linear infinite;
  margin-bottom: 20px;

  @keyframes spin {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  } 
`;

const Loader = () => (
  <Container>
    <Circle />
  </Container>
);

export default Loader;
