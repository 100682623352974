import styled from 'styled-components';
import { Link } from 'react-router-dom';
import Grow from '@mui/material/Grow';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import theme from '../../design/theme';
import Button from '../../components/Button/Button';
import StagingBanner from '../../components/StagingBanner';
import { Body } from '../../components/Typography';
import { logIn, signUp } from '../../navigation/routes';
import WelcomeImage from '../../assets/welcome_image.png';
import OwletLogo from '../../assets/logo_white.png';
import useFeatureFlag from '../../../backend/hooks/useFeatureFlag';
import { FeatureFlags } from '../../../shared/constants';

const Layout = styled.div`
  background: url(${WelcomeImage}) no-repeat center center fixed;
  background-size: cover;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  height: 100vh;
  z-index: 10;
`;

const Content = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 250px;
  width: 100%;
  padding-top: 100px;
  background: rgb(0,0,0);
  background: linear-gradient(0deg, rgba(0,0,0,1) 0%, rgba(0,0,0,0) 100%);
`;

const ButtonContainer = styled.div`
  width: 90%;
  text-align: center;
`;

const Logo = styled.img`
  width: 50%;
  margin-top: 100px;
`;

const StyledLink = styled(Link)`
  padding: 16px;
  color: ${theme.color.primary.default};
  display: block;
`;

const Welcome = () => {
  const [isPageLoaded, setIsPageLoaded] = useState(false);
  const showStagingBanner = useFeatureFlag(FeatureFlags.STAGING_BANNER);
  const { t } = useTranslation();

  const welcomeMessages = [
    t('More than a million parents trust Owlet.'),
    t('Calm & confident parenting'),
    t('Peace of mind, one swipe away'),
    t('Keep baby healty & happy'),
    t('Peace of mind so you can rest assured'),
  ];

  useEffect(() => {
    setIsPageLoaded(true);
  }, []);

  return (
    <Layout>
      {showStagingBanner ? <StagingBanner /> : null}
      <Logo
        alt={t('Owlet logo')}
        src={OwletLogo}
      />
      <Content>
        <Grow
          in={isPageLoaded}
          style={{ transformOrigin: '0 0 0' }}
          timeout={500}
        >
          <Body>
            {welcomeMessages[
              Math.floor(Math.random() * welcomeMessages.length)
            ]}
          </Body>
        </Grow>
        <Grow
          in={isPageLoaded}
          style={{ transformOrigin: '0 0 0' }}
          timeout={1000}
        >
          <ButtonContainer>
            <Button
              label={t('Join Owlet')}
              to={signUp()}
            />
            <StyledLink to={logIn()}>
              {t('Login')}
            </StyledLink>
          </ButtonContainer>
        </Grow>
      </Content>
    </Layout>
  );
};

export default Welcome;
