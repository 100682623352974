import { Routes, Route } from 'react-router-dom';
import ChildSelect from './views/ChildSelect';
import IsBorn from './views/IsBorn';
import AboutChild from './views/AboutChild';
import NotBornYet from './views/NotBornYet';
import OwlSet from './views/OwlSet';

const routes = [
  { path: 'child-select', Component: ChildSelect },
  { path: 'is-born', Component: IsBorn },
  { path: 'about', Component: AboutChild },
  { path: 'not-born-yet', Component: NotBornYet },
  { path: 'success', Component: OwlSet },
];

const ChildProfile = () => (
  <form>
    <Routes>
      {routes.map(({ path, Component }) => (
        <Route
          key={path}
          path={path}
          element={<Component />}
        />
      ))}
    </Routes>
  </form>
);

export default ChildProfile;

// Generally wrapping your child views in a single use form wrapper
// to sync with the db and local store is advisable.
