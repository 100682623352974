import styled from 'styled-components';
import { AylaProvider } from './backend/contexts/aylaContext';
import { NPSProvider } from './backend/contexts/nps';
import StoreProvider from './backend/contexts/storeContext';
import RootNavigation from './ui/navigation';

const StyledBody = styled.div`
  display: grid;
  grid-template-columns: 100vw;
  grid-template-rows: 90vh 10vh;
  height: 90vh;
`;

export default function App() {
  return (
    <AylaProvider>
      <StoreProvider>
        <StyledBody>
          <NPSProvider>
            <RootNavigation />
          </NPSProvider>
        </StyledBody>
      </StoreProvider>
    </AylaProvider>
  );
}
