import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import styled from 'styled-components';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { useMemo } from 'react';
import Icon from '../../components/Icon/Icon';
import Avatar from '../../components/Avatar';
import { deviceCollection, serviceUserInfo } from '../../navigation/routes';
import { useAccountContext } from '../../../backend/contexts/accountContext';
import { useAylaContext } from '../../../backend/contexts/aylaContext';
import { useDeviceContext } from '../../../backend/contexts/deviceContext';
import { Heading, Label, PageTitle } from '../../components/Typography';
import theme from '../../design/theme';
import {
  emptyDevice, emptyService, Service, ServiceUser,
} from '../../../shared/types';
import MinutesToHoursAndMinutes from '../../components/Time';
import { determineBatteryIcon } from '../../../backend/utils/helpers';
import { IconTypes } from '../../components/Icon';

type ProfileCardProps = {
  service?: Service,
  serviceUser: ServiceUser
}

const StyledLabel = styled(Label)`
    color: ${theme.color.primary.dark};
    margin-left: 8px;
  `;

const AddDeviceButton = styled(Link)`
  display: flex;
  align-items: center;
  color: ${theme.color.primary.default};
  margin: 16px 0 0;
`;
const LinkText = styled(PageTitle)`
  margin-left: 6px;
  color: ${theme.color.primary.default};
  font-weight: 500;
`;

const ChildCard = ({ service = emptyService, serviceUser }: ProfileCardProps) => {
  const { accountKey } = useAccountContext();
  const { deviceDetails } = useAylaContext();
  const { findDevice } = useDeviceContext();
  const { dsn } = findDevice(service.deviceKey) || emptyDevice;
  const {
    ox, hr, btt, chg, bat,
  } = deviceDetails[dsn] || {};
  const { t } = useTranslation();
  const batteryStatus = useMemo<IconTypes>(() => determineBatteryIcon(bat, chg), [bat, chg]);

  return (
    <Container>
      <AvatarPosition>
        <Avatar image={serviceUser.avatar} size="medium" />
      </AvatarPosition>
      <ArrowPosition>
        <Link to={serviceUserInfo(accountKey, serviceUser.id)}>
          <ArrowForwardIosIcon style={arrowStyle} />
        </Link>
      </ArrowPosition>
      <NamePosition>
        <Heading>{serviceUser.firstName}</Heading>
      </NamePosition>
      {dsn
        ? (
          <StatContainer>
            <Stat>
              <Icon icon="StatusHeartrate" fill={theme.color.primary.default} width={20} height={20} />
              <StyledLabel>
                {hr || '---'}
              </StyledLabel>
            </Stat>
            <Stat>
              <Icon icon="StatusOxygen" fill={theme.color.primary.default} width={20} height={20} />
              <StyledLabel>
                {ox || '---'}
              </StyledLabel>
            </Stat>
            <Stat>
              <Icon
                icon={batteryStatus}
                fill={theme.color.primary.default}
                width={20}
                height={20}
              />
              <StyledLabel>
                {btt ? <MinutesToHoursAndMinutes totalMinutes={btt} /> : '---'}
              </StyledLabel>
            </Stat>
          </StatContainer>
        )
        : (
          <AddDeviceButton to={deviceCollection()}>
            <AddCircleOutlineIcon fontSize="small" />
            <LinkText>{t('Add an Owlet device')}</LinkText>
          </AddDeviceButton>
        )}

    </Container>
  );
};

const Container = styled.div`
  background-color: ${theme.color.background.darkMode.dark};
  margin: 40px 0;
  display: flex;
  align-items: center;
  flex-direction: column;
  padding: 16px;
  position: relative;
`;

const AvatarPosition = styled.div`
  margin-top: -40px;
  margin-bottom: 10px;
`;

const ArrowPosition = styled.div`
  position: absolute;
  top: 20px;
  right: 20px;
`;

const arrowStyle = {
  height: '15px',
  width: '15px',
  color: '#fff',
};

const NamePosition = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`;

const StatContainer = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  padding-top: 8px;
`;

const Stat = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`;

export default ChildCard;
