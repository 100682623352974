import {
  Route,
} from 'react-router-dom';
import LogIn from './Login';
import SignUp from './SignUp';
import ForgotPassword from './ForgotPassword';
import Details from './Details';
import {
  logIn,
  signUp,
  forgotPassword,
  details,
} from '../../navigation/routes';
import PrivateRoute from '../../navigation/PrivateRoute';

const SignInRoutes = [
  <Route
    key="logIn"
    path={logIn()}
    element={
            (
              <LogIn />
            )
        }
  />,
  <Route
    key="signUp"
    path={signUp()}
    element={
            (
              <SignUp />
            )
        }
  />,
  <Route
    key="forgotPassword"
    path={forgotPassword()}
    element={
            (
              <ForgotPassword />
            )
        }
  />,
  <Route
    key="details"
    path={details()}
    element={
          (
            <PrivateRoute>
              <Details />
            </PrivateRoute>
          )
      }
  />,
];

export default SignInRoutes;
