import { createContext, useContext } from 'react';
import { SleepDataResponse, VitalsDataResponse } from '../../../shared/types';
import { SleepDataRequestParams, VitalsDataRequestParams } from '../../utils/owletApi';

export type History = Record<string, any>

type SleepRequest = {
  key: string;
  params: SleepDataRequestParams;
  reload?: boolean;
}

type VitalsRequest = {
  key: string;
  params: VitalsDataRequestParams;
  reload?: boolean;
}

interface HistoryState {
  sleep: SleepRequest;
  vitals: VitalsRequest;
}

export type HistoryPayload = {
  key: string;
  data: SleepDataResponse | VitalsDataResponse | History;
  error: boolean;
}

interface HistoryContextState {
  updateHistory: (results: HistoryState, setLoading?: (v: boolean) => void) => Promise<void>
  findHistoryByServiceUser: (k: string) => History | undefined
  history: History
  historyDate: Date | null
  setHistoryDate: (date: Date | null) => void
}

const initialState: HistoryContextState = {
  updateHistory: () => new Promise(() => undefined),
  findHistoryByServiceUser: (_) => undefined,
  history: {},
  historyDate: null,
  setHistoryDate: (_) => null,
};

const Context = createContext<HistoryContextState>(initialState);

const useHistoryContext = () => {
  const context = useContext(Context);
  if (context === undefined) {
    throw new Error('HistoryContext context must be used within a HistoryProvider');
  }
  return context;
};

export { initialState, useHistoryContext };
export type { HistoryState };
export default Context;
