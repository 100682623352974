import i18n from 'i18next';
// import { datadogRum } from '@datadog/browser-rum';
import { initReactI18next } from 'react-i18next';
import LanguageDetector from 'i18next-browser-languagedetector';
import fr from 'date-fns/locale/fr';
import frCA from 'date-fns/locale/fr-CA';
import enTranslation from '../../locales/en/webapp.json';

// TODO: https://owletcare.atlassian.net/browse/OWA-260
// TODO: Find a better way to handle typing that have a committed strings file.
const resources = {
  en: {
    translation: enTranslation,
  },
} as const;

export const defaultNamespace = 'webapp';
// TODO: https://owletcare.atlassian.net/browse/OWA-260
const fallbackLng = 'en'; // const fallbackLng = translationConfig.fallbackLanguage
// We only support a subset of our fupported languages for webapp. If we list
// all supported languages and some requests (it,fr,en) it would show english
// strings, not french like we would want it to.
const supportedLngs = ['en', 'fr', 'fr-CA'];

// TODO: emit a DD RUM error or something if strings are missing. i.e. define getHandler
// function getHandler(handlerType: string) {
//   return (langs: string[], namespace: string, key: string) => {
//     const lang = langs.join(',');
//     const errorMessage = `Missing ${handlerType}. Langs: ${lang}; ns: ${namespace}; key: ${key}`;
//     const error = new Error(errorMessage);

//     datadogRum.addError(error, {
//     });
//   };
// }

export const locales: Record<string, Locale> = {
  fr,
  'fr-CA': frCA,
};

i18n
  .use(LanguageDetector)
  .use(initReactI18next)
  .use({
    type: 'backend',
    read(
      language: string,
      namespace: string,
      callback: (
        errorValue: unknown,
        translations: null | typeof resources['en']
      ) => void,
    ) {
      const locale = language.replace('-', '_');
      import(`../../locales/${locale}/${namespace}.json`)
        .then((sources) => {
          callback(null, sources);
        })
        .catch((error) => {
          callback(error, null);
        });
    },
  })
  .init({
    defaultNS: defaultNamespace,
    detection: {
      order: ['navigator'],
    },
    fallbackLng,
    interpolation: {
      escapeValue: false,
    },
    nonExplicitSupportedLngs: true,
    ns: [defaultNamespace],
    preload: [fallbackLng],
    returnEmptyString: false,
    supportedLngs,

    // TODO: emit a DD error or something if strings are missing. i.e. define getHandler
    // saveMissing: true,
    // saveMissingTo: 'fallback',
    // missingInterpolationHandler: getHandler('interpolation'),
    // missingKeyHandler: getHandler('key'),
  });

export default resources;
