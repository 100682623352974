import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';
import { Body, PageHeading } from '../../components/Typography';
import BackButton from '../../components/Button/BackButton';
import DeviceImage from '../../assets/onboarding_sock_connect.svg';
import { useAylaContext } from '../../../backend/contexts/aylaContext';
import {
  checkOTAUpdates, pairFailed,
} from './routes';
import { useDeviceSetupContext } from '../../../backend/contexts/deviceSetupContext';
import { DeviceConnectionStatus } from '../../../shared/types';

const giveUpAfterMs = 60000;

const Layout = styled.div`
  display: flex;
  flex-direction: column;
  position: relative;
  `;

const SuccessImage = styled.img`
  margin: 32px;
  width: 260px;
  height: 220px;
  display: flex;
  align-self: center;
  `;

const Content = styled.div`
  display: flex;
  flex-direction: column;
  padding: 20px;
`;

const SearchSock = () => {
  const navigate = useNavigate();
  const { dsn } = useDeviceSetupContext();
  const { deviceDetails, refreshDevices } = useAylaContext();
  const { t } = useTranslation();

  useEffect(() => {
    refreshDevices();
    const giveUpTimeout = setTimeout(() => {
      navigate(pairFailed());
    }, giveUpAfterMs);

    return () => {
      clearTimeout(giveUpTimeout);
    };
  }, [navigate, refreshDevices]);

  useEffect(() => {
    if (dsn && !deviceDetails[dsn]) {
      refreshDevices();
    }
  }, [refreshDevices, dsn, deviceDetails]);

  useEffect(() => {
    let timeoutId: ReturnType<typeof setTimeout>;

    if (dsn) {
      const connection = deviceDetails[dsn]?.sc || 0;
      const connectionStatus = deviceDetails[dsn]?.connectionStatus;

      if (connection > 0 && connectionStatus === DeviceConnectionStatus.Connected) {
        timeoutId = setTimeout(() => {
          navigate(checkOTAUpdates());
        }, 2000);
      }
    }
    return () => {
      clearTimeout(timeoutId);
    };
  }, [deviceDetails, dsn, navigate]);

  return (
    <Layout>
      <BackButton />
      <Content>
        <SuccessImage src={DeviceImage} alt="Owl image" />
        <PageHeading>{t('Registration successful!')}</PageHeading>
        <Body>{t('Insert the sock into the base station.')}</Body>
      </Content>
    </Layout>
  );
};

export default SearchSock;
